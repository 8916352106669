import InfiniteScroll from "react-infinite-scroll-component";
import {Box, CircularProgress, Typography} from "@mui/material";
import React, {CSSProperties, ReactNode} from "react";
import ComponentEmpty from "components/componentEmpty";
import DefaultSkeleton from "components/default.skeleton";
import __ from "languages/index";

type Fn = () => any;

interface TypedInfiniteScrollContainerProps {
  isRefreshing: boolean,
  next: Fn;
  hasMore: boolean;
  children: ReactNode;
  loader?: ReactNode;
  skeleton?: ReactNode;
  emptyContent?: ReactNode;
  scrollThreshold?: number | string;
  endMessage?: ReactNode;
  style?: CSSProperties;
  height?: number | string;
  scrollableTarget?: ReactNode;
  hasChildren?: boolean;
  inverse?: boolean;
  pullDownToRefresh?: boolean;
  pullDownToRefreshContent?: ReactNode;
  releaseToRefreshContent?: ReactNode;
  pullDownToRefreshThreshold?: number;
  refreshFunction?: Fn;
  onScroll?: (e: MouseEvent) => any;
  dataLength: number;
  initialScrollY?: number;
  className?: string;
  showEndMessage?:boolean;
  textEmptyMessage?: string
  textEndMessage?: string
}

export const InfiniteScrollContainer = ({
                                          isRefreshing,
                                          emptyContent,
                                          skeleton,
                                          children,
                                          loader,
                                          releaseToRefreshContent,
                                          pullDownToRefreshContent,
                                          endMessage,
                                          pullDownToRefresh = false,
                                          refreshFunction,
                                          pullDownToRefreshThreshold,
                                          dataLength,
                                          showEndMessage = false,
                                          textEmptyMessage = `${__(`not_found`)}`,
                                          textEndMessage = `${__(`no_more_content`)}`,
                                          ...attributes
                                        }: TypedInfiniteScrollContainerProps) => {

  return (
    isRefreshing ?
      (skeleton || <DefaultSkeleton/>)
      :
      (
        dataLength > 0 ?
          <InfiniteScroll
            {...attributes}
            dataLength={dataLength}
            endMessage={showEndMessage && (endMessage ||
              <Box
                paddingY={3}
                sx={{
                  display: 'grid',
                  placeItems: 'center'
                }}>
                <Typography color={'text.secondary'} fontWeight={400} textAlign={'center'}>
                  {textEndMessage}
                </Typography>
              </Box>)
            }

            pullDownToRefreshContent={pullDownToRefreshContent ||
              <h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
            }

            releaseToRefreshContent={releaseToRefreshContent ||
              <h3 style={{textAlign: 'center', color: "#000000"}}>&#8593; Release to refresh</h3>
            }

            loader={loader ||
              <Box display={"flex"} justifyContent={'center'} sx={{height: 60}} paddingY={2} alignItems={"center"}>
                <CircularProgress/>
              </Box>
            }

            refreshFunction={refreshFunction}
            pullDownToRefresh={pullDownToRefresh}
            pullDownToRefreshThreshold={pullDownToRefreshThreshold || 50}
          >
            {children}
          </InfiniteScroll>
          :
          (emptyContent || <ComponentEmpty text={textEmptyMessage}/>)
      )
  );
};
