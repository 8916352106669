import { SvgIcon, SvgIconProps } from '@mui/material'

const AppsAdd = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
     <path fillRule="evenodd" clipRule="evenodd" d="M2 5C2 3.34315 3.34315 2 5 2H8C9.65685 2 11 3.34315 11 5V8C11 9.65685 9.65685 11 8 11H5C3.34315 11 2 9.65685 2 8V5ZM5 4C4.44772 4 4 4.44772 4 5V8C4 8.55228 4.44772 9 5 9H8C8.55228 9 9 8.55228 9 8V5C9 4.44772 8.55228 4 8 4H5ZM13 5C13 3.34315 14.3431 2 16 2H19C20.6569 2 22 3.34315 22 5V8C22 9.65685 20.6569 11 19 11H16C14.3431 11 13 9.65685 13 8V5ZM16 4C15.4477 4 15 4.44772 15 5V8C15 8.55228 15.4477 9 16 9H19C19.5523 9 20 8.55228 20 8V5C20 4.44772 19.5523 4 19 4H16ZM2 16C2 14.3431 3.34315 13 5 13H8C9.65685 13 11 14.3431 11 16V19C11 20.6569 9.65685 22 8 22H5C3.34315 22 2 20.6569 2 19V16ZM5 15C4.44772 15 4 15.4477 4 16V19C4 19.5523 4.44772 20 5 20H8C8.55228 20 9 19.5523 9 19V16C9 15.4477 8.55228 15 8 15H5ZM17.5 13C18.0523 13 18.5 13.4477 18.5 14V16.5H21C21.5523 16.5 22 16.9477 22 17.5C22 18.0523 21.5523 18.5 21 18.5H18.5V21C18.5 21.5523 18.0523 22 17.5 22C16.9477 22 16.5 21.5523 16.5 21V18.5H14C13.4477 18.5 13 18.0523 13 17.5C13 16.9477 13.4477 16.5 14 16.5H16.5V14C16.5 13.4477 16.9477 13 17.5 13Z" />
</svg>
    </SvgIcon>
  )
}

export default AppsAdd
