import helpers from "helpers/index";

export enum EnumNotificationScreen {
    NAVIGATION_CHAT_ROOM = 'NAVIGATION_CHAT_ROOM',
    COMMENT_IS_CREATED = 'COMMENT_IS_CREATED',
    COMMENT_IS_LIKED = 'COMMENT_IS_LIKED',
    POST_IS_LIKED = 'POST_IS_LIKED',
    POST_IS_CREATED = 'POST_IS_CREATED',
    POST_IS_APPROVED = 'POST_IS_APPROVED',
    POST_NEED_APPROVING = 'POST_NEED_APPROVING',
    NAVIGATION_CHALLENGE_CREATED = 'NAVIGATION_CHALLENGE_CREATED',
    NAVIGATION_CHALLENGE_CHECKIN = 'NAVIGATION_CHALLENGE_CHECKIN',
    NAVIGATION_CHALLENGE_CHECKIN_APPROVE = 'NAVIGATION_CHALLENGE_CHECKIN_APPROVE',
    NAVIGATION_CHALLENGE_REQUIRE_JOIN_APPROVED = 'NAVIGATION_CHALLENGE_REQUIRE_JOIN_APPROVED',
    NAVIGATION_CHALLENGE_REQUIRE_JOIN_NEED_APPROVED = 'NAVIGATION_CHALLENGE_REQUIRE_JOIN_NEED_APPROVED',
    NAVIGATION_LIVESTREAM_CREATED = 'NAVIGATION_LIVESTREAM_CREATED',
    WITHDRAW_REQUEST = 'WITHDRAW_REQUEST',
    REQUEST_JOIN_YOUR_CHANNEL = 'REQUEST_JOIN_YOUR_CHANNEL',
    NOTIFICATION_BUY_COURSE_SUCCESS = 'NOTIFICATION_BUY_COURSE_SUCCESS',
    NOTIFICATION_MEMBER_REQUEST_JOIN = 'NOTIFICATION_MEMBER_REQUEST_JOIN',
    GIFT_AFTER_CREATED = 'GIFT_AFTER_CREATED',
    COURSE_AFTER_CREATED = 'COURSE_AFTER_CREATED',
    COURSE_AFTER_ADDED_USER_TO_COURSE = 'COURSE_AFTER_ADD_USER_TO_COURSE',
    SOMEONE_SEND_US_MONEY = 'SOMEONE_SEND_US_MONEY',
    NOTIFICATION_WHEN_CREATE_SUBDOMAIN_CHANNEL_FAIL = 'NOTIFICATION_WHEN_CREATE_SUBDOMAIN_CHANNEL_FAIL',
    NOTIFICATION_WHEN_CREATE_SUBDOMAIN_CHANNEL_SUCCESS = 'NOTIFICATION_WHEN_CREATE_SUBDOMAIN_CHANNEL_SUCCESS',
    NAVIGATION_COURSE_CREATED = "NAVIGATION_COURSE_CREATED",
    NOTIFICATION_ORDER_SUCCESS = "NOTIFICATION_ORDER_SUCCESS",
    NAVIGATION_REDEEM_CREATED = "NAVIGATION_REDEEM_CREATED",
    NAVIGATION_GIFT_SENDED = "NAVIGATION_GIFT_SENDED",
    NAVIGATION_REDEEM_COMPLETED = "NAVIGATION_REDEEM_COMPLETED",
    NAVIGATION_CHALLENGE_ACTIVITY = "NAVIGATION_CHALLENGE_ACTIVITY",
    NAVIGATION_ORDER_CREATED = "NAVIGATION_ORDER_CREATED",
}

/**
 * parse notification data to link ...
 * @param notification_data
 */

/**
{
    screen?: string,
    router?: string,
    param?: string
}

 */
export default function notification_parse_action( notification_data: string ): string {
    let dataParamString = notification_data || "{}";
    if(!helpers.isJson(dataParamString)) return;
    let dataParam: {
        screen?: EnumNotificationScreen,
        router?: string,
        param?: string
    } = JSON.parse(dataParamString);

    let router = dataParam.router || '';
    let param = dataParam.param || '';
    if ( ! router) return '';
    let homepage = window.location.protocol +'//'+ window.location.host;

    switch( dataParam?.screen ) {
        case EnumNotificationScreen.POST_NEED_APPROVING:
            return `/post/approval-list`;
        case EnumNotificationScreen.POST_IS_LIKED:
        case EnumNotificationScreen.POST_IS_CREATED:
        case EnumNotificationScreen.POST_IS_APPROVED:
            return `/post/detail/${router}`;
        case EnumNotificationScreen.COMMENT_IS_LIKED:
            return `/post/detail/${router}#${param}`;
        case EnumNotificationScreen.NAVIGATION_CHAT_ROOM:
            return `/chat/detail/${router}`;
        case EnumNotificationScreen.COMMENT_IS_CREATED:
            return `/post/detail/${router}#${dataParam?.param||""}`;
        case EnumNotificationScreen.NAVIGATION_CHALLENGE_CREATED:
        case EnumNotificationScreen.NAVIGATION_CHALLENGE_CHECKIN:
        case EnumNotificationScreen.NAVIGATION_CHALLENGE_CHECKIN_APPROVE:
        case EnumNotificationScreen.NAVIGATION_CHALLENGE_REQUIRE_JOIN_APPROVED:
        case EnumNotificationScreen.NAVIGATION_CHALLENGE_REQUIRE_JOIN_NEED_APPROVED:
        case EnumNotificationScreen.NAVIGATION_CHALLENGE_ACTIVITY:
            return `/challenge/detail/${router}`;
        case EnumNotificationScreen.NAVIGATION_LIVESTREAM_CREATED:
            return `/live-room/${router}`;
        case EnumNotificationScreen.NAVIGATION_ORDER_CREATED:
            return `/orders/approve/${router}`;
        case EnumNotificationScreen.NOTIFICATION_ORDER_SUCCESS:
            return `/orders/detail/${router}`;
        case EnumNotificationScreen.COURSE_AFTER_CREATED:
        case EnumNotificationScreen.NAVIGATION_COURSE_CREATED:
        case EnumNotificationScreen.COURSE_AFTER_ADDED_USER_TO_COURSE:
        case EnumNotificationScreen.NOTIFICATION_BUY_COURSE_SUCCESS:
            return `/courses/view/${router}`;
        case EnumNotificationScreen.GIFT_AFTER_CREATED:
            return `/gift/detail/${router}`;
        case EnumNotificationScreen.WITHDRAW_REQUEST:
            return `/mentor/payment-management`;
        case EnumNotificationScreen.NAVIGATION_REDEEM_COMPLETED:
        case EnumNotificationScreen.NAVIGATION_REDEEM_CREATED:
            return `/missions`;
        case EnumNotificationScreen.NAVIGATION_GIFT_SENDED:
            return `/gift/receivers`;
        case EnumNotificationScreen.NOTIFICATION_MEMBER_REQUEST_JOIN:
        case EnumNotificationScreen.REQUEST_JOIN_YOUR_CHANNEL:
            return `/member#waiting`;
        case EnumNotificationScreen.SOMEONE_SEND_US_MONEY:
            return `/`;
        default:
            return '';
    }


}
