import { SvgIcon, SvgIconProps } from '@mui/material'

const UserAddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M11.4983 1C8.02829 1 5.17829 3.85 5.17829 7.32C5.17829 9.45 6.24829 11.33 7.86829 12.47C4.09829 13.99 1.41829 17.68 1.41829 21.98C1.38829 23.34 3.43829 23.34 3.40829 21.98C3.40829 17.41 7.08829 13.73 11.6583 13.73C13.2583 13.73 14.7383 14.18 15.9983 14.96C17.1283 15.65 18.1783 13.96 17.0383 13.26C16.4683 12.91 15.8583 12.64 15.2183 12.4C16.7783 11.25 17.8083 9.4 17.8083 7.32C17.8083 3.84 14.9683 1 11.4983 1ZM11.4983 2.99C13.8983 2.99 15.8183 4.92 15.8183 7.32C15.8183 9.72 13.8983 11.64 11.4983 11.64C9.09829 11.64 7.16829 9.72 7.16829 7.32C7.16829 4.92 9.09829 2.99 11.4983 2.99ZM17.6083 19.93V21.92C17.6383 23.22 19.5683 23.22 19.5983 21.92V19.93H21.5883C22.9183 19.93 22.9183 17.94 21.5883 17.94H19.5983V15.95C19.5983 15.39 19.1483 14.94 18.5883 14.94C18.0383 14.94 17.5983 15.4 17.5983 15.95V17.94H15.5583C14.1783 18 14.2783 20 15.6083 19.93H17.5983H17.6083Z" />
        </svg>
      </svg>
    </SvgIcon>
  )
}

export default UserAddIcon
