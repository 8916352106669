export function mergeArrayObjectsByKey(
  arr1: Array<Record<string, any>>,
  arr2: Array<Record<string, any>>,
  key: string
) {
  return arr1.map(item => {
    const combinedObj = arr2.find(obj2 => obj2[key] === item[key])
    if (combinedObj) {
      return { ...item, ...combinedObj }
    }
    return item
  })
}
