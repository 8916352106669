import { SvgIcon, SvgIconProps } from '@mui/material'

const LookupIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'21'}
        height={'21'}
        viewBox="0 0 21 21"
        fill="none"
        stroke="currentColor"
      >
        <g clipPath="url(#clip0_90_1418)">
          <path
            d="M18.7355 19.2784L14.9855 15.5284M17.0688 10.1118C17.0688 14.2539 13.711 17.6118 9.56885 17.6118C5.42671 17.6118 2.06885 14.2539 2.06885 10.1118C2.06885 5.96962 5.42671 2.61176 9.56885 2.61176C13.711 2.61176 17.0688 5.96962 17.0688 10.1118Z"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_90_1418">
            <rect width="20" height="20" fill="white" transform="translate(0.402344 0.945068)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default LookupIcon
