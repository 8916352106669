import { SvgIcon, SvgIconProps } from '@mui/material'

const GoogleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_3132_19574)">
          <path
            d="M5.31891 14.5036L4.4835 17.6223L1.43011 17.6869C0.517594 15.9944 0 14.0579 0 12.0001C0 10.0102 0.483938 8.13374 1.34175 6.48145H1.34241L4.06078 6.97982L5.25159 9.68188C5.00236 10.4085 4.86652 11.1885 4.86652 12.0001C4.86661 12.881 5.02617 13.725 5.31891 14.5036Z"
            fill="#FBBB00"
          />
          <path
            d="M23.7902 9.7583C23.928 10.4842 23.9999 11.2339 23.9999 12.0001C23.9999 12.8592 23.9095 13.6972 23.7375 14.5056C23.1533 17.2563 21.6269 19.6583 19.5124 21.3581L19.5118 21.3574L16.0878 21.1827L15.6032 18.1576C17.0063 17.3348 18.1028 16.0471 18.6804 14.5056H12.2637V9.7583H18.774H23.7902Z"
            fill="#518EF8"
          />
          <path
            d="M19.5114 21.3572L19.5121 21.3579C17.4556 23.0109 14.8433 23.9999 11.9996 23.9999C7.42969 23.9999 3.45652 21.4456 1.42969 17.6867L5.31848 14.5034C6.33188 17.208 8.94089 19.1333 11.9996 19.1333C13.3143 19.1333 14.546 18.7779 15.6029 18.1575L19.5114 21.3572Z"
            fill="#28B446"
          />
          <path
            d="M19.6596 2.76262L15.7721 5.94525C14.6783 5.26153 13.3853 4.86656 12 4.86656C8.87213 4.86656 6.21431 6.88017 5.25169 9.68175L1.34245 6.48131H1.3418C3.33895 2.63077 7.36223 0 12 0C14.9117 0 17.5814 1.03716 19.6596 2.76262Z"
            fill="#F14336"
          />
        </g>
        <defs>
          <clipPath id="clip0_3132_19574">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default GoogleIcon
