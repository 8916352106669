import { SvgIcon, SvgIconProps } from '@mui/material'

const BarChartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.50033 1.6665C2.96056 1.6665 3.33366 2.0396 3.33366 2.49984V12.1665C3.33366 13.1137 3.33431 13.7739 3.37631 14.288C3.41751 14.7923 3.49432 15.082 3.60614 15.3015C3.84583 15.7719 4.22828 16.1543 4.69868 16.394C4.91815 16.5058 5.20789 16.5827 5.71219 16.6239C6.22623 16.6659 6.88649 16.6665 7.83366 16.6665H17.5003C17.9606 16.6665 18.3337 17.0396 18.3337 17.4998C18.3337 17.9601 17.9606 18.3332 17.5003 18.3332H7.79797C6.89482 18.3332 6.16637 18.3332 5.57647 18.285C4.9691 18.2354 4.43561 18.1305 3.94203 17.879C3.15802 17.4796 2.5206 16.8421 2.12113 16.0581C1.86964 15.5646 1.7648 15.0311 1.71517 14.4237C1.66698 13.8338 1.66698 13.1053 1.66699 12.2022L1.66699 2.49984C1.66699 2.0396 2.04009 1.6665 2.50033 1.6665ZM10.8337 1.6665C11.2939 1.6665 11.667 2.0396 11.667 2.49984V14.1665C11.667 14.6267 11.2939 14.9998 10.8337 14.9998C10.3734 14.9998 10.0003 14.6267 10.0003 14.1665L10.0003 2.49984C10.0003 2.0396 10.3734 1.6665 10.8337 1.6665ZM15.0003 5.83317C15.4606 5.83317 15.8337 6.20627 15.8337 6.6665V14.1665C15.8337 14.6267 15.4606 14.9998 15.0003 14.9998C14.5401 14.9998 14.167 14.6267 14.167 14.1665V6.6665C14.167 6.20627 14.5401 5.83317 15.0003 5.83317ZM6.66699 9.1665C7.12723 9.1665 7.50033 9.5396 7.50033 9.99984V14.1665C7.50033 14.6267 7.12723 14.9998 6.66699 14.9998C6.20676 14.9998 5.83366 14.6267 5.83366 14.1665V9.99984C5.83366 9.5396 6.20676 9.1665 6.66699 9.1665Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default BarChartIcon
