import { SvgIcon, SvgIconProps } from '@mui/material'

const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 48095855">
          <g id="Group">
            <path
              id="Vector"
              d="M31.9145 30.3118V3.97784C31.9145 3.19504 31.3141 2.55664 30.5693 2.55664H4.18969C3.44489 2.55664 2.84448 3.19504 2.84448 3.97784V30.3118H31.9145Z"
              fill="url(#paint0_linear_1101_5189)"
            />
            <path
              id="Vector_2"
              d="M31.9145 30.3118V3.97784C31.9145 3.19504 31.3141 2.55664 30.5693 2.55664H4.18969C3.44489 2.55664 2.84448 3.19504 2.84448 3.97784V30.3118H31.9145Z"
              fill="url(#paint1_linear_1101_5189)"
            />
            <path
              id="Vector_3"
              d="M30.5693 34.7199H4.18969C3.44489 34.7199 2.84448 34.0815 2.84448 33.2987V17.9087H31.9069V33.2987C31.9069 34.0815 31.3065 34.7199 30.5617 34.7199H30.5693Z"
              fill="url(#paint2_linear_1101_5189)"
            />
            <path
              id="Vector_4"
              d="M33.1383 31.9461H1.62114C0.762335 31.9461 0.123937 31.0569 0.313937 30.1221L2.84474 17.8101H31.9147L34.4455 30.1221C34.6355 31.0569 33.9971 31.9461 33.1383 31.9461Z"
              fill="url(#paint3_linear_1101_5189)"
            />
            <path
              id="Vector_5"
              opacity="0.5"
              d="M11.6989 5.08744C11.4557 4.84424 11.3037 4.50224 11.3037 4.12224V2.55664H13.8117L15.8561 4.72264C16.5097 5.41424 16.8745 6.34904 16.8745 7.32944C16.8745 8.04384 16.3577 8.65944 15.6813 8.68984C15.2937 8.70504 14.9517 8.54544 14.7085 8.27184L11.7065 5.08744H11.6989Z"
              fill="url(#paint4_linear_1101_5189)"
            />
            <path
              id="Vector_6"
              opacity="0.5"
              d="M20.8037 5.08744C20.5605 4.84424 20.4084 4.50224 20.4084 4.12224V2.55664H22.9165L24.9608 4.72264C25.6144 5.41424 25.9792 6.34904 25.9792 7.32944C25.9792 8.04384 25.4625 8.65944 24.786 8.68984C24.3984 8.70504 24.0565 8.54544 23.8133 8.27184L20.8112 5.08744H20.8037Z"
              fill="url(#paint5_linear_1101_5189)"
            />
            <path
              id="Vector_7"
              opacity="0.5"
              d="M33.1388 31.9456C33.9976 31.9456 34.636 31.0564 34.446 30.1216L31.9152 17.8096H2.85278L15.56 31.9456H33.1464H33.1388Z"
              fill="url(#paint6_linear_1101_5189)"
            />
          </g>
          <g id="Group_2">
            <path
              id="Vector_8"
              d="M10.4446 20.6298V14.6334C10.4446 13.0526 10.8398 11.8898 11.6302 11.1526C12.4206 10.4078 13.477 10.043 14.7994 10.043C16.1218 10.043 17.1858 10.4154 17.9838 11.1526C18.7818 11.8974 19.1846 13.0526 19.1846 14.6334V20.6298C19.1846 22.2106 18.7894 23.3734 17.9838 24.1106C17.1858 24.8554 16.1218 25.2202 14.7994 25.2202C13.477 25.2202 12.4206 24.8478 11.6302 24.1106C10.8398 23.3658 10.4446 22.2106 10.4446 20.6298ZM12.5042 20.6298C12.5042 22.4006 13.2718 23.2898 14.7994 23.2898C15.5366 23.2898 16.1066 23.0694 16.5094 22.6286C16.9122 22.1878 17.1098 21.5266 17.1098 20.6298V14.6334C17.1098 13.7442 16.9122 13.0754 16.5094 12.6346C16.1066 12.1938 15.5366 11.9734 14.7994 11.9734C13.2718 11.9734 12.5042 12.8626 12.5042 14.6334V20.6298Z"
              fill="white"
            />
            <path
              id="Vector_9"
              d="M20.4466 13.1512C20.4466 12.794 20.5834 12.5128 20.857 12.3076L22.8786 10.248C23.023 10.096 23.1978 10.02 23.403 10.02C23.6386 10.02 23.8438 10.0884 24.0338 10.2176C24.2238 10.3468 24.315 10.5216 24.315 10.742V24.384C24.315 24.6044 24.2086 24.7792 24.0034 24.9084C23.7982 25.0376 23.555 25.106 23.2814 25.106C23.0078 25.106 22.7494 25.0376 22.5518 24.9084C22.3542 24.7792 22.2478 24.6044 22.2478 24.384V12.9308L21.5638 13.8352C21.4346 13.972 21.2902 14.0404 21.1382 14.0404C20.9406 14.0404 20.781 13.9492 20.6442 13.7592C20.5074 13.5768 20.439 13.3716 20.439 13.1512H20.4466Z"
              fill="white"
            />
            <path
              id="Vector_10"
              d="M12.5501 5.44482C11.8585 5.44482 11.2961 4.85202 11.2961 4.12242V1.84242C11.2961 1.11282 11.8585 0.52002 12.5501 0.52002C13.2417 0.52002 13.8041 1.11282 13.8041 1.84242V4.11482C13.8041 4.84442 13.2417 5.44482 12.5501 5.44482Z"
              fill="url(#paint7_linear_1101_5189)"
            />
            <path
              id="Vector_11"
              d="M21.6551 5.44482C20.9635 5.44482 20.4011 4.85202 20.4011 4.12242V1.84242C20.4011 1.11282 20.9635 0.52002 21.6551 0.52002C22.3467 0.52002 22.9091 1.11282 22.9091 1.84242V4.11482C22.9091 4.84442 22.3467 5.44482 21.6551 5.44482Z"
              fill="url(#paint8_linear_1101_5189)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1101_5189"
            x1="12.4965"
            y1="6.71384"
            x2="21.0237"
            y2="24.627"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC200" />
            <stop offset="0.27" stopColor="#FFBB00" />
            <stop offset="0.66" stopColor="#FFA801" />
            <stop offset="1" stopColor="#FF9102" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1101_5189"
            x1="3.33848"
            y1="16.4342"
            x2="31.7929"
            y2="16.4342"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#69C6FF" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1101_5189"
            x1="2.85208"
            y1="26.3143"
            x2="31.9145"
            y2="26.3143"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#006699" />
            <stop offset="1" stopColor="#00587A" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1101_5189"
            x1="17.3835"
            y1="17.6049"
            x2="17.3835"
            y2="32.1133"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#69C6FF" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1101_5189"
            x1="15.2025"
            y1="6.31864"
            x2="12.3905"
            y2="3.65864"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0E91E2" stopOpacity="0" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1101_5189"
            x1="25.7512"
            y1="8.17304"
            x2="21.6701"
            y2="3.53704"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0E91E2" stopOpacity="0" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1101_5189"
            x1="18.6684"
            y1="32.09"
            x2="18.6684"
            y2="17.536"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0E91E2" stopOpacity="0" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1101_5189"
            x1="11.2961"
            y1="2.98242"
            x2="13.8041"
            y2="2.98242"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#006699" />
            <stop offset="1" stopColor="#00587A" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1101_5189"
            x1="20.4011"
            y1="2.98242"
            x2="22.9091"
            y2="2.98242"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#006699" />
            <stop offset="1" stopColor="#00587A" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default CalendarIcon
