import example from '../entities/example/example.store.reducer'
import mission from 'entities/mission/store/mission.store.reduce'
import courses from '../entities/courses/courses.store.reducer'
import gift from '../entities/gift/gift.store.reducer'
import calendar from '../entities/calendar/calendar.store.reducer'
import user from './user.store.reducer'
import challenge from '../entities/challenge/challenge.store.reducer'
import mentor from '../entities/mentor/store/mentor.store.reducer'
import order from '../entities/orders/order.store.reducer'
import payment from '../entities/payment/payment.store.reducer'
import extensions from '../entities/extentions/extensions.store.reducer'
import ui from './ui.slice'
import homeSlice from '../entities/homeNew/store/homeSlice'
import global_notification from './global.warning.store.reducer'
import global_process_bar from './global_process_bar.store.reducer'
import approval_post from 'entities/approvalPost/store/approval.store.reducer'
import detail_user from 'entities/user/store/user.store.reducer'
import about from 'entities/about/store/about.store.reducer'
import notification from 'entities/notification/store/notification.store.reducer'
import channel from 'entities/channel/store/channel.store.reducer'
import channel_setting from 'entities/channelSetting/store/channelSetting.store.reduce.ts'
import home from 'entities/homepage/home.store.reducer'
import podcast from '../podcast/store/podcastSlice'
import challengeApi from 'entities/challenge/store/challengeApi'
import supportApi from 'entities/support/store/supportApi'
import domainApi from 'entities/domain/store/domainApi'
import podcastApi from '../podcast/store/podcastApi'
import member from 'entities/member/store/member.store.reducer'
import leader_board from 'entities/leaderBoard/store/leaderBoard.store.reducer'
import validate_code from 'entities/validateCode/store/validateCode.store.reducer'
import referrerApi from 'entities/ReferrerTree/store/referrerApi'
import auth from 'store/auth.store.reducer'
import chat from 'entities/chat/store/chat.store.reducer'
import homeApi from 'entities/homeNew/store/homeApi'
import rankingApi from 'entities/ranking/store/rankingApi'
import cartSlice from 'entities/shop/store/cart/cart.slice'
import channelSlice from 'entities/channel-settings/store/channel.slice'
import shop from 'entities/shop/store'
import referral from 'entities/referral/store'

const rootReducer = {
  extensions,
  example,
  user,
  detail_user,
  global_process_bar,
  calendar,
  courses,
  mentor,
  challenge,
  gift,
  order,
  payment,
  ui,
  global_notification,
  mission,
  approval_post,
  about,
  notification,
  channel,
  channel_setting,
  home,
  podcast,
  member,
  leader_board,
  validate_code,
  auth,
  chat,
  homeSlice,
  cart: cartSlice,
  channelSlice,
  shop,
  referral,
  [challengeApi.reducerPath]: challengeApi.reducer,
  [supportApi.reducerPath]: supportApi.reducer,
  [domainApi.reducerPath]: domainApi.reducer,
  [podcastApi.reducerPath]: podcastApi.reducer,
  [referrerApi.reducerPath]: referrerApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [rankingApi.reducerPath]: rankingApi.reducer
}

export default rootReducer
