import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckedboxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} stroke="currentColor">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="currentColor"
      >
        <path
          d="M0.350586 4C0.350586 1.79086 2.14145 0 4.35059 0H16.3506C18.5597 0 20.3506 1.79086 20.3506 4V16C20.3506 18.2091 18.5597 20 16.3506 20H4.35059C2.14145 20 0.350586 18.2091 0.350586 16V4Z"
          // fill="#DC3545"
        />
        <path
          d="M6.30559 9.23574H6.3054H5.44882C5.2416 9.23574 5.12591 9.47446 5.25383 9.6367L5.25388 9.63676L8.61036 13.889L8.61041 13.8891C8.82755 14.1639 9.24394 14.1632 9.46184 13.8895L9.46225 13.889L15.4465 6.30583C15.4466 6.30564 15.4468 6.30544 15.4469 6.30524C15.5774 6.14208 15.4569 5.90499 15.2524 5.90499H14.3958C14.2301 5.90499 14.0724 5.9809 13.9701 6.11196C13.97 6.11207 13.9699 6.11219 13.9699 6.1123L9.0357 12.3629L6.73083 9.44243C6.7308 9.4424 6.73078 9.44237 6.73075 9.44234C6.6801 9.37805 6.61554 9.32607 6.54193 9.29029C6.46828 9.25449 6.38747 9.23584 6.30559 9.23574Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
      </svg>
    </SvgIcon>
  )
}

export default CheckedboxIcon
