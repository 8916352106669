import { SvgIcon, SvgIconProps } from '@mui/material'

const PinOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8157 3.11426C13.0985 1.98313 14.5071 1.59295 15.3315 2.4174L14.6244 3.12451M15.3315 2.4174L22.0827 9.16852C22.9071 9.99296 22.5169 11.4016 21.3858 11.6844L18.78 12.3358L18.5374 11.3657L18.78 12.3359C18.6041 12.3798 18.4436 12.4707 18.3154 12.5989L16.1785 14.7358C16.0255 14.8888 15.9263 15.0873 15.8957 15.3015L15.0465 21.2455C14.9067 22.2244 13.7093 22.6235 13.0101 21.9243L8.5 17.4143L3.70711 22.2072C3.31658 22.5977 2.68342 22.5977 2.29289 22.2072C1.90237 21.8166 1.90237 21.1835 2.29289 20.793L7.08579 16.0001L2.57574 11.49C1.87651 10.7908 2.27564 9.59339 3.25456 9.45355L3.39598 10.4435L3.25456 9.45355L9.19861 8.6044C9.41281 8.5738 9.6113 8.47455 9.7643 8.32155L11.9012 6.18467C12.0293 6.05651 12.1203 5.89593 12.1642 5.7201L13.1344 5.96264L12.1642 5.7201L12.8157 3.11426M14.5418 4.45606L14.1045 6.20517C13.9726 6.73267 13.6999 7.21441 13.3154 7.59889L11.1785 9.73577C10.7195 10.1948 10.124 10.4925 9.48145 10.5843L5.12132 11.2072L13.2929 19.3787L13.9158 15.0186C14.0076 14.376 14.3053 13.7805 14.7643 13.3216L16.9012 11.1847C17.2857 10.8002 17.7674 10.5274 18.2949 10.3956L20.044 9.95829L14.5418 4.45606ZM14.7558 3.60006C14.7558 3.59982 14.7559 3.59957 14.756 3.59933L14.2709 3.47806L14.756 3.59933L14.7558 3.60006Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default PinOutlinedIcon
