import { SvgIcon, SvgIconProps } from '@mui/material'

const CrownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="26"
        viewBox="0 0 30 26"
        fill="none"
      >
        <path
          d="M22.6238 12.4908C22.5349 12.4909 22.4473 12.4687 22.3689 12.4263C22.2905 12.3839 22.2238 12.3225 22.1747 12.2477L19.645 8.40176C19.5796 8.30202 19.5486 8.18325 19.557 8.06393C19.5654 7.94461 19.6126 7.83142 19.6913 7.74196L22.8669 4.13043C22.994 3.98309 23.151 3.865 23.3273 3.78413C23.5036 3.70325 23.695 3.66148 23.8886 3.66162C23.9849 3.66185 24.081 3.67185 24.1753 3.69146C24.4127 3.73916 24.6332 3.84974 24.8143 4.01184C24.9954 4.17394 25.1304 4.38173 25.2056 4.61388L26.8937 9.74795C26.9341 9.86962 26.9304 10.0018 26.8834 10.121C26.8363 10.2402 26.7489 10.3387 26.6367 10.3991L22.8755 12.4278C22.7981 12.4696 22.7116 12.4912 22.6238 12.4908Z"
          fill="#FAAD14"
        />
        <path
          d="M7.37838 12.4904C7.29015 12.4907 7.20326 12.4687 7.12563 12.4264L3.36496 10.3981C3.25272 10.3378 3.16532 10.2393 3.11826 10.1201C3.07119 10.0009 3.06753 9.8687 3.1079 9.74703L4.79544 4.61296C4.87069 4.38073 5.00584 4.1729 5.18701 4.01079C5.36818 3.84869 5.58885 3.73816 5.82636 3.69054C5.92133 3.67042 6.0181 3.66024 6.11514 3.66016C6.3089 3.6599 6.50045 3.70173 6.67676 3.78281C6.85307 3.86389 7.01001 3.98232 7.13692 4.13005L10.3125 7.74158C10.3909 7.83129 10.4377 7.9446 10.4457 8.06392C10.4536 8.18324 10.4223 8.30187 10.3566 8.40138L7.82904 12.2484C7.77967 12.3233 7.71265 12.3846 7.63398 12.4268C7.55532 12.469 7.46749 12.4909 7.37838 12.4904Z"
          fill="#FAAD14"
        />
        <path
          d="M5.58478 25.5C5.28731 25.4986 4.99815 25.4008 4.76003 25.2209C4.5219 25.041 4.34743 24.7886 4.26239 24.501L0.0564415 10.0679C-0.0205743 9.80485 -0.0187349 9.52467 0.0617281 9.26266C0.142191 9.00065 0.297681 8.76851 0.508623 8.59547C0.719565 8.42243 0.97653 8.31623 1.24717 8.29022C1.51781 8.26422 1.79002 8.31958 2.02954 8.44934L7.19972 11.2394L13.852 1.12209C13.9777 0.930809 14.1484 0.773898 14.3489 0.665352C14.5493 0.556806 14.7732 0.5 15.0007 0.5C15.2282 0.5 15.4521 0.556806 15.6525 0.665352C15.853 0.773898 16.0237 0.930809 16.1494 1.12209L22.8011 11.2378L27.9713 8.44771C28.2108 8.31858 28.4828 8.26369 28.7531 8.28992C29.0234 8.31615 29.2801 8.42233 29.4908 8.59514C29.7015 8.76796 29.857 8.99971 29.9377 9.26133C30.0184 9.52295 30.0207 9.80279 29.9444 10.0657L25.7385 24.4989C25.6535 24.7866 25.479 25.0391 25.2407 25.219C25.0024 25.3989 24.7131 25.4967 24.4155 25.4978L5.58478 25.5Z"
          fill="url(#paint0_linear_1881_14636)"
        />
        <path
          d="M25.7384 24.501L26.1088 23.2755L3.89941 23.2754L4.26228 24.501C4.34732 24.7886 4.52179 25.041 4.75992 25.2209C4.99805 25.4008 5.28721 25.4986 5.58468 25.4999H24.4155C24.713 25.4988 25.0024 25.4011 25.2406 25.2212C25.4789 25.0413 25.6534 24.7887 25.7384 24.501Z"
          fill="#FAAD14"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1881_14636"
            x1="15"
            y1="0.5"
            x2="15"
            y2="25.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD87D" />
            <stop offset="1" stopColor="#FFC53D" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default CrownIcon
