//Library
import axios, {AxiosError, AxiosResponse} from 'axios'
import {useCallback, useEffect, useState} from 'react'
import {notEmpty, useField, useForm} from '@shopify/react-form'
import { Form} from 'react-bootstrap'
import {  Button, Typography } from '@mui/material'

//Components
import TextField from 'components/TextField'

//Helpers
import helpers from 'helpers'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import { getDataChannelSetting, updateChannel, uploadImage} from '../store/channelSetting.store.reduce.ts'

//Data
import {listBankMock} from 'entities/infoPayment/data'

//Image
import iconImage from '../media/icons/icon_image.svg'
import imageDefault from 'media/images/image_default.png'

//Scss
import '../media/channel.setting.payment.scss'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import TypedMedia from 'interfaces/media.interface.js'
import { validateLabel } from 'entities/support/utils'

interface TypeDataBank {
  bank_account_name?: string
  bank_account_number?: string
  bank_brand_name?: string
  bank_name?: string
  bank_qr_code?: unknown | null
}

const ChannelSettingPayment = () => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listDataBank, setListDataBank] = useState([])
  const [dataBank, setDataBank] = useState<TypeDataBank>(null)
  const toast = useToast()

  const { fields, submit, dirty, submitting } = useForm({
    fields: {
      bank_name: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_payment_bank')}`)?.required)]
      }),
      bank_brand_name: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_payment_branch_bank')}`)?.required)]
      }),
      bank_account_name: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_payment_receiver')}`)?.required)]
      }),
      bank_account_number: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('channel_setting_payment_recipient_account')}`)?.required)]
      }),
      bank_qr_code: useField<TypedMedia>({
        value: null,
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          _id: channel_data?._id,
          bank_account_name: values?.bank_account_name.trim(),
          bank_account_number: `${values?.bank_account_number}`,
          bank_brand_name: values?.bank_brand_name.trim(),
          bank_name: values?.bank_name,
          // @ts-ignore
          bank_qr_code: values?.bank_qr_code?._id
        }
        setIsLoading(true)
        dispatch(updateChannel(formData))
          .unwrap()
          .then((res: AxiosResponse) => {
            const data = {
              bank_account_name: res?.data?.bank_account_name,
              bank_account_number: res?.data?.bank_account_number,
              bank_brand_name: res?.data?.bank_brand_name,
              bank_name: res?.data?.bank_name,
              bank_qr_code: res?.data?.bank_qr_code
            }
            setDataBank(data)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_info_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateChannel_${error}`)
            setDataBank(null)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_info_failed")}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target
      fields.bank_name.onChange(value)
    },
    [fields]
  )

  const handleImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]
      if (!file) return
      let formData = new FormData()
      formData.append('file[]', file)
      dispatch(uploadImage(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          fields.bank_qr_code.onChange(res?.data[0]?.callback)
          fields.bank_qr_code.setError('')
        })
        .catch((error: AxiosError) => {
          console.log(`uploadImage_${error}`)
        })
    },
    [fields]
  )

  useEffect(() => {
      dispatch(getDataChannelSetting())
        .unwrap()
        .then((res: AxiosResponse) => {
          const response = res?.data?.[0]
          const data = {
            bank_account_name: response?.bank_account_name || '',
            bank_account_number: response?.bank_account_number || '',
            bank_brand_name: response?.bank_brand_name || '',
            bank_name: response?.bank_name || '',
            bank_qr_code: response?.bank_qr_code || null
          }
          setDataBank(data)
        })
        .catch((error: AxiosError) => {
          console.log(`getDataChannel_${error}`)
          setDataBank(null)
          toast.show({
            content: `${__("channel_setting_error")}`,
            type: EnumTypeToast.Error
          })
        })
  }, [])

  useEffect(() => {
    if (helpers.isEmpty(dataBank)) return
    Object.keys(fields).forEach((item: string) => {
      fields[item]?.onChange(dataBank[item])
    })
  }, [dataBank])

  useEffect(() => {
    axios
      .get(`https://api.vietqr.io/v2/banks`)
      .then(res => {
        setListDataBank(res?.data?.data)
      })
      .catch(error => {
        console.log(error)
        setListDataBank(listBankMock)
      })
  }, [])

  return (
    <div id="channelSettingPayment" className="channelSettingPayment_container">
      <div className="channelSettingPoint_header">
        <h4 className="channelSettingPoint_header_title">{__("channel_setting_payment")}</h4>
        <span className="channelSettingPoint_header_desc">
          {__("channel_setting_payment_sub_text")}
        </span>
      </div>
      <Form onSubmit={submit} className="channelSettingPayment_form_wrapper">
        <div className="channelSettingPayment_form">
          <div className="channelSettingPayment_form_item">
            <label className="fw-bold mb-1">{__("channel_setting_payment_bank")}</label>
            <Form.Select
              name="bank_name"
              value={fields?.bank_name.value}
              onChange={handleChangeSelect}
              className={`form-select`}
              style={fields.bank_name.error ? {border: `1px solid #dc3545`} : {}}
              aria-label="Default select example"
            >
              <option value="" disabled>
                {__("channel_setting_payment_chose_bank")}
              </option>
              {listDataBank?.map((item, idx) => (
                <option
                  key={`option_${idx}`}
                  value={item?.shortName}
                >{`${item?.shortName} - ${item?.name}`}</option>
              ))}
            </Form.Select>
              { fields.bank_name.error && (
                  <Typography component={'small'} sx={{
                    fontSize: theme=>theme.spacing(1.75),
                    color: '#dc3545'
                  }}>
                    {fields.bank_name.error}
                </Typography>
            )}
          </div>
          <div className="channelSettingPayment_form_item">
            <label className="fw-bold mb-1">{__("channel_setting_payment_branch_bank")}</label>
            <TextField
              {...fields.bank_brand_name}
              autoFocus
              maxLength={50}
              placeholder={__("channel_setting_payment_branch_bank_placeholder")}
            />
          </div>
          <div className="channelSettingPayment_form_item">
            <label className="fw-bold mb-1">{__("channel_setting_payment_receiver")}</label>
            <TextField
              {...fields.bank_account_name}
              maxLength={50}
              placeholder={__("channel_setting_payment_receiver_placeholder")}
            />
          </div>
          <div className="channelSettingPayment_form_item">
            <label className="fw-bold mb-1">{__("channel_setting_payment_recipient_account")}</label>
            <TextField
              {...fields.bank_account_number}
              maxLength={50}
              onChange={(value: string) =>
                fields.bank_account_number.onChange(value)
              }
              placeholder={__("channel_setting_payment_recipient_account_placeholder")}
            />
          </div>
          <div className="channelSettingPayment_form_item">
            <label className="fw-bold mb-1">{__("channel_setting_payment_QR_code")}</label>
            {fields.bank_qr_code.value ? (
              <div className="channelSettingPayment_image_view_wrapper">
                <img
                  src={fields?.bank_qr_code?.value?.media_url || fields?.bank_qr_code?.value?.media_thumbnail}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = imageDefault
                  }}
                  width={60}
                  height={60}
                  alt="icon"
                  loading="lazy"
                />
                <span
                  onClick={() => fields.bank_qr_code.onChange(null)}
                  className="channelSettingPayment_delete_image_title"
                >
                  {__("channel_setting_payment_remove_photo")}
                </span>
              </div>
            ) : (
              <>
                <input
                  type="file"
                  id="upload_image"
                  name="filename"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleImageUpload}
                  multiple
                  className="channelSettingPayment_image_input"
                />
                <label
                  htmlFor="upload_image"
                  role="button"
                  className="channelSettingPayment_image_label"
                >
                  <img src={iconImage} width={60} height={60} alt="icon" loading="lazy" />
                  <span className={`channelSettingPayment_image_title`}>
                    {__("channel_setting_payment_size_photo")}
                  </span>
                  <Button component={'label'}
                    htmlFor="upload_image"
                    className="channelSettingPayment_image_button_label"
                  >
                    {__("channel_setting_payment_approval_file")}
                  </Button>
                </label>
              </>
            )}
          </div>
        </div>
        <div className="channelSettingPayment_button">
          <Button sx={{textTransform: 'none'}}
            onClick={submit}
            disabled={isLoading || !dirty || submitting}
          >
            {isLoading ? `${__("btn_processing")}` : `${__("btn_save")}`}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ChannelSettingPayment
