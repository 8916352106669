import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  ActivityType,
  AddUserToChallengePayload,
  AddUserToChallengeResponse,
  ChallengeListUserResponse,
  ChallengeUser,
  ChallengeUserInfo,
  CreateActivityPayload,
  GetJoinedListQueryParams,
  InjectPaginationQueryParams,
  JoinChallengeResponse,
  PaginationQueryParamsType,
  TypedChallengeDetail,
  UpdatePermissionPayload
} from 'entities/challenge/challenge.interface'

export const convertParamsToQueryString = (params: Record<string, string | number>) => {
  const paramKeys = Object.keys(params)
  let formattedParams: Record<string, string> = {}
  paramKeys.forEach(key => {
    if (!key) return
    formattedParams[key] = params[key].toString() || ''
  })
  return '?' + new URLSearchParams(formattedParams).toString()
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL + '/challenge',
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    return headers
  },
  credentials: 'same-origin'
})

const challengeApi = createApi({
  baseQuery,
  reducerPath: 'challengeApi',
  tagTypes: ['Detail', 'Permission', 'Activities'],
  refetchOnMountOrArgChange: 30,

  endpoints: build => ({
    getChallengeDetail: build.query<
      TypedChallengeDetail,
      {
        authId?: string
        challengeId: string
      }
    >({
      query: ({ authId, challengeId }: { authId?: string; challengeId: string }) => ({
        url: `/detail/${challengeId}`,
        method: 'GET',
        params: {
          auth_id: authId ?? undefined
        }
      }),
      providesTags: ['Detail']
    }),

    joinChallenge: build.mutation<JoinChallengeResponse, { authId?: string; challengeId: string }>({
      query({ challengeId, authId }) {
        return {
          url: '/join-permission',
          method: 'POST',
          body: {
            challenge_id: challengeId
          }
        }
      },
      invalidatesTags: ['Detail']
    }),

    getJoinedChallengeUsers: build.query<
      { data: ChallengeListUserResponse; totalCount: number },
      {
        search?: string
        page?: number
        limit?: number
        challenge_id: string
        official_status: number | string
      }
    >({
      query(params) {
        return `/list-member/${params?.challenge_id}${convertParamsToQueryString({ ...params })}`
      },
      providesTags: (result, error, arg, meta) => {
        return [{ type: 'Permission', id: arg.official_status }]
      },
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        // return endpointName
        const newQueryArgs = { ...queryArgs }
        if (newQueryArgs.page) {
          delete newQueryArgs.page
        }
        return newQueryArgs
      },
      transformResponse: (response: ChallengeListUserResponse, meta) => {
        return {
          data: response,
          totalCount: Number(meta.response.headers.get('X-Total-Count'))
        }
      },
      merge: (currentCacheData, newData, { arg, baseQueryMeta }) => {
        if (currentCacheData.data && arg.page !== 1) {
          return {
            ...currentCacheData,
            ...newData,
            data: [...currentCacheData.data, ...newData.data]
          }
        }
        return newData
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),

    leaveChallenge: build.mutation<
      TypedChallengeDetail,
      {
        challengeId: string
        userId: string
        queryParams?: GetJoinedListQueryParams
      }
    >({
      query({ challengeId, userId }) {
        return {
          url: '/delete-permission',
          method: 'DELETE',
          body: {
            challenge_id: challengeId,
            user_id: userId
          }
        }
      },
      invalidatesTags: ['Detail'],
      async onQueryStarted({ challengeId, userId, queryParams }, { dispatch, queryFulfilled }) {
        const getJoinedUsers = dispatch(
          challengeApi.util.updateQueryData(
            'getJoinedChallengeUsers',
            { challenge_id: challengeId, ...queryParams },
            draft => {
              draft.data = draft.data.filter(user => user.user_id._id !== userId)
              draft.totalCount -= 1
            }
          )
        )

        try {
          await queryFulfilled
        } catch (err) {
          getJoinedUsers.undo()
        }
      }
    }),

    addUsersToChallenge: build.mutation<
      AddUserToChallengeResponse,
      InjectPaginationQueryParams<AddUserToChallengePayload>
    >({
      query({ challengeId, userIds, officialStatus }) {
        return {
          url: '/create-permission',
          method: 'POST',
          body: {
            challenge_id: challengeId,
            user_id: userIds.join(','),
            official_status: '1'
          }
        }
      },
      async onQueryStarted(
        { challengeId, userIds, officialStatus, queryParams },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: addedUsers } = await queryFulfilled
          const patchResult = dispatch(
            challengeApi.util.updateQueryData(
              'getJoinedChallengeUsers',
              { challenge_id: challengeId, official_status: officialStatus, ...queryParams },
              draft => {
                draft.data = (addedUsers as ChallengeUser[]).concat(draft.data)
                draft.totalCount += addedUsers.length
              }
            )
          )
        } catch (err) {
          throw err
        }
      }
    }),

    updatePermission: build.mutation<
      Partial<ChallengeUserInfo>,
      {
        payload: UpdatePermissionPayload
        dependency?: InjectPaginationQueryParams<{ challenge_id: string }>
      }
    >({
      query({ payload, dependency }) {
        return {
          url: '/update-permission',
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: (result, error, arg, meta) => [
        { type: 'Permission', id: 0 },
        { type: 'Permission', id: arg.payload.official_status },
        'Detail'
      ],
      async onQueryStarted({ payload, dependency }, { dispatch, queryFulfilled }) {
        const getJoinedUser = dispatch(
          challengeApi.util.updateQueryData(
            'getJoinedChallengeUsers',
            {
              challenge_id: payload.challenge_id,
              official_status: payload.official_status,
              ...dependency
            },
            draft => {
              draft.data = draft.data.filter(user => user.user_id._id !== payload.user_id)
              draft.totalCount -= 1
            }
          )
        )
        try {
          await queryFulfilled
        } catch (err) {
          getJoinedUser.undo()
        }
      }
    }),

    getActivities: build.query<
      { data: ActivityType[]; totalCount: number },
      { official_status: string } & PaginationQueryParamsType
    >({
      query(params) {
        return `/list-activity${convertParamsToQueryString({ ...params })}`
      },
      providesTags: ['Activities'],
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        // return endpointName
        const newQueryArgs = { ...queryArgs }
        if (newQueryArgs.page) {
          delete newQueryArgs.page
        }
        return newQueryArgs
      },
      transformResponse: (response: ActivityType[], meta) => {
        return {
          data: response,
          totalCount: Number(meta.response.headers.get('X-Total-Count'))
        }
      },
      merge: (currentCacheData, newData, { arg, baseQueryMeta }) => {
        if (currentCacheData.data && arg.page !== 1) {
          return {
            ...currentCacheData,
            ...newData,
            data: [...currentCacheData.data, ...newData.data]
          }
        }
        return newData
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),

    updateStatusActivity: build.mutation<ActivityType, CreateActivityPayload>({
      query(payload: CreateActivityPayload) {
        return {
          url: '/update-activity',
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: ['Activities']
    })
  })
})

export const {
  useGetChallengeDetailQuery,
  useJoinChallengeMutation,
  useLeaveChallengeMutation,
  useAddUsersToChallengeMutation,
  useGetJoinedChallengeUsersQuery,
  useUpdatePermissionMutation,
  useGetActivitiesQuery,
  useUpdateStatusActivityMutation
} = challengeApi

export default challengeApi
