import { SvgIcon, SvgIconProps } from '@mui/material'

const CrownTopIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="99"
        height="126"
        viewBox="0 0 99 126"
        fill="none"
      >
        <path
          d="M67.4502 34.564C66.6165 34.7066 65.8224 35.0801 65.1584 35.6419C64.4944 36.2038 63.9873 36.9313 63.6949 37.7415L58.2125 52.7782L41.8079 49.3866C41.0356 49.2353 40.2222 49.2964 39.4505 49.5637C38.6788 49.831 37.9765 50.2949 37.4151 50.9083C36.8536 51.5217 36.453 52.2625 36.2542 53.0554C36.0554 53.8482 36.0654 54.6648 36.2832 55.4219L41.0859 71.4876L26.6004 78.2641C25.8189 78.6277 25.1382 79.1976 24.6353 79.9091C24.1324 80.6206 23.8274 81.4456 23.7546 82.2906C23.6819 83.1356 23.8443 83.9671 24.2236 84.6909C24.603 85.4148 25.184 86.0022 25.9011 86.3868L52.5803 100.791C53.0956 101.072 53.6699 101.239 54.2682 101.284C54.8665 101.328 55.4765 101.249 56.0611 101.05C56.8565 100.785 57.5814 100.313 58.159 99.6835C58.4389 99.3767 58.6774 99.0349 58.8677 98.6681C65.4419 86.3391 75.0911 75.7981 86.7872 68.1683C87.8437 67.4611 88.6084 66.3799 88.9135 65.1619C89.065 64.5617 89.0935 63.9453 88.997 63.3517C88.9005 62.7581 88.6812 62.2001 88.3529 61.7129L71.7168 36.337C71.27 35.6569 70.6337 35.1304 69.8798 34.8171C69.1258 34.5038 68.2843 34.4161 67.4502 34.564Z"
          fill="url(#paint0_linear_1001_19361)"
        />
        <ellipse
          cx="9.99955"
          cy="10.0092"
          rx="9.99955"
          ry="10.0092"
          transform="matrix(0.65582 -0.716292 0.715452 0.656287 18.5723 46.856)"
          fill="url(#paint1_linear_1001_19361)"
        />
        <ellipse
          cx="7.49967"
          cy="7.50689"
          rx="7.49967"
          ry="7.50689"
          transform="matrix(0.65582 -0.716292 0.715452 0.656287 46.8262 69.4277)"
          fill="#A51C30"
        />
        <ellipse
          cx="6.24972"
          cy="6.25574"
          rx="6.24972"
          ry="6.25574"
          transform="matrix(0.65582 -0.716292 0.715452 0.656287 59.2656 30.9385)"
          fill="url(#paint2_linear_1001_19361)"
        />
        <ellipse
          cx="6.24972"
          cy="6.25574"
          rx="6.24972"
          ry="6.25574"
          transform="matrix(0.65582 -0.716292 0.715452 0.656287 11.2246 83.4277)"
          fill="url(#paint3_linear_1001_19361)"
        />
        <ellipse
          cx="3.74983"
          cy="3.75344"
          rx="3.74983"
          ry="3.75344"
          transform="matrix(0.65582 -0.716292 0.715452 0.656287 42.209 85.2153)"
          fill="#DC3545"
        />
        <ellipse
          cx="3.74983"
          cy="3.75344"
          rx="3.74983"
          ry="3.75344"
          transform="matrix(0.65582 -0.716292 0.715452 0.656287 67.0586 58.0659)"
          fill="#DC3545"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1001_19361"
            x1="70.6898"
            y1="81.4414"
            x2="33.1796"
            y2="47.1044"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1001_19361"
            x1="9.99904"
            y1="18.7424"
            x2="9.99904"
            y2="-2.36256"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1001_19361"
            x1="6.2494"
            y1="11.714"
            x2="6.2494"
            y2="-1.4766"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1001_19361"
            x1="6.2494"
            y1="11.714"
            x2="6.2494"
            y2="-1.4766"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default CrownTopIcon
