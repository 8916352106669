import { Box, Stack, styled } from '@mui/material'

export const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1.5),
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // height: '90vh',
  maxHeight: '90vh',
  overflowY: 'auto',
  width: 'auto',
  maxWidth: theme.spacing(95.75),
  minWidth: theme.spacing(50),
  margin: '0 auto',

  '&:focus-visible': {
    outline: 0
  }
}))

export const ModalHeader = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  borderBottom: `1px solid ${ theme.palette.divider}`,
  padding: theme.spacing(1, 3),
  '& .title': {
    fontWeight: 700,
    width: '100%',
    marginRight: 'auto',
    textAlign: 'center'
  },
    '& .mobile': {
      textAlign: 'start'
    }
}))

export const ModalBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3)
}))

export const ModalFooter = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1.25, 3)
}))
