import { SvgIcon, SvgIconProps } from '@mui/material'

const GroupSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="33"
        viewBox="0 0 35 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 48095866">
          <path
            id="Vector"
            d="M17.6195 16.5016C22.1643 16.5016 25.8503 12.8156 25.8503 8.27084C25.8503 3.72604 22.1643 0.0400391 17.6195 0.0400391C13.0747 0.0400391 9.38867 3.72604 9.38867 8.27084C9.38867 12.8156 13.0747 16.5016 17.6195 16.5016Z"
            fill="url(#paint0_linear_1101_5223)"
          />
          <path
            id="Vector_2"
            d="M25.8275 8.91692C25.6831 10.7865 24.9155 12.4737 23.7299 13.7885C23.1295 14.4497 22.4227 15.0197 21.6399 15.4605L16.6467 10.4673L15.0659 8.88652C14.2907 8.19492 13.9031 7.18412 13.9031 6.06692C13.9031 3.96932 15.5067 2.36572 17.6043 2.36572C18.7215 2.36572 19.7323 2.75332 20.4239 3.52852L25.8199 8.92452L25.8275 8.91692Z"
            fill="url(#paint1_linear_1101_5223)"
          />
          <path
            id="Vector_3"
            d="M23.7303 13.7807C23.1299 14.4419 22.4231 15.0119 21.6403 15.4527C20.4547 16.1215 19.0791 16.5015 17.6199 16.5015C17.1335 16.5015 16.6547 16.4559 16.1911 16.3799L12.9687 13.1575C12.7179 13.0131 12.7331 12.6635 12.9459 12.4203C13.9111 11.3411 15.1727 10.6647 16.6395 10.4595C16.9511 10.4139 17.2703 10.3911 17.5971 10.3911C19.4135 10.3911 21.0475 11.0523 22.2103 12.2607L23.7303 13.7807Z"
            fill="url(#paint2_linear_1101_5223)"
          />
          <path
            id="Vector_4"
            d="M17.6047 9.85147C19.7023 9.85147 21.4047 8.14907 21.4047 6.05147C21.4047 3.95386 19.7023 2.25146 17.6047 2.25146C15.5071 2.25146 13.8047 3.95386 13.8047 6.05147C13.8047 8.14907 15.5071 9.85147 17.6047 9.85147Z"
            fill="url(#paint3_linear_1101_5223)"
          />
          <path
            id="Vector_5"
            d="M22.3846 12.4511C22.3846 12.4511 22.339 12.3979 22.3162 12.3751C21.1458 11.0983 19.4738 10.3003 17.6042 10.3003C15.7346 10.3003 14.0246 11.1135 12.8542 12.4131C12.5882 12.7019 12.7782 13.1731 13.1658 13.2111C14.3286 13.3251 15.9702 13.4011 17.7942 13.4011C19.4358 13.4011 20.9406 13.3403 22.073 13.2415C22.4682 13.2111 22.6582 12.7399 22.3922 12.4435L22.3846 12.4511Z"
            fill="url(#paint4_linear_1101_5223)"
          />
          <g id="Group">
            <path
              id="Vector_6"
              d="M8.75033 32.6442C13.2951 32.6442 16.9811 28.9582 16.9811 24.4134C16.9811 19.8686 13.2951 16.1826 8.75033 16.1826C4.20553 16.1826 0.519531 19.8686 0.519531 24.4134C0.519531 28.9582 4.20553 32.6442 8.75033 32.6442Z"
              fill="url(#paint5_linear_1101_5223)"
            />
            <path
              id="Vector_7"
              d="M16.9583 25.0595C16.8139 26.9291 16.0463 28.6163 14.8607 29.9311C14.2603 30.5923 13.5535 31.1623 12.7707 31.6031L7.77754 26.6099L6.19674 25.0291C5.42154 24.3375 5.03394 23.3267 5.03394 22.2095C5.03394 20.1119 6.63754 18.5083 8.73514 18.5083C9.85234 18.5083 10.8631 18.8959 11.5547 19.6711L16.9507 25.0671L16.9583 25.0595Z"
              fill="url(#paint6_linear_1101_5223)"
            />
            <path
              id="Vector_8"
              d="M14.8599 29.9311C14.2595 30.5923 13.5527 31.1623 12.7699 31.6031C11.5843 32.2719 10.2087 32.6519 8.74955 32.6519C8.26315 32.6519 7.78435 32.6063 7.32075 32.5303L4.09835 29.3079C3.84755 29.1635 3.86275 28.8139 4.07555 28.5707C5.04075 27.4915 6.30235 26.8151 7.76915 26.6099C8.08075 26.5643 8.39995 26.5415 8.72675 26.5415C10.5431 26.5415 12.1771 27.2027 13.3399 28.4111L14.8599 29.9311Z"
              fill="url(#paint7_linear_1101_5223)"
            />
            <path
              id="Vector_9"
              d="M8.73481 26.0019C10.8324 26.0019 12.5348 24.2995 12.5348 22.2019C12.5348 20.1043 10.8324 18.4019 8.73481 18.4019C6.63721 18.4019 4.93481 20.1043 4.93481 22.2019C4.93481 24.2995 6.63721 26.0019 8.73481 26.0019Z"
              fill="url(#paint8_linear_1101_5223)"
            />
            <path
              id="Vector_10"
              d="M13.5228 28.5937C13.5228 28.5937 13.4772 28.5405 13.4544 28.5177C12.284 27.2409 10.612 26.4429 8.7424 26.4429C6.8728 26.4429 5.1628 27.2561 3.9924 28.5557C3.7264 28.8445 3.9164 29.3157 4.304 29.3537C5.4668 29.4677 7.1084 29.5437 8.9324 29.5437C10.574 29.5437 12.0788 29.4829 13.2112 29.3841C13.6064 29.3537 13.7964 28.8825 13.5304 28.5861L13.5228 28.5937Z"
              fill="url(#paint9_linear_1101_5223)"
            />
            <path
              id="Vector_11"
              d="M26.4886 32.6442C31.0334 32.6442 34.7194 28.9582 34.7194 24.4134C34.7194 19.8686 31.0334 16.1826 26.4886 16.1826C21.9438 16.1826 18.2578 19.8686 18.2578 24.4134C18.2578 28.9582 21.9438 32.6442 26.4886 32.6442Z"
              fill="url(#paint10_linear_1101_5223)"
            />
            <path
              id="Vector_12"
              d="M34.6966 25.0595C34.5522 26.9291 33.7846 28.6163 32.599 29.9311C31.9986 30.5923 31.2918 31.1623 30.509 31.6031L25.5158 26.6099L23.935 25.0291C23.1598 24.3375 22.7722 23.3267 22.7722 22.2095C22.7722 20.1119 24.3758 18.5083 26.4734 18.5083C27.5906 18.5083 28.6014 18.8959 29.293 19.6711L34.689 25.0671L34.6966 25.0595Z"
              fill="url(#paint11_linear_1101_5223)"
            />
            <path
              id="Vector_13"
              d="M32.5994 29.9308C31.999 30.592 31.2922 31.162 30.5094 31.6028C29.3238 32.2716 27.9482 32.6516 26.489 32.6516C26.0026 32.6516 25.5239 32.606 25.0603 32.53L21.8378 29.3076C21.587 29.1632 21.6023 28.8136 21.8151 28.5704C22.7802 27.4988 24.0419 26.8224 25.5087 26.6172C25.8203 26.5716 26.1394 26.5488 26.4662 26.5488C28.2826 26.5488 29.9166 27.21 31.0794 28.4184L32.5994 29.9384V29.9308Z"
              fill="url(#paint12_linear_1101_5223)"
            />
            <path
              id="Vector_14"
              d="M26.4738 26.0019C28.5714 26.0019 30.2738 24.2995 30.2738 22.2019C30.2738 20.1043 28.5714 18.4019 26.4738 18.4019C24.3762 18.4019 22.6738 20.1043 22.6738 22.2019C22.6738 24.2995 24.3762 26.0019 26.4738 26.0019Z"
              fill="url(#paint13_linear_1101_5223)"
            />
            <path
              id="Vector_15"
              d="M31.2538 28.5937C31.2538 28.5937 31.2082 28.5405 31.1854 28.5177C30.015 27.2409 28.343 26.4429 26.4734 26.4429C24.6038 26.4429 22.8938 27.2561 21.7234 28.5557C21.4574 28.8445 21.6474 29.3157 22.035 29.3537C23.1978 29.4677 24.8394 29.5437 26.6634 29.5437C28.305 29.5437 29.8098 29.4829 30.9422 29.3841C31.3374 29.3537 31.5274 28.8825 31.2614 28.5861L31.2538 28.5937Z"
              fill="url(#paint14_linear_1101_5223)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1101_5223"
            x1="14.2603"
            y1="4.91164"
            x2="22.5595"
            y2="13.2108"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9D66D6" />
            <stop offset="1" stopColor="#453D81" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1101_5223"
            x1="22.5519"
            y1="11.0069"
            x2="16.2211"
            y2="4.66852"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7930C5" stopOpacity="0" />
            <stop offset="1" stopColor="#4600A0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1101_5223"
            x1="18.8815"
            y1="16.1899"
            x2="17.5211"
            y2="9.96551"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7930C5" stopOpacity="0" />
            <stop offset="1" stopColor="#4600A0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1101_5223"
            x1="15.6971"
            y1="6.05907"
            x2="19.0107"
            y2="6.05907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1101_5223"
            x1="15.6966"
            y1="11.8507"
            x2="19.0102"
            y2="11.8507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1101_5223"
            x1="5.39113"
            y1="21.0618"
            x2="13.6903"
            y2="29.3534"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1101_5223"
            x1="13.6827"
            y1="27.1495"
            x2="7.35193"
            y2="20.8187"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1101_5223"
            x1="10.0111"
            y1="32.3327"
            x2="8.65075"
            y2="26.1083"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1101_5223"
            x1="6.82721"
            y1="22.2019"
            x2="10.1408"
            y2="22.2019"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1101_5223"
            x1="6.8272"
            y1="28.0009"
            x2="10.1408"
            y2="28.0009"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1101_5223"
            x1="23.1294"
            y1="21.0618"
            x2="31.4286"
            y2="29.3534"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1101_5223"
            x1="32.257"
            y1="28.5935"
            x2="25.8958"
            y2="20.9783"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EF332E" stopOpacity="0" />
            <stop offset="1" stopColor="#BE1E2D" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1101_5223"
            x1="27.7506"
            y1="32.3324"
            x2="26.3902"
            y2="26.108"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EF332E" stopOpacity="0" />
            <stop offset="1" stopColor="#C40000" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1101_5223"
            x1="24.5662"
            y1="22.2019"
            x2="27.8798"
            y2="22.2019"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1101_5223"
            x1="24.5658"
            y1="28.0009"
            x2="27.8794"
            y2="28.0009"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default GroupSidebarIcon
