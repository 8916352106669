import { SvgIcon, SvgIconProps } from "@mui/material"

const StarIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <path id="Vector" d="M12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75Z" fill="url(#paint0_linear_3488_26190)" stroke="white" stroke-width="1.5" />
                <path id="Vector_2" d="M12 19.2852C16.0235 19.2852 19.2852 16.0235 19.2852 12C19.2852 7.97652 16.0235 4.71484 12 4.71484C7.97652 4.71484 4.71484 7.97652 4.71484 12C4.71484 16.0235 7.97652 19.2852 12 19.2852Z" fill="url(#paint1_linear_3488_26190)" />
                <path id="Vector_3" d="M12.9221 8.28906L13.7697 9.90625L15.5705 10.2109C16.3986 10.3516 16.7268 11.3633 16.1408 11.9648L14.8635 13.2695L15.1291 15.0742C15.2502 15.9062 14.3908 16.5312 13.6369 16.1602L12.0002 15.3516L10.3635 16.1602C9.60958 16.5312 8.7502 15.9062 8.8713 15.0742L9.13692 13.2695L7.85958 11.9648C7.27364 11.3633 7.60177 10.3516 8.42989 10.2109L10.2307 9.90625L11.0783 8.28906C11.469 7.54297 12.5315 7.54297 12.9221 8.28906Z" fill="url(#paint2_linear_3488_26190)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_3488_26190" x1="12" y1="22" x2="12" y2="2" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF9900" />
                    <stop offset="0.11" stop-color="#FFA200" />
                    <stop offset="0.37" stop-color="#FFB300" />
                    <stop offset="0.65" stop-color="#FFBD00" />
                    <stop offset="1" stop-color="#FFC100" />
                </linearGradient>
                <linearGradient id="paint1_linear_3488_26190" x1="12" y1="19.2855" x2="12" y2="4.71445" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFE187" />
                    <stop offset="1" stop-color="#FFF8E0" />
                </linearGradient>
                <linearGradient id="paint2_linear_3488_26190" x1="12.0001" y1="16.2699" x2="12.0001" y2="7.73008" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FD7600" />
                    <stop offset="0.71" stop-color="#FDBF30" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
)

export default StarIcon