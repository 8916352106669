//Library
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import {
  Box,
  Drawer,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Skeleton,
  Stack,
  styled,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import __, { ___ } from 'languages/index'

//Components
import AvatarComponent from './AvatarComponent'
import Capacity from 'components/Capacity'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'

//Icons
import { ArrowBarLeftIcon, CloseIcon, StarBadgeIcon, UserCircleOutlinedIcon } from 'icons'

//Helpers
import helpers from 'helpers'
import { useClickOutside } from 'hooks/useClickOutside'
import { logout } from '../../store/user.store.reducer'
import StarIcon from 'icons/StarIcon'
import { ChannelInformation } from 'entities/mission/data/enum'

const UserInformation = ({ widthCurrent }: { widthCurrent: number }) => {
  const wrapperRef = useRef(null)
  const dispatch = useAppDispatch()
  const level_data = useAppSelector(state => state.user.level_data)
  const user_data = useAppSelector(state => state.user.user_data)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const navigate = useNavigate()
  const [levelNext, setLevelNext] = useState(null)
  const [isShowModalInformation, setIsShowModalInformation] = useState<boolean>(false)
  const [isShowModalInformationMobile, setIsShowModalInformationMobile] = useState<boolean>(false)

  useClickOutside(wrapperRef, setIsShowModalInformation)

  // /**
  //  * Logic from layout/header.tsx
  //  */
  useEffect(() => {
    if (helpers.isEmpty(level_data)) return
    let dataNextLevelNew = level_data?.find(
      value => value?.level_number === Number(user_data?.channelpermission?.level_number) + 1
    )
    if (dataNextLevelNew) {
      setLevelNext(dataNextLevelNew)
    } else {
      setLevelNext(null)
    }
  }, [level_data, user_data?.channelpermission?.level_number])

  const onClickItem = (path: string) => {
    setIsShowModalInformation(false)
    if (path === 'logout') {
      dispatch(logout())
    } else {
      navigate(path)
    }
  }

  const buttonData = [
    {
      label: __('header_personal_information'),
      icon: <UserCircleOutlinedIcon />,
      _function: () => {
        onClickItem(`/user/detail/${user_data?._id}`)
      }
    },
    // {
    //   label: __('set_up_payment_information'),
    //   icon: <WalletIcon />,
    //   _function: () => {
    //     onClickItem(`/user/detail/${user_data?._id}`)
    //   }
    // },
    // {
    //   label: __('account_settings'),
    //   icon: <SettingIcon />,
    //   _function: () => {
    //     onClickItem(`/user/detail/${user_data?._id}`)
    //   }
    // },
    {
      label: __('header_logout'),
      icon: <ArrowBarLeftIcon />,
      _function: () => {
        onClickItem('logout')
      }
    }
  ]

  return (
    <UserInformationContainer ref={wrapperRef} id="userInformation">
      <IconButton
        className="button_information"
        onClick={() =>
          widthCurrent < 1024
            ? setIsShowModalInformationMobile(!isShowModalInformationMobile)
            : setIsShowModalInformation(!isShowModalInformation)
        }
      >
        <AvatarComponent
          userData={user_data}
          permissionData={permission_data}
          channelpermission={user_data?.channelpermission}
          width={36}
          height={36}
        />
      </IconButton>
      <Box className="info_mobile">
        <DrawerUserInfomation
          userData={user_data}
          permissionData={permission_data}
          toggle={isShowModalInformationMobile}
          setToggle={setIsShowModalInformationMobile}
          levelNext={levelNext}
          buttonData={buttonData}
        />
      </Box>
      {isShowModalInformation && (
        <Box className="modal_information">
          <Box className="modal_information_first">
            <AvatarComponent
              userData={user_data}
              permissionData={permission_data}
              channelpermission={user_data?.channelpermission}
              width={72}
              height={72}
            />
            <Stack flex={1} gap={0.5}>
              <Typography
                className="user_display_name"
                textTransform={'none'}
                variant="subtitle2"
                fontWeight={600}
              >
                {user_data?.display_name || ''}
              </Typography>
              <Capacity
                current_user_can={'boss'}
                onFail={() => (
                  <Stack gap={0.5}>
                    <Box className="userInformation_user">
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: theme => `${theme.spacing(1.875)} 1fr`,
                          alignItems: 'center',
                          minWidth: theme => theme.spacing(12),
                          position: 'relative'
                        }}
                      >
                        <div></div>
                        <Box className="userInformation_icon_star_badge">
                          <StarBadgeIcon />
                        </Box>
                        <Box className="userInformation_user_line">
                          <BorderLinearProgress
                            variant="determinate"
                            value={
                              levelNext
                                ? (user_data?.channelpermission?.point / levelNext?.level_point) *
                                100 || 0
                                : 100
                            }
                          />
                          <Typography
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              whiteSpace: 'nowrap'
                            }}
                            color={'warning.dark'}
                            fontWeight={700}
                            variant="body2"
                          >
                            {levelNext
                              ? `${helpers.convertToShortNumber(user_data?.channelpermission?.point || 0)}/${helpers.convertToShortNumber(
                                levelNext?.level_point || 0
                              )}`
                              : `${helpers.convertToShortNumber(user_data?.channelpermission?.point || 0)}/${helpers.convertToShortNumber(
                                user_data?.channelpermission?.point || 0
                              )}`}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          minWidth: theme => theme.spacing(6.25),
                          gridTemplateColumns: theme => `${theme.spacing(3)} 1fr`,
                          alignItems: 'center',
                          position: 'relative'
                        }}
                      >
                        <div></div>
                        <Box className="userInformation_icon_diamond">
                          <Box
                            component={'img'}
                            marginBottom={1}
                            src={ChannelInformation.icon}
                            width={40}
                            height={40}
                            alt="icon/diamond"
                            loading="lazy"
                          />
                        </Box>
                        <Box className="userInformation_icon_diamond_number">
                          <Typography color={'primary.dark'} variant="body2" fontWeight={700}>
                            {helpers.convertToShortNumber(
                              user_data?.channelpermission?.coin_number || 0
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Typography variant="body1" color={'text.secondary'} fontWeight={400}>
                      {___('header {level_point} point', {
                        level_point: (
                          <>{`${user_data?.channelpermission?.level_number || '1'} - ${user_data?.channelpermission?.point || '0'}`}</>
                        )
                      })}
                    </Typography>
                  </Stack>
                )}
              >
                <Typography
                  color={'primary.dark'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                  variant="body2"
                >
                  {__('header_name_sub_text')}
                </Typography>
              </Capacity>
            </Stack>
          </Box>
          <Box className="modal_information_second">
            {buttonData?.map((data, idx) => (
              <Box key={idx} onClick={data?._function} className="information_second_item">
                {data?.icon}
                <Typography variant="body1" color="text.primary" fontWeight={600}>
                  {data?.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </UserInformationContainer>
  )
}

interface TypedDrawerUserInfomation {
  toggle: boolean
  setToggle: Dispatch<SetStateAction<boolean>>
  userData?: any
  permissionData?: string[]
  levelNext: any
  buttonData: any
}

export const DrawerUserInfomation = ({
  toggle,
  setToggle,
  userData,
  permissionData,
  levelNext,
  buttonData
}: TypedDrawerUserInfomation) => {
  return (
    <>
      <DrawerUserInfomationContainer
        PaperProps={{
          sx: {
            width: '100%',
            borderRadius: '0'
          }
        }}
        anchor="right"
        open={toggle}
        onClose={() => setToggle(false)}
      >
        <Box width="100%" height="100%" px="16px" pt="24px" className="content">
          <Box className="user_infomation">
            <IconButton className="icon_close" onClick={() => setToggle(false)}>
              <CloseIcon />
            </IconButton>
            <Box pt="32px" className="infomation">
              <Stack alignItems="center" mx="auto">
                <AvatarComponent
                  userData={userData}
                  permissionData={permissionData}
                  channelpermission={userData?.channelpermission}
                  width={101}
                  height={101}
                  isInfomationMobile={true}
                />
                <Typography
                  my="14px"
                  className="user_display_name"
                  fontSize="18px"
                  variant="subtitle2"
                  fontWeight={600}
                >
                  {userData?.display_name || ''}
                </Typography>

                <Capacity
                  current_user_can={'boss'}
                  onFail={() => (
                    <Box className="userInformation_user">
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: theme => `${theme.spacing(1.875)} 1fr`,
                          alignItems: 'center',
                          minWidth: theme => theme.spacing(12),
                          position: 'relative'
                        }}
                      >
                        <div></div>
                        <Box className="userInformation_icon_star_badge">
                          <StarIcon />
                        </Box>
                        <Box className="userInformation_user_line">
                          <BorderLinearProgress
                            variant="determinate"
                            value={
                              levelNext?.level_point
                                ? (userData?.channelpermission?.point / levelNext?.level_point) *
                                100 || 0
                                : 100
                            }
                          />
                          <Typography
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              whiteSpace: 'nowrap'
                            }}
                            color={'warning.dark'}
                            fontWeight={700}
                            variant="body2"
                          >
                            {levelNext?.level_point
                              ? `${helpers.convertToShortNumber(userData?.channelpermission?.point || 0)}/${helpers.convertToShortNumber(
                                levelNext?.level_point || 0
                              )}`
                              : helpers.convertToShortNumber(
                                userData?.channelpermission?.point || 0
                              )}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          minWidth: theme => theme.spacing(6.25),
                          gridTemplateColumns: theme => `${theme.spacing(3)} 1fr`,
                          alignItems: 'center',
                          position: 'relative'
                        }}
                      >
                        <div></div>
                        <Box className="userInformation_icon_diamond">
                          <Box
                            component={'img'}
                            marginBottom={1}
                            src={ChannelInformation.icon}
                            width={40}
                            height={40}
                            alt="icon/diamond"
                            loading="lazy"
                          />
                        </Box>
                        <Box className="userInformation_icon_diamond_number">
                          <Typography color={'primary.dark'} variant="body2" fontWeight={700}>
                            {helpers.convertToShortNumber(
                              userData?.channelpermission?.coin_number || 0
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                >
                  <Typography color={'primary.dark'} variant="body2">
                    {__('header_name_sub_text')}
                  </Typography>
                </Capacity>
              </Stack>
            </Box>
          </Box>
          <Box className="menu" pt="24px">
            {buttonData?.map((data, idx) => (
              <Box key={idx} onClick={data?._function} className="information_second_item">
                {data?.icon}
                <Typography variant="body1" color="text.primary" fontWeight={600}>
                  {data?.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </DrawerUserInfomationContainer>
    </>
  )
}

const DrawerUserInfomationContainer = styled(Drawer)(({ theme }) => ({
  '& .content': {
    '& .user_infomation': {
      '& .icon_close': {
        position: 'absolute',
        right: 0
      },
      '& .infomation': {
        '& .userInformation_icon_star_badge': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          height: theme.spacing(2.5),
          width: theme.spacing(2.5),
          left: '0',
          background: theme.palette.background.paper,
          position: 'absolute',
          zIndex: '1'
        },
        '& .userInformation_icon_diamond': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: theme.spacing(2.5),
          width: theme.spacing(2.5),
          left: theme.spacing(1.25),
          position: 'absolute',
          zIndex: '1'
        },

        '& .userInformation_user': {
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignContent: 'center',
          width: '80%'
        },
        '& .userInformation_user_line': {
          textAlign: 'center',
          height: '80%',
          background: theme.palette.background.default,
          borderRadius: theme.spacing(0, 2, 2, 0)
        },
        '& .userInformation_icon_diamond_number': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'auto',
          textAlign: 'center',
          height: '80%',
          minWidth: theme.spacing(5.125),
          background: theme.palette.background.default,
          borderRadius: theme.spacing(0, 2, 2, 0)
        }
      }
    },
    '& .menu': {
      '& .information_second_item': {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        columnGap: theme.spacing(2),
        alignItems: 'center',
        padding: theme.spacing(2),
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
          background: theme.palette.action.hover,
          borderRadius: theme.spacing(1.5)
        },
        ':last-child': {
          color: theme.palette.text.secondary
        }
      }
    }
  }
}))

export const UserInformationSkeleton = () => {
  return (
    <Box className="information">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'center',
          columnGap: theme => theme.spacing(2)
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            position: 'relative',
            width: theme => theme.spacing(5),
            height: theme => theme.spacing(5)
          }}
        />

        <Skeleton
          variant="rounded"
          sx={{
            position: 'relative',
            width: theme => theme.spacing(5),
            height: theme => theme.spacing(5)
          }}
        />
      </Box>
      <UserInformationContainer id="userInformation">
        <Box className="button_information">
          <Skeleton
            variant="circular"
            sx={{
              position: 'relative',
              width: theme => theme.spacing(5.5),
              height: theme => theme.spacing(5.5)
            }}
          />
        </Box>
      </UserInformationContainer>
    </Box>
  )
}

export default UserInformation

const UserInformationContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  width: theme.spacing(4.5),
  '& .button_information': {
    display: 'grid',
    padding: '0',
    width: '100%',
    columnGap: theme.spacing(1),
    justifyContent: 'flex-start',
    textTransform: 'none'
  },
  '& .userInformation_icon_star_badge': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    left: '0',
    background: theme.palette.background.paper,
    position: 'absolute',
    zIndex: '1'
  },
  '& .userInformation_icon_diamond': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    left: theme.spacing(1.25),
    position: 'absolute',
    zIndex: '1'
  },

  '& .userInformation_user': {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignContent: 'center',
    width: '100%'
  },
  '& .userInformation_user_line': {
    textAlign: 'center',
    height: '80%',
    background: theme.palette.background.default,
    borderRadius: theme.spacing(0, 2, 2, 0)
  },
  '& .userInformation_icon_diamond_number': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    textAlign: 'center',
    height: '80%',
    minWidth: theme.spacing(5.125),
    background: theme.palette.background.default,
    borderRadius: theme.spacing(0, 2, 2, 0)
  },
  '& .modal_information': {
    position: 'absolute',
    width: theme.spacing(47.625),
    height: 'auto',
    overflow: 'auto',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(5)} 0 rgba(14, 20, 38, 0.08)`,
    display: 'grid',
    rowGap: theme.spacing(3),
    right: 0,
    top: theme.spacing(10),
    zIndex: 1,
    cursor: 'default',
    '& .modal_information_first': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: theme.spacing(2),
      padding: theme.spacing(0, 2),
      '& .user_avatar': {
        width: theme.spacing(9),
        height: theme.spacing(9),
        borderRadius: '50%',
        objectFit: 'cover'
      },
      '& .user_display_name': {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden'
      }
    },
    '& .modal_information_second': {
      '& .information_second_item': {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        columnGap: theme.spacing(2),
        padding: theme.spacing(1.5, 2),
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
          background: theme.palette.action.hover,
          borderRadius: theme.spacing(1.5)
        },
        ':last-child': {
          color: theme.palette.text.secondary
        }
      }
    }
  },
  '& .info_mobile': {
    display: 'none'
  },

  '@media(max-width: 1024px)': {
    '& .info_mobile': {
      display: 'block'
    },

    '& .modal_information': {
      display: 'none'
    },

    '& .box_avt': {
      display: 'none'
    }
  }
}))

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '100%',
  borderRadius: `0 ${theme.spacing(12.5)} ${theme.spacing(12.5)} 0`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.background.default
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: `0 ${theme.spacing(12.5)} ${theme.spacing(12.5)} 0`,
    background: 'linear-gradient(270deg, #FFC53D 1.43%, rgba(255, 197, 61, 0.30) 98.69%)'
  }
}))
