import { Components } from '@mui/material'
import { CurrentTheme } from 'styles/themes'

export const defaultSelect: Components<CurrentTheme>['MuiSelect'] = {
  defaultProps: {
    variant: 'outlined',
    fullWidth: true,
    size: 'small'
  },

  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.primary,
      borderRadius: theme.spacing(1.5)
    })
  }
}
