//Library
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {AxiosError, AxiosResponse} from 'axios'
import {notEmpty, useField, useForm} from '@shopify/react-form'
import { Form} from 'react-bootstrap'
import {faEarthAmericas, faLock} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//Components
import TextField from 'components/TextField'

//Image
import imageDefault from 'media/images/image_default.png'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getDataChannel, updateChannel, uploadImage} from '../store/channelSetting.store.reduce.ts'
import {getDefaultChannel,setChannelData} from 'store/user.store.reducer'

//Interface

//Scss
import '../media/channel.setting.overview.scss'
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import __ from "languages/index";
import TypedChannel from "../../../interfaces/channel.interface";
import { Box, Button, styled } from '@mui/material'

const ChannelSettingOverview = () => {
  const dispatch = useAppDispatch()
  const desRef = useRef(null)
  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataChannel, setDataChannel] = useState<TypedChannel>()
  const toast = useToast()

  const { fields, dirty, submitting, submit } = useForm({
    fields: {
      avatar: useField<string>({
        value: '',
        validates: []
      }),
      group_name: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("channel_setting_overview_note")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("channel_setting_overview_note")}`
            }
          }
        ]
      }),
      description: useField<string>({
        value: '',
        validates: []
      }),
      need_approval: useField<boolean>({
        value: false,
        validates: []
      }),
      public_status: useField<string>({
        value: '',
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          _id: channel_data?._id,
          name: values.group_name.trim(),
          description: values.description.trim(),
          public_status: values.public_status,
          need_approval: `${values.need_approval}`
        }
        setIsLoading(true)
        dispatch(updateChannel({ ...formData }))
          .unwrap()
          .then((res: AxiosResponse<TypedChannel>) => {
            setDataChannel(res?.data)
            dispatch(setChannelData(res?.data))
            dispatch(getDefaultChannel());
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_info_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateDataUser_${error}`)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_info_failed")}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]
      if (!file) return
      let formData = new FormData()
      formData.append('file[]', file)
      setIsLoadingUpload(true)
      dispatch(uploadImage(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          dispatch(
            updateChannel({ _id: channel_data?._id, avatar: res?.data[0]?.callback?._id })
          )
            .unwrap()
            .then((res: AxiosResponse<TypedChannel>) => {
              console.log(res,"resresres")
              setIsLoadingUpload(false)
              fields.avatar.onChange(res?.data?.avatar?.media_url)
              dispatch(setChannelData(res?.data))
              localStorage.setItem('channel', JSON.stringify(res?.data))
              toast.show({
                content: `${__("channel_setting_overview_update_logo_success")}`,
                type: EnumTypeToast.Success
              })
            })
            .catch((error: AxiosError) => {
              console.log(`updateDataUser_${error}`)
              setIsLoadingUpload(false)
              toast.show({
                content: `${__("channel_setting_overview_update_logo_failed")}`,
                type: EnumTypeToast.Error
              })
            })
        })
        .catch((error: AxiosError) => {
          console.log(`uploadImage_${error}`)
          toast.show({
            content: `${__("channel_setting_overview_update_logo_failed")}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [channel_data, fields]
  )


  const handleSetHeightTextArea = useCallback(
    (ref: React.RefObject<HTMLTextAreaElement>) => {
      const textarea = ref?.current
      if (textarea) {
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    },
    [dataChannel, fields]
  )

  const handleChangeStatus = useCallback(
    (value: string) => {
      fields.public_status.onChange(value)
    },
    [fields]
  )

  const isPublic = useMemo(() => {
    return fields.public_status.value === 'public'
  }, [fields])

  useEffect(() => {
    if(channel_data?._id){
      dispatch(getDataChannel(channel_data?._id))
        .unwrap()
        .then((res: AxiosResponse<TypedChannel>) => {
          setDataChannel(res?.data)
        })
        .catch((error: AxiosError) => {
          console.log(`getDataChannel_${error}`)
        })
    }
  }, [channel_data?._id])

  useEffect(() => {
    fields.avatar.onChange(dataChannel?.avatar?.media_url)
    fields.group_name.onChange(dataChannel?.name)
    fields.description.onChange(dataChannel?.description)
    fields.need_approval.onChange(dataChannel?.need_approval)
    fields.public_status.onChange(dataChannel?.public_status)
  }, [dataChannel])

  return (
    <ChannelSettingOverviewContainer id="channelSettingOverview" className="channelSettingOverview_container">
      <h4 className="channelSettingOverview_header_title">{__("channel_setting_overview")}</h4>
      <div className="channelSettingOverview_avatar_wrapper">
        <img
          src={fields.avatar.value || imageDefault}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = imageDefault
          }}
          className="channelSettingOverview_avatar_img"
          alt="avatar"
        />
        <div>
          <input
            type="file"
            onChange={handleImageUpload}
            id="logoChannel"
            style={{ display: 'none' }}
            accept="image/*"
          />
          <Button component={'label'}
            htmlFor="logoChannel"
            sx={{textTransform: 'none'}}
            className="channelSettingOverview_avatar_button"
          >
            {isLoadingUpload ? `${__("btn_processing")}` : `${__("channel_setting_overview_change_logo")}`}
          </Button>
        </div>
      </div>
      <Form onSubmit={submit} className="channelSettingOverview_body_wrapper">
        <div className="channelSettingOverview_group_name">
          <label className="channelSettingOverview_label">{__("channel_setting_overview_name")}</label>
          <TextField {...fields.group_name} maxLength={50} autoFocus />
        </div>
        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_overview_description")}</label>
          <textarea
            value={fields.description.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.description.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_overview_description")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>
        <div className="channelSettingOverview_approval">
          <div className="channelSettingOverview_approval_left">
            <span className="channelSettingOverview_approval_label">{__("channel_setting_overview_approval_post")}</span>
            <span className="channelSettingOverview_approval_left_content">
              {__("channel_setting_overview_switch_approval_post")}
            </span>
          </div>
          <div className="form-switch">
            <Form.Check
              name="notification_chat"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                fields.need_approval.onChange(e.target.checked)
              }
              checked={fields.need_approval.value || false}
              readOnly
              className="channelSettingOverview_approval_right"
            />
          </div>
        </div>
        <div className="channelSettingOverview_status_wrapper">
          <div
            role="button"
            className={`channelSettingOverview_status_body ${isPublic && 'active_status'}`}
            onClick={() => handleChangeStatus('public')}
          >
            <div className="channelSettingOverview_form_check form-check">
              <Form.Check
                checked={isPublic}
                readOnly
                className="channelSettingOverview_check"
                type="radio"
              />
              <div className="channelSettingOverview_form_check_right">
                <FontAwesomeIcon icon={faEarthAmericas} />
                <span className="channelSettingOverview_form_check_right_title">{__("channel_setting_overview_public")}</span>
              </div>
            </div>
            <span className="channelSettingOverview_status_content">
              {__("channel_setting_overview_public_sub_text")}
            </span>
          </div>
          <div
            role="button"
            className={`channelSettingOverview_status_body ${!isPublic && 'active_status'}`}
            onClick={() => handleChangeStatus('private')}
          >
            <div className="channelSettingOverview_form_check form-check">
              <Form.Check
                checked={!isPublic}
                readOnly
                className="channelSettingOverview_check"
                type="radio"
              />
              <div className="channelSettingOverview_form_check_right">
                <FontAwesomeIcon icon={faLock} />
                <span className="channelSettingOverview_form_check_right_title">{__("channel_setting_overview_private")}</span>
              </div>
            </div>
            <span className="channelSettingOverview_status_content">
              {__("channel_setting_overview_private_sub_text")}
            </span>
          </div>
        </div>
        <div className="channelSettingOverview_button_update">
          <Button
            sx={{textTransform: 'none'}}
            disabled={!dirty || submitting || isLoading}
            onClick={submit}
          >
            {submitting || isLoading ?`${__("btn_processing")}` : `${__("btn_update")}`}
          </Button>
        </div>
      </Form>
    </ChannelSettingOverviewContainer>
  )
}

export default ChannelSettingOverview

const ChannelSettingOverviewContainer = styled(Box)(({theme})=>({
  '& .form-check-input':{
    ':checked':{
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`
    },
    ':focus':{
      boxShadow: 'unset !important'
    }
  }
}))