//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Interface
import { TypeDataAbout } from '../interface'

//Helpers
import helpers from 'helpers'

interface TypeUpdateChannel {
  _id?: string
  description?: string
  attach_files?: string
}

const initialState = {}
export const getDataAbout = createAsyncThunk(
  'about/get_data',
  async (_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<TypeDataAbout>(`/channel/detail/${_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    serializeError: serializeAxiosError
  }
)

export const updateChannel = createAsyncThunk(
  'about/update_desc',
  async (body: TypeUpdateChannel, { rejectWithValue }) => {
    try {
      const response = await axios.patch<TypeDataAbout>(
        `/channel/update`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    serializeError: serializeAxiosError
  }
)

export const uploadImage = createAsyncThunk(
  'detail_user/upload_image',
  async (body: unknown, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(
        `${process.env.REACT_APP_MEDIA_UPLOAD_URL}`,
        body,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const ABOUT_REDUCER = createEntitySlice({
  name: 'about',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = ABOUT_REDUCER.actions
export default ABOUT_REDUCER.reducer
