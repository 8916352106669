import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  ReferredListResponse,
  ReferredUser,
  ReferrerType
} from 'entities/ReferrerTree/referrer.types'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL + '/referrer',
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    headers.set('X-Channel', localStorage.getItem('default_channel_id'))
    return headers
  },
  credentials: 'include'
})

const referrerApi = createApi({
  baseQuery,
  reducerPath: 'referrerApi',
  tagTypes: ['Referrer'],
  refetchOnMountOrArgChange: 30,
  endpoints(build) {
    return {
      getReferrerList: build.query<ReferrerType[], { channelId: string }>({
        query({ channelId }) {
          return {
            url: `/admin/${channelId}`
          }
        }
      }),
      getReferredDataById: build.query<
        { user: ReferredUser; referredList: ReferredUser[] },
        { userId: string }
      >({
        query({ userId }) {
          return {
            url: `/${userId}`
          }
        },

        transformResponse: (res: ReferredListResponse) => {
          return {
            user: res.you,
            referredList: res.yours
          }
        },

        providesTags: result =>
          result
            ? [
                ...result?.referredList?.map(({ id }) => ({ type: 'Referrer' as const, id })),
                { type: 'Referrer', id: 'LIST' }
              ]
            : [{ type: 'Referrer', id: 'LIST' }]
      })
    }
  }
})

export const { useGetReferredDataByIdQuery, useGetReferrerListQuery } = referrerApi
export default referrerApi
