import React, { Dispatch, SetStateAction } from 'react'
import { Box, Divider, List, ListItem, styled, Typography } from '@mui/material'
import SidebarItem from './Sidebar/SidebarItem'
import {
  CalendarIcon,
  ChallengeSidebarIcon,
  CourseSidebarIcon,
  EventIcon,
  GiftSidebarIcon,
  GroupSidebarIcon,
  MissionSidebarICon,
  NewsIcon,
  OrderSidebarIcon,
  RankingSidebarIcon,
  RevenueIcon
} from 'icons'
import __ from 'languages/index'
import { useAppSelector } from 'config/store'
import { getChannelServices } from '../store/user.store.reducer'
import { mergeArrayObjectsByKey } from 'helpers/mergeArrayObjectsByKey'
import helpers from 'helpers'
import { Link } from 'react-router-dom'
import ShopSidebarIcon from '../icons/ShopSidebarIcon'

type SidebarItemType = {
  key: string
  label: string
  icon: React.ReactNode
  path: string
  subMenu?: Pick<SidebarItemType, 'label' | 'path'>[]
}

const freeChannelConfigsAdmin: SidebarItemType[] = [
  {
    key: 'shop',
    label: __('shop'),
    icon: <ShopSidebarIcon />,
    path: '/shop'
  },
  {
    key: 'news',
    label: __('news'),
    icon: <NewsIcon />,
    path: '/news'
  },
  {
    key: 'order',
    label: __('order'),
    icon: <OrderSidebarIcon />,
    path: '/orders',
    subMenu: [
      {
        label: __('plan_register'),
        path: '/orders/my-subscription'
      },
      {
        label: __('my_order'),
        path: '/orders'
      },
      {
        label: __('channel_orders'),
        path: '/orders/orders-channel'
      }
    ]
  },
  {
    key: 'event',
    label: __('event_title'),
    icon: <EventIcon />,
    path: '/event'
  },
  {
    key: 'calendar',
    label: __('calendar'),
    icon: <CalendarIcon />,
    path: '/calendar'
  },
  {
    key: 'courses',
    label: __('courses'),
    icon: <CourseSidebarIcon />,
    path: '/courses',
    subMenu: [
      {
        label: __('total_course'),
        path: '/courses'
      },
      {
        label: __('course_revenue'),
        path: '/courses/income'
      }
    ]
  },
  {
    key: 'members',
    label: __('member'),
    icon: <GroupSidebarIcon />,
    path: '/member'
  },

  {
    key: 'ranking',
    label: __('challenge_rank'),
    icon: <RankingSidebarIcon />,
    path: '/leader-board'
  },

  {
    key: 'mission',
    label: __('mission'),
    icon: <MissionSidebarICon />,
    path: '/setting-mission'
  },
  {
    key: 'report',
    label: __('report'),
    icon: <RevenueIcon />,
    path: '/revenue'
  }
  // {
  //   key: 'extension',
  //   label: __('extension_breadcrumb'),
  //   icon: <ExtensionSidebarIcon />,
  //   path: '/extensions'
  // }
  // {
  //   label: __('course_income_table_affiliate'),
  //   icon: <AffiliateSidebarIcon />,
  //   path: '/temp/affiliate'
  // }
  // {
  //   label: __('podcast'),
  //   icon: <PodcastSidebarIcon />,
  //   path: '/podcast'
  // }

  // {
  //   key: 'shop',
  //   label: __('shop'),
  //   icon: <ShopSidebarIcon />,
  //   path: '/shop',
  //   subMenu: [
  //     {
  //       label: __('shop_list'),
  //       path: '/shop'
  //     },
  //     {
  //       label: __('shop_manage_product'),
  //       path: '/shop/manage-product'
  //     },
  //     {
  //       label: __('shop_manage_order'),
  //       path: '/shop/manage-order'
  //     },
  //     {
  //       label: __('shop_earning'),
  //       path: '/shop/revenue'
  //     },
  //     {
  //       label: __('shop_admin'),
  //       path: '/shop/admin'
  //     }
  //   ]
  // }
]

const freeChannelConfigsUser: SidebarItemType[] = [
  {
    key: 'shop',
    label: __('shop'),
    icon: <ShopSidebarIcon />,
    path: '/shop'
  },
  {
    key: 'news',
    label: __('news'),
    icon: <NewsIcon />,
    path: '/news'
  },
  {
    key: 'order',
    label: __('order'),
    icon: <OrderSidebarIcon />,
    path: '/orders'
  },
  {
    key: 'event',
    label: __('event_title'),
    icon: <EventIcon />,
    path: '/event'
  },
  {
    key: 'calendar',
    label: __('calendar'),
    icon: <CalendarIcon />,
    path: '/calendar'
  },
  {
    key: 'courses',
    label: __('courses'),
    icon: <CourseSidebarIcon />,
    path: '/courses',
    subMenu: [
      {
        label: __('total_course'),
        path: '/courses'
      },
      {
        label: __('course_joined'),
        path: '/courses/course-joined'
      }
    ]
  },
  {
    key: 'members',
    label: __('member'),
    icon: <GroupSidebarIcon />,
    path: '/member'
  },

  {
    key: 'ranking',
    label: __('challenge_rank'),
    icon: <RankingSidebarIcon />,
    path: '/leader-board'
  },

  {
    key: 'mission',
    label: __('mission'),
    icon: <MissionSidebarICon />,
    path: '/missions'
  },
  {
    key: 'report',
    label: __('report'),
    icon: <RevenueIcon />,
    path: '/revenue'
  }
  // {
  //   label: __('course_income_table_affiliate'),
  //   icon: <AffiliateSidebarIcon />,
  //   path: '/temp/affiliate'
  // }
  // {
  //   label: __('podcast'),
  //   icon: <PodcastSidebarIcon />,
  //   path: '/podcast'
  // }
]

const premiumChannelConfigs: Pick<SidebarItemType, 'key' | 'icon'>[] = [
  // {
  //   key: 'livestream',
  //   icon: <LivestreamSidebarIcon />
  // },
  // {
  //   key: 'mentor',
  //   icon: <MentorSidebarIcon />
  // },
  {
    key: 'gift',
    icon: <GiftSidebarIcon />
  },
  {
    key: 'challenge',
    icon: <ChallengeSidebarIcon />
  }
]

interface TypedProps {
  setToggle?: Dispatch<SetStateAction<boolean>>
}

const Sidebar = ({ setToggle }: TypedProps) => {
  const services = useAppSelector(getChannelServices)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const serviceConfigs = services
    ?.reduce((res, item) => {
      if (item.handle === 'store') {
        if (helpers.current_user_can('boss', permission_data)) {
          res.push(item)
        } else {
          res.push({
            handle: 'store',
            title: __('shop'),
            icon: <ShopSidebarIcon />,
            router_link: '/shop',
            sub_menu: []
          })
        }
      } else {
        res.push(item)
      }

      return res
    }, [])
    ?.map(service => ({
      key: service.handle,
      subMenu:
        service?.sub_menu.length > 0
          ? service.sub_menu?.map(sub => ({
              label: sub.title_side_bar,
              path: sub.router_link
            }))
          : undefined,
      path: service.router_link,
      label: service.title
    }))
    .filter(item => !['livestream', 'mentor'].includes(item.key))

  return (
    <SidebarContainer>
      <List
        sx={{
          display: 'grid',
          rowGap: theme => theme.spacing(0.5)
        }}
      >
        {(helpers.current_user_can('boss', permission_data)
          ? freeChannelConfigsAdmin
          : freeChannelConfigsUser
        ).map((config, index) => (
          <SidebarItem setToggle={setToggle} key={index} {...config} channel_data={channel_data} />
        ))}
      </List>
      {(serviceConfigs || []).length > 0 && (
        <>
          <Divider
            sx={{
              borderColor: theme => theme.palette.text.disabled
            }}
          />
          <List
            sx={{
              display: 'grid',
              rowGap: theme => theme.spacing(0.5)
            }}
          >
            <Typography color="text.secondary" fontWeight={600} noWrap>
              {__('sidebar_advance')}
            </Typography>
            {serviceConfigs &&
              mergeArrayObjectsByKey(premiumChannelConfigs, serviceConfigs, 'key')
                .filter(item => !!item.label)
                .map((config, index) => (
                  <SidebarItem
                    setToggle={setToggle}
                    key={index}
                    {...(config as SidebarItemType)}
                    channel_data={channel_data}
                  />
                ))}
          </List>
        </>
      )}

      <Divider
        sx={{
          borderColor: theme => theme.palette.text.disabled
        }}
      />
      <Box padding={theme => theme.spacing(1, 0)}>
        {helpers.isGamifa() && (
          <List className="list">
            <ListItem className="item">
              <Link to={'/about-us'} className="link">
                {__('about_us_title')}
              </Link>
            </ListItem>
            <ListItem sx={{ padding: theme => theme.spacing(0, 1), width: 'auto' }}>
              <Box className="doted"></Box>
            </ListItem>
            <ListItem className="item">
              <Link to={'/contact'} className="link">
                {__('contact_title')}
              </Link>
            </ListItem>
            <ListItem sx={{ padding: theme => theme.spacing(0, 1), width: 'auto' }}>
              <Box className="doted"></Box>
            </ListItem>
            <ListItem className="item">
              <Link to={'/tos'} className="link">
                {__('regulations')}
              </Link>
            </ListItem>
          </List>
        )}
        <Typography variant="body2" color={'text.secondary'}>
          {channel_data?.name || 'ICEO'} {__('contact_version')} {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </SidebarContainer>
  )
}

export default Sidebar

const SidebarContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  height: `calc(100vh - ${theme.spacing(14.5)})`,
  top: theme.spacing(12.5),
  width: '100%',
  overflow: 'auto',
  '& .list': {
    display: 'flex',
    flexWrap: 'wrap',
    '& .item': {
      padding: 0,
      width: 'auto'
    }
  },
  '& .link': {
    textDecoration: 'none',
    fontSize: theme.spacing(1.75),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap'
  },
  '& .doted': {
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    borderRadius: '50%',
    background: theme.palette.text.disabled
  }
}))
