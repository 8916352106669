import { SvgIcon, SvgIconProps } from '@mui/material'

const LikeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_2198_52015)">
          <path
            d="M16.1393 12.4594H10.0464C9.89194 12.4594 9.74171 12.4087 9.61889 12.315C9.49607 12.2213 9.40746 12.0898 9.36673 11.9407C9.32599 11.7917 9.33538 11.6334 9.39344 11.4903C9.45151 11.3471 9.55503 11.227 9.68807 11.1484C12.3427 9.57981 13.7217 7.75 13.9035 5.55447C13.967 4.79252 14.6456 4.18389 15.416 4.19397C16.2021 4.20653 16.9386 4.52163 17.4901 5.08131C18.0421 5.64152 18.3464 6.38336 18.3466 7.17011V7.40706C18.3466 9.32894 17.7774 10.5543 16.7271 12.1433C16.6628 12.2405 16.5756 12.3202 16.473 12.3754C16.3704 12.4305 16.2558 12.4594 16.1393 12.4594Z"
            fill="url(#paint0_linear_2198_52015)"
          />
          <path
            d="M23.6062 13.258C23.6062 12.041 22.616 11.0508 21.3989 11.0508H10.0462C9.92009 11.0508 9.79625 11.0846 9.68773 11.1488C8.89081 11.62 8.08616 12.0079 7.43961 12.3197C7.21409 12.4284 7.00109 12.5311 6.80736 12.6279C6.69035 12.6865 6.59195 12.7764 6.52317 12.8877C6.4544 12.999 6.41797 13.1272 6.41797 13.258V22.2749C6.41797 22.4057 6.4544 22.5339 6.52317 22.6452C6.59195 22.7565 6.69035 22.8464 6.80736 22.905C9.65375 24.3279 12.0198 24.5 14.7789 24.5C15.2062 24.5 15.6432 24.4959 16.0925 24.4917C16.5895 24.487 17.1033 24.4821 17.642 24.4821H19.8689C21.086 24.4821 22.0762 23.492 22.0762 22.2749C22.0762 21.8656 21.9643 21.4821 21.7694 21.1532C22.4036 20.765 22.8276 20.0657 22.8276 19.2692C22.8276 18.86 22.7157 18.4765 22.5208 18.1476C23.155 17.7593 23.579 17.0601 23.579 16.2636C23.579 15.6912 23.36 15.1689 23.0013 14.7764C23.3919 14.3648 23.6062 13.8279 23.6062 13.258Z"
            fill="url(#paint1_linear_2198_52015)"
          />
          <path
            d="M22.141 7.11485H20.6381C20.2491 7.11485 19.9337 6.79943 19.9337 6.41041C19.9337 6.0214 20.2491 5.70598 20.6381 5.70598H22.141C22.53 5.70598 22.8454 6.0214 22.8454 6.41041C22.8454 6.79943 22.53 7.11485 22.141 7.11485ZM11.6213 7.11485H10.1185C9.72948 7.11485 9.41406 6.79943 9.41406 6.41041C9.41406 6.0214 9.72948 5.70598 10.1185 5.70598H11.6213C12.0103 5.70598 12.3257 6.0214 12.3257 6.41041C12.3257 6.79943 12.0104 7.11485 11.6213 7.11485ZM13.8762 3.21059C13.6327 3.21059 13.396 3.08426 13.2655 2.85827L12.5141 1.55679C12.3196 1.2199 12.4351 0.789023 12.772 0.594538C13.1088 0.400007 13.5397 0.51546 13.7342 0.852351L14.4856 2.15384C14.6801 2.49073 14.5647 2.9216 14.2278 3.11609C14.121 3.178 13.9997 3.2106 13.8762 3.21059ZM18.3833 3.21059C18.2598 3.2106 18.1385 3.178 18.0317 3.11609C17.6948 2.92155 17.5794 2.49073 17.7739 2.15384L18.5253 0.852351C18.7198 0.51546 19.1507 0.399913 19.4875 0.594538C19.8244 0.78907 19.9399 1.2199 19.7454 1.55679L18.994 2.85827C18.8636 3.08426 18.6267 3.21059 18.3833 3.21059Z"
            fill="#29CCB1"
          />
          <path
            d="M4.81122 20.7811V24.4912H3.40234V20.7811C3.40234 20.3923 3.71795 20.0767 4.10678 20.0767C4.49561 20.0767 4.81122 20.3923 4.81122 20.7811Z"
            fill="#73C3FF"
          />
          <path
            d="M22.141 7.11485H20.6381C20.2491 7.11485 19.9337 6.79943 19.9337 6.41041C19.9337 6.0214 20.2491 5.70598 20.6381 5.70598H22.141C22.53 5.70598 22.8454 6.0214 22.8454 6.41041C22.8454 6.79943 22.53 7.11485 22.141 7.11485ZM11.6213 7.11485H10.1185C9.72948 7.11485 9.41406 6.79943 9.41406 6.41041C9.41406 6.0214 9.72948 5.70598 10.1185 5.70598H11.6213C12.0103 5.70598 12.3257 6.0214 12.3257 6.41041C12.3257 6.79943 12.0104 7.11485 11.6213 7.11485ZM13.8762 3.21059C13.6327 3.21059 13.396 3.08426 13.2655 2.85827L12.5141 1.55679C12.3196 1.2199 12.4351 0.789023 12.772 0.594538C13.1088 0.400007 13.5397 0.51546 13.7342 0.852351L14.4856 2.15384C14.6801 2.49073 14.5647 2.9216 14.2278 3.11609C14.121 3.178 13.9997 3.2106 13.8762 3.21059ZM18.3833 3.21059C18.2598 3.2106 18.1385 3.178 18.0317 3.11609C17.6948 2.92155 17.5794 2.49073 17.7739 2.15384L18.5253 0.852351C18.7198 0.51546 19.1507 0.399913 19.4875 0.594538C19.8244 0.78907 19.9399 1.2199 19.7454 1.55679L18.994 2.85827C18.8636 3.08426 18.6267 3.21059 18.3833 3.21059Z"
            fill="#E35D6A"
          />
          <path
            d="M4.81122 20.7811V24.4912H3.40234V20.7811C3.40234 20.3923 3.71795 20.0767 4.10678 20.0767C4.49561 20.0767 4.81122 20.3923 4.81122 20.7811Z"
            fill="#73C3FF"
          />
          <path
            d="M7.11212 11.0508H1.10092C0.712094 11.0508 0.396484 11.3664 0.396484 11.7552V23.7776C0.396484 24.1669 0.712094 24.4821 1.10092 24.491H5.60931C6.47294 24.4821 7.222 23.9838 7.58411 23.2596C7.73746 22.954 7.81707 22.6167 7.81656 22.2748V11.7552C7.81656 11.3663 7.50095 11.0508 7.11212 11.0508Z"
            fill="#A51C30"
          />
          <path
            d="M4.81122 20.7337V24.4908H3.40234V20.7337C3.40234 20.3449 3.71795 20.0293 4.10678 20.0293C4.49561 20.0293 4.81122 20.3449 4.81122 20.7337Z"
            fill="url(#paint2_linear_2198_52015)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2198_52015"
            x1="13.844"
            y1="11.9326"
            x2="13.844"
            y2="3.21835"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2198_52015"
            x1="15.0116"
            y1="23.6428"
            x2="15.0116"
            y2="9.46351"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2198_52015"
            x1="4.10674"
            y1="24.2064"
            x2="4.10674"
            y2="19.5028"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <clipPath id="clip0_2198_52015">
            <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default LikeIcon
