import { PaletteOptions } from '@mui/material'

const white = '#fff'

export const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#DC3545',
    light: '#E35D6A',
    dark: '#a51c30',
    background: '#FDEFF1',
    backgroundHover: '#F8D7DA',
    contrastText: white
  },

  error: {
    main: '#FF2528',
    light: '#f55954',
    dark: '#d70103'
  },
  warning: {
    main: '#faad14',
    light: '#ffc53d',
    dark: '#e18700'
  },
  info: {
    main: '#1677ff',
    light: '#4096ff',
    dark: '#0958d9',
    background: '#E6F4FF'
  },
  success: {
    main: '#52c41a',
    light: '#73d13d',
    dark: '#389e0d',
    background: '#E9F9DA'
  },
  divider: '#E0E2E6',
  background: {
    default: '#F3F4F5',
    secondary: '#E7E9EC',
    paper: white
  },
  text: {
    primary: '#0E1426',
    secondary: '#626F82',
    disabled: '#C0C5CD'
    // hint: '#B5B5C3'
  },
  cyan: {
    base: '#13C2C2',
    active: '#08979C',
    hover: '#36CFC9',
    backgroundHover: '#B5F5EC',
    background: '#E6FFFB'
  },
  magenta: {
    base: '#E23F96',
    active: '#BD2E7F',
    hover: '#EF62AB',
    backgroundHover: '#FCD7E7',
    background: '#FEF0F6'
  },
  purple: {
    base: '#722ED1',
    active: '#531DAB',
    hover: '#9254DE',
    backgroundHover: '#EFDBFF',
    background: '#F9F0FF'
  }
}
