import { SvgIcon, SvgIconProps } from '@mui/material'

const ShareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2317 5.58711L4.26829 12.4129L12.2317 19.2387V15.4834C12.2317 15.2064 12.3466 14.9419 12.5489 14.7528C12.7513 14.5637 13.023 14.467 13.2994 14.4857C14.8674 14.5921 16.403 14.9957 17.8235 15.6772C18.599 16.0492 19.3314 16.4999 20.0099 17.0206C19.684 15.6082 19.0064 14.2894 18.0265 13.1932C16.7248 11.7372 14.9732 10.7674 13.0594 10.4326C12.5808 10.3488 12.2317 9.93332 12.2317 9.44752V5.58711ZM12.6945 3.00046C13.1583 2.98896 13.6013 3.19272 13.8944 3.55235C14.1514 3.86764 14.1965 4.23339 14.2136 4.42243C14.2318 4.62394 14.2318 4.87112 14.2317 5.11625L14.2317 8.64141C16.2623 9.16559 18.1051 10.2803 19.5175 11.8603C21.2642 13.8141 22.2303 16.3426 22.2317 18.9633V19.5759C22.2317 19.9972 21.9676 20.3734 21.5712 20.5164C21.1749 20.6595 20.7314 20.5388 20.4623 20.2146C19.505 19.0614 18.3097 18.1287 16.9584 17.4804C16.0917 17.0646 15.1739 16.7722 14.2317 16.6093L14.2317 19.7095C14.2318 19.9547 14.2318 20.2018 14.2136 20.4034C14.1965 20.5924 14.1514 20.9581 13.8944 21.2734C13.6013 21.6331 13.1583 21.8368 12.6945 21.8253C12.2879 21.8152 11.9808 21.6115 11.8262 21.5014C11.6614 21.3841 11.4737 21.2232 11.2876 21.0636L2.78955 13.7796C2.78308 13.774 2.77653 13.7684 2.76991 13.7627C2.66547 13.6733 2.54417 13.5694 2.44645 13.4698C2.33583 13.3571 2.18104 13.1781 2.08832 12.92C1.97056 12.5922 1.97056 12.2336 2.08832 11.9058C2.18104 11.6477 2.33583 11.4687 2.44645 11.356C2.54416 11.2564 2.66547 11.1525 2.7699 11.0631C2.77652 11.0574 2.78308 11.0518 2.78955 11.0462L11.2603 3.78561C11.2694 3.77779 11.2785 3.76997 11.2876 3.76215C11.4737 3.60259 11.6614 3.4417 11.8262 3.32438C11.9808 3.21432 12.2879 3.01055 12.6945 3.00046Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default ShareIcon
