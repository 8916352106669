import { SvgIcon, SvgIconProps } from '@mui/material'

const ChevronRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.19526 3.52876C6.45561 3.26841 6.87772 3.26841 7.13807 3.52876L10.6667 7.05735C11.1874 7.57805 11.1874 8.42227 10.6667 8.94297L7.13807 12.4716C6.87772 12.7319 6.45561 12.7319 6.19526 12.4716C5.93491 12.2112 5.93491 11.7891 6.19526 11.5288L9.72386 8.00016L6.19526 4.47157C5.93491 4.21122 5.93491 3.78911 6.19526 3.52876Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default ChevronRightIcon
