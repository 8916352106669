import axios from 'axios'
import { Response, UpdateCategoryProductsPayload } from 'entities/shop/service/service.type'
import { TypedProduct, TypedSummarize } from 'entities/shop/interface'
import { InjectPaginateParams } from '../../../interfaces/query.interface'
import _ from 'lodash'
import { IQueryParams } from 'config/reducer.utils'

// export const productApi = axios.create({
//   baseURL: process.env.REACT_APP_AJAX_URL_V2 + '/product',
//   headers: {
//     ['X-Authorization']:
//       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzU4MTExNTEsImRhdGEiOnsiX2lkIjoiNjRkNWRlMmZhYTJmZWQxNzU4NDUxMGQyIiwia2V5IjoiODRhYWM3M2JjZWZiZTE1NGUxMWY1MjE5MWUyYTY1ZWMzYTI0MjU3YWEwOGNlMzQyYWYxYTJiZjYzZDA0NTAwYiIsInNpZ25hdHVyZSI6IjBlNzI0YjBhYmJlY2QxMDA3YWY3M2M5Y2M2ZDEzY2EyNGY3MWNhNjQxMmVhZWZlNmNkOGJiZWU4MWY3ZGUyYzciLCJzZXNzaW9uIjoiNjU5NTJjY2Y1MmNjY2JlNGU0Nzk0ZDQxIn0sImlhdCI6MTcwNDI3NTE1MX0._r3bwu32scLrZLJeVubFB4hDkTeUnxe_CT-G4z42doM'
//   }
// })

export const getProductById = (productId: string): Response<TypedProduct, string> => {
  return axios.get(`v2/product/${productId}`)
}

export const updateProductById = (productId: string, body): Response<TypedProduct, string> => {
  return axios.patch(`v2/product/${productId}`,body)
}

export const getProducts = (
  params?: InjectPaginateParams<{
    stock_id?: string
    category_id?: string
    collection_id?: string
  }>
): Response<
  TypedProduct[],
  InjectPaginateParams<{
    stock_id?: string
    category_id?: string
    collection_id?: string
  }>
> => {
  const rest = _.omit(params, ['stock_id', 'category_id', 'collection_id'])
  return axios.get('v2/product', {
    params: {
      ['product_to_stock.stock_id']: params?.stock_id,
      ['product_to_category.category_id']: params?.category_id,
      ['product_to_collection.collection_id']: params?.collection_id,
      ...rest
    }
  })
}

export const updateCategoryProducts = (
  payload: UpdateCategoryProductsPayload
): Response<
  UpdateCategoryProductsPayload,
  {
    count: number
  }
> => {
  return axios.patch('v2/product', payload)
}

export const getListProductToStock = async ({
  stock_id,
  ...rest
}: {
  params: object
  stock_id: string
}) => {
  return axios.get<TypedProduct[]>(`v2/product?product_to_stock.stock_id=${stock_id}`, { params: rest })
}


//Review products
export const getListReviewOfProduct = (params: IQueryParams) => {
  const url = `v2/review`
  return axios.get(url,{params: params})
}

export const getSummarizeOfProduct = (product_id: string) => {
  const url = `v2/review/summarize/${product_id}`
  return axios.get<TypedSummarize>(url)
}

export const getProductOfToday = (params: IQueryParams) => {
  const url = `v2/product`
  return axios.get(url, {params: params})
}