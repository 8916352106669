import { SvgIcon, SvgIconProps } from '@mui/material'

const FacebookOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={'currentColor'}
      >
        <g clipPath="url(#clip0_1771_38403)">
          <path
            d="M13.6203 23.9531H9.76944C9.12622 23.9531 8.60298 23.4309 8.60298 22.7889V14.12H6.35628C5.71306 14.12 5.18982 13.5976 5.18982 12.9558V9.24115C5.18982 8.59918 5.71306 8.07697 6.35628 8.07697H8.60298V6.2168C8.60298 4.37238 9.18328 2.80316 10.2809 1.67908C11.3836 0.549866 12.9245 -0.046875 14.7371 -0.046875L17.674 -0.0421143C18.3161 -0.0410156 18.8384 0.481201 18.8384 1.12207V4.57105C18.8384 5.21301 18.3154 5.73523 17.6723 5.73523L15.695 5.73596C15.0919 5.73596 14.9384 5.85663 14.9055 5.89362C14.8514 5.95496 14.787 6.12836 14.787 6.60718V8.07678H17.5237C17.7298 8.07678 17.9294 8.1275 18.1009 8.22308C18.4709 8.42944 18.701 8.81964 18.701 9.24133L18.6995 12.956C18.6995 13.5976 18.1763 14.1198 17.5331 14.1198H14.787V22.7889C14.787 23.4309 14.2636 23.9531 13.6203 23.9531ZM10.0127 22.5461H13.3771V13.4901C13.3771 13.0615 13.7266 12.7128 14.1559 12.7128H17.2898L17.2911 9.48395H14.1557C13.7264 9.48395 13.3771 9.13532 13.3771 8.70667V6.60718C13.3771 6.0575 13.433 5.43237 13.8488 4.96252C14.3511 4.39453 15.1427 4.32898 15.6946 4.32898L17.4287 4.32825V1.3645L14.736 1.36011C11.823 1.36011 10.0127 3.22119 10.0127 6.2168V8.70667C10.0127 9.13513 9.6634 9.48395 9.2341 9.48395H6.59956V12.7128H9.2341C9.6634 12.7128 10.0127 13.0615 10.0127 13.4901V22.5461Z"
            fill={'currentColor' || 'black'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1771_38403">
            <rect width="24" height="24" fill={'currentColor' || 'white'} />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default FacebookOutlinedIcon
