import { Box, Drawer, IconButton, Stack, styled, Typography } from "@mui/material";
import TypedChannel from "interfaces/channel.interface";
import { Dispatch, SetStateAction } from "react";
import Sidebar from "./Sidebar";
//Image
import imageDefault from 'media/images/image_default.png'
import { CloseIcon } from '../icons'

interface TypedProps {
    toggle: boolean;
    setToggle: Dispatch<SetStateAction<boolean>>;
    channelData: TypedChannel;
};

const SidebarMobile = ({toggle, setToggle, channelData}: TypedProps) => {

    return (
        <SidebarMobileContainer
            PaperProps={{
                sx: {
                    width: '100%',
                    borderRadius: '0'
                },
            }}
            elevation={1}
            anchor="left"
            open={toggle}
            onClose={() => setToggle(false)}
        >
            <Box className="content">
                <Stack direction="row" justifyContent="space-between" alignItems="center" className="header">
                    <Box className="header_left">
                        <img
                            alt="logo"
                            src={channelData?.avatar?.media_thumbnail || imageDefault}
                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                e.currentTarget.src = imageDefault
                            }}

                        />
                        <Typography className="channel_name" color="text.primary" fontWeight={600}>
                            {channelData?.name || ''}
                        </Typography>
                    </Box>
                    <Box className="header_right">
                            <IconButton onClick={() => setToggle(false)}>
                                <CloseIcon />
                            </IconButton>
                    </Box>
                </Stack>
                <Box overflow="hidden" px='16px' py='8px'>
                 <Sidebar setToggle={setToggle} />
                </Box>
            </Box>
        </SidebarMobileContainer>
    )

};

export default SidebarMobile;

const SidebarMobileContainer = styled(Drawer)(({ theme }) => ({
    width: '100%',
    '& .content':{
        '& .header': {
            borderBottom: '0.5px solid var(--Other-Line, #E0E2E6)',
            '&_left': {
                display: 'flex',
                alignItems: 'center',
                '& img': {
                    width: theme.spacing(6.5),
                    height: theme.spacing(6.5),
                    borderRadius: '12%',
                    objectFit: 'cover',
                    marginRight: '8px'
                },
                '& .channel_name': {
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }
            }
        }
    }
}))
