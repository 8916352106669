//Library
import { Box, Button, Dialog, IconButton, InputBase, Stack, Typography, styled } from '@mui/material'
import React, {FormEventHandler, useCallback, useEffect, useRef} from 'react'
import { useState } from 'react'
import __ from 'languages/index'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

//Icons
import { SearchIcon } from 'icons'
//Helpers
import helpers from 'helpers';

interface TypedPosts {
  post_title?: string
  _id?: string
  post_content?: string
  post_slug?: string
}

const Search = () => {
  const navigate = useNavigate()
  const wrapperRef = useRef(null)
  const location = useLocation()
  // const user_data = useAppSelector(state => state.user.user_data)

  // const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false)
  const [textSearch, setTextSearch] = useState<string>('')
  const [openModalSearch, setOpenModalSearch] = useState(false);
  // const [postList, setPostList] = useState<TypedPosts[]>([])

  // const handleGetPosts = useCallback(
  //   async (_textSearch: string) => {
  //     try {
  //       const params = {
  //         order_by: 'DESC',
  //         order_type: 'time',
  //         search: _textSearch,
  //         page: 1,
  //         limit: 5,
  //         auth_id: user_data?._id
  //       }
  //       helpers.clearValueEmptyInObject(params)
  //       const response = await axios.get(`/request/list`, {
  //         params: params
  //       })
  //       const convertData = response?.data?.map(value => {
  //         return {
  //           post_title: value?.post_title || '',
  //           _id: value?._id || '',
  //           post_content: value?.post_content || '',
  //           post_slug: value?.post_slug || ''
  //         }
  //       })
  //       setPostList(convertData)
  //     } catch (error) {
  //       console.log(`handleGetPosts-${error}`)
  //     }
  //   },
  //   [user_data]
  // )

  // const requestSearch = useMemo(() => {
  //   return debounce((_value: string) => {
  //     handleGetPosts(_value)
  //   }, 500)
  // }, [])

  const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value)
    // requestSearch(e.target.value)
  }, [])

  // useEffect(() => {
  //   function handleClickOutside(event: Event) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       setIsOpenSearch(false)
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [wrapperRef, setIsOpenSearch])

  const onSubmitSearch = useCallback((event)=>{
    event.preventDefault();
    if(!textSearch) return
    navigate(`/search?search=${textSearch}`)
    setOpenModalSearch(false);
  },[textSearch])

  const handleClose = () => {
    setOpenModalSearch(false);
    setTextSearch('')
  };

  useEffect(() => {
    if(location?.pathname === '/search' && location?.search.includes('?search=')){
      setTextSearch(location?.search?.replace('?search=', '').trim())
    }else{
      setTextSearch('')
    }
  }, [location])

  useEffect(()=>{
    if(!openModalSearch) return
    if(location?.search){
      const search = new URLSearchParams(location.search)
      setTextSearch(search.get('search'))
    } else {
      setTextSearch('')
    }
  },[location, openModalSearch])


  return (
    <SearchContainer id="search" ref={wrapperRef}>
 {
        !helpers.isMobile() ? (
          <form className="search_form" onSubmit={onSubmitSearch}>
            <IconButton aria-label="search" sx={{ padding: 0 }}>
              <SearchIcon color="inherit" />
            </IconButton>
            <InputBase
              // onFocus={() => {
              //   setIsOpenSearch(true)
              // }}
              value={textSearch || ''}
              onChange={handleChangeValue}
              placeholder={__('header_search')}
              inputProps={{ 'aria-label': __('header_search') }}
              className="search_input"
            />
          </form>
        ) : (
          <>
            <IconButton onClick={()=> setOpenModalSearch(true)} aria-label="search" sx={{ padding: !helpers.isMobile() ? '0 0 0 20px' : 0 }}>
              <SearchIcon color="inherit" />
            </IconButton>
            <Dialog
              fullScreen
              open={openModalSearch}
              onClose={handleClose}
            >
              <Stack sx={{rowGap: theme=>theme.spacing(2), p: theme=>theme.spacing(2)}}>
                <Box component='form' sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    columnGap: theme =>theme.spacing(2),
                    borderRadius: theme=>theme.spacing(1.5),
                    padding: theme=>theme.spacing(1, 2),
                    color: theme=>theme.palette.text.secondary,
                    border: theme=>`1px solid ${theme.palette.divider}`,
                }}  onSubmit={onSubmitSearch}>
                  <IconButton aria-label="search" sx={{ padding: 0 }}>
                    <SearchIcon color="inherit" />
                  </IconButton>
                  <InputBase
                    autoFocus
                    value={textSearch || ''}
                    onChange={handleChangeValue}
                    placeholder={__('header_search')}
                    inputProps={{ 'aria-label': __('header_search') }}
                    className="search_input"
                  />
                </Box>
                <Box sx={{display: 'flex', justifyContent:'flex-end',columnGap: theme=>theme.spacing(2) }}>
                  <Button disabled={!textSearch} onClick={onSubmitSearch}>
                    <Typography textTransform={'none'}>
                      {__('header_search')}
                    </Typography>
                  </Button>
                  <Button onClick={handleClose} variant='text'>
                    <Typography textTransform={'none'}>
                      {__('upgrade_compare_modal_btn_cancel')}
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            </Dialog>
          </>
        )
      }
      {/* {isOpenSearch && (
        <Box className="search_modal">
          <Box>
            {helpers.isEmpty(postList) && textSearch ? (
              <Box>Khong co ket qua</Box>
            ) : (
              <>
                {postList?.map((post, idx) => (
                  <Typography key={idx}>{post?.post_title}</Typography>
                ))}
              </>
            )}
            {!textSearch && (
              <>
                <Typography
                  sx={{
                    padding: theme => theme.spacing(1.5, 0)
                  }}
                  variant="body1"
                  color={'text.secondary'}
                  fontWeight={600}
                >
                  {__('header_recent_searches')}
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: theme => theme.spacing(1.5)
                  }}
                >
                  {Array(1, 2)?.map((_value, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr auto',
                        columnGap: theme => theme.spacing(2)
                      }}
                    >
                      <RotateIcon width={'100%'} height={'100%'} />
                      <Typography
                        role="button"
                        variant="body2"
                        color="text.primary"
                        fontSize={14}
                        fontWeight={400}
                      >
                        Bài viết hôm nay 1
                      </Typography>
                      <IconButton>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
                <Typography
                  sx={{
                    padding: theme => theme.spacing(1.5, 0)
                  }}
                  variant="body1"
                  color={'text.secondary'}
                  fontWeight={600}
                >
                  {__('header_popular_keywords')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: theme => theme.spacing(1),
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap'
                  }}
                >
                  {Array(1, 2, 3).map((_value, idx) => (
                    <Typography
                      key={idx}
                      sx={{
                        borderRadius: theme => theme.spacing(1.5),
                        background: theme => theme.palette.background.default,
                        padding: theme => theme.spacing(1.5, 3),
                        whiteSpace: 'nowrap'
                      }}
                      variant="body2"
                      color="text.primary"
                      fontWeight={600}
                      fontSize={'14px'}
                    >
                      Gamifications
                    </Typography>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )} */}
    </SearchContainer>
  )
}

export default Search

const SearchContainer = styled(Box)(({ theme }) => ({
  '& .search_form': {
    display: 'grid',
    maxWidth: theme.spacing(96),
    margin: '0 auto',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
    columnGap: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1, 2),
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.divider}`,
    position: 'relative'
  },
    '& .search_input': {
    '& ::placeholder': {
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(2),
      fontWeight: 400,
      lineHeight: '133%'
    }
  },
  '& .search_modal': {
    position: 'absolute',
    top: '68px',
    left: 0,
    width: '100%',
    maxWidth: '766px',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1.5),
    height: 'auto',
    maxHeight: '300px',
    minHeight: '230px',
    overflow: 'auto',
    background: theme.palette.background.paper,
    zIndex: 1,
    boxShadow: '0px 4px 12px 0px rgba(14, 20, 38, 0.12)'
  }
}))
