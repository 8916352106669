//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//helpers
import helpers from 'helpers'

//Interfaces
import { TypedDataMember } from 'interfaces/member.interface'

export const initialState = {}

export const getListMembers = createAsyncThunk(
  'member/get_list_members',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<TypedDataMember[]>(`/channel/list-members`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createChatroomToUser = createAsyncThunk(
  'member/create_chatroom_to_user',
  async (body: { partner_id: string; chat_type: string | 'personal' }, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`/chat-room/create`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateStatusPermissionUser = createAsyncThunk(
  'member/updateStatusPermissionUser',
  async (body: { _id?: string; official_status?: number;}, { rejectWithValue }) => {
    try {
      const response = await axios.patch<any>(
        `/channel/update-official-status/${body?._id}`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const banUser = createAsyncThunk(
  'member/banUser',
  async (body: { _id?: string}, { rejectWithValue }) => {
    try {
      const response = await axios.delete<any>(
        `/channel/delete-permission/${body?._id}`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const postUserIntoBoss = createAsyncThunk(
  'member/postUserIntoBoss',
  async (body: { _id?: string}, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>(
        `/channel/add-boss-channel`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const postBossIntoUser = createAsyncThunk(
  'member/postBossIntoUser',
  async (body: { _id?: string}, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>(
        `/channel/remove-boss-channel`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const deleteUser = createAsyncThunk(
  'member/delete_user',
  async (_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete<any>(`/channel/delete-permission/${_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListLevel = createAsyncThunk(
  'member/get_list_level',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>(`/channel/list-level`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updatePointUser = createAsyncThunk(
  'member/update_point_user',
  async (body: {user_id: string, point: number}, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/channel/point/modify`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const MEMBER_REDUCER = createEntitySlice({
  name: 'member',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = MEMBER_REDUCER.actions

export default MEMBER_REDUCER.reducer
