import { createEntitySlice, IQueryParams, serializeAxiosError } from 'config/reducer.utils'
import { Order } from 'entities/orders/order.type'
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import axios, { AxiosRequestConfig } from 'axios'
import helpers from 'helpers/index'
import { useSelector } from 'react-redux'
import { IRootState } from 'config/store'
import { OrderStatus } from './data/order.enum'

export const initialState = {
  entity: {} as Order,
  entities: [] as Order[],
  listPaymentMethod: [],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false
}

const apiUrl = 'order'

export const createOrder = createAsyncThunk(
  'order/create_order',
  async (data: { channel_id?: string, plan_id: string; amount_of_package: string; payment_method?: string; trans_id?: string }) =>
    axios.post<any, any>(`${apiUrl}/create`, data),
  { serializeError: serializeAxiosError }
)
export const createOrderExtension = createAsyncThunk(
  'order/createOrderExtension',
  async (data: { plan_id: string; amount_of_package: string; payment_method: string; status: string }) =>
    axios.post<any, any>(`${apiUrl}/channel-n-extension`, data),
  { serializeError: serializeAxiosError }
)
export const getEntity = createAsyncThunk(
  'order/detail_order',
  async ({ _id }: { _id: string }) => axios.get<Order>(`${apiUrl}/detail-order/${_id}`),
  { serializeError: serializeAxiosError }
)
export const getEntities = createAsyncThunk(
  'order/user_list',
  async (queryParam: IQueryParams) => {
    const EndURL = helpers.buildEndUrl(queryParam)
    const requestUrl = `${apiUrl}/user-list${EndURL}`
    return axios.get<Order[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const getListOrderOfChannel = createAsyncThunk(
  'order/user_list',
  async (queryParam: IQueryParams) => {
    const EndURL = helpers.buildEndUrl(queryParam)
    const requestUrl = `${apiUrl}/admin-list${EndURL}`
    return axios.get<Order[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)
export const updateOrder = createAsyncThunk(
  'order/user-update',
  async (data: { _id: string; status: string; media_id?: string }) =>
    axios.post(`${apiUrl}/user-update`, data),
  { serializeError: serializeAxiosError }
)
export const updateOrderbyAdmin = createAsyncThunk(
  'order/admin-update',
  async (data: { _id: string; status: string }) => axios.post(`${apiUrl}/admin-update`, data),
  { serializeError: serializeAxiosError }
)
export const getListUserSubscription = createAsyncThunk(
  'order/get_list_user_subscription',
  async ({ params, id }: { params: IQueryParams; id: string }, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.get<any>(`subscribe/user/${id}`, { params: params })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListUserExpiredSubscription = createAsyncThunk(
  'order/get_list_user_expired_subscription',
  async ({ params, id }: { params: IQueryParams; id: string }, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)

      return axios.get<any>(`subscribe/user-expired/${id}`, { params: params })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateSubscription = createAsyncThunk(
  'order/update_subscription',
  async (body: { _id: string; is_auto_renew: string }, { rejectWithValue }) => {
    try {
      return axios.patch<any>(`subscribe/user-update-subscribe`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListPaymentMethod = createAsyncThunk(
  'order/payment_method_list',
  async (data: { service_id: string }) => {
    const EndURL = helpers.buildEndUrl(data)
    const requestUrl = `${apiUrl}/list-payment-method${EndURL}`
    return axios.get<string[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const getOrders = createAsyncThunk(
  'order/get_list',
  async (_queryParams: IQueryParams) => {
    const EndURL = helpers.buildEndUrl(_queryParams)
    const requestUrl = `v2/${apiUrl}s${EndURL}`
    return axios.get<Order[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const getOrderdetailOrder = createAsyncThunk(
  'order/detail',
  async ({ _id }: { _id: string }) => {
    const requestUrl = `v2/${apiUrl}s/${_id}`
    return axios.get<Order>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const updateStatusOrder = createAsyncThunk(
  'order/update_status_order',
  async ({ idOrder, body }: { idOrder: string; body: { order_status?: OrderStatus } }) => {
    const requestUrl = `v2/${apiUrl}s/set_status/${idOrder}`
    return axios.patch<any>(requestUrl, helpers.cleanEntity({...body}))
  },
  { serializeError: serializeAxiosError }
)

export const assignProduct = createAsyncThunk(
  'order/assignProduct',
  async (data: { order_id: string, product_id: string, item_quantity: number }, { rejectWithValue }) => {
    console.log('assign product', data)

    try {
      const response = await axios.post<any, any>(`v2/orders/assign/product`, data)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const assignProductChangeQuantity = createAsyncThunk(
  'order/assignProductChangeQuantity',
  async (data: { order_product_id: string, item_quantity: number }, { rejectWithValue }) => {
    try {
      const response = await axios.patch<any, any>(`v2/orders/assign/product/${data.order_product_id}`, {
        item_quantity: data.item_quantity
      })

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const assignProductDelete = createAsyncThunk(
  'order/assignProductDelete',
  async (data: { order_product_id: string, order_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.delete<{}, any>(`v2/orders/assign/product/${data.order_product_id}`, {
        data: { order_id: data.order_id }
      })

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const assignOrderUserAddress = createAsyncThunk(
  'order/assignAddress',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post<any, any>(`v2/orders/assign/address`, data)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const assignOrderFullfillment = createAsyncThunk(
  'order/assignOrderFullfillment',
  async (data: {
    order_id: string,
    logistic_id: number
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any, any>(`v2/orders/assign/fullfillment`, data)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const orderEdit = createAsyncThunk(
  'order/orderEdit',
  async ({ order_id, ...rest }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put<any, any>(`v2/orders/${order_id}`, rest)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const createOrderAdmin = createAsyncThunk(
  'order/orderCreateAdmin',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await axios.post<any, any>(`v2/orders`, body)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
  ,
  { serializeError: serializeAxiosError }
)

const ORDER_REDUCER = createEntitySlice({
  name: 'order',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => ({ ...state, ...initialState }),
    clearEntity: state => {
      state.entity = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false
        state.entities = action.payload.data
        state.totalItems = parseInt(action.payload.headers['x-total-count'] || 1000, 10)
      })
      .addCase(getEntities.pending, state => {
        state.loading = true
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false
        state.entities = null
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addCase(getListPaymentMethod.fulfilled, (state, action) => {
        state.loading = false
        state.listPaymentMethod = action.payload.data
      })
      .addCase(updateOrder.pending, state => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.updating = false
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false
        state.entities = action.payload.data
        state.totalItems = parseInt(action.payload.headers['x-total-count'] || 1000, 10)
      })
      .addCase(getOrders.pending, state => {
        state.loading = true
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false
        state.entities = null
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addMatcher(isPending(getEntity, createOrder, createOrderExtension), state => {
        state.loading = true
        state.entity = null
      })
      .addMatcher(isRejected(getEntity, createOrder, createOrderExtension), (state, action) => {
        state.loading = false
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(getEntity, createOrder, createOrderExtension), (state, action) => {
        state.loading = false
        // @ts-ignore
        state.entity = action.payload.data
      })
  }
})
export const { clearError, clearEntity, reset } = ORDER_REDUCER.actions

export const selectorOrders = (state: IRootState) => state.order.entities;
export default ORDER_REDUCER.reducer
