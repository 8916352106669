import { SvgIcon, SvgIconProps } from '@mui/material'

const CourseSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="29"
        viewBox="0 0 35 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 48095856">
          <path
            id="Vector"
            d="M28.9896 18.6643V20.4655C28.9896 21.6435 28.4804 22.6923 27.7356 23.0343L18.4484 27.2675C17.9316 27.5031 17.3844 27.5031 16.8752 27.2675L7.57276 23.0723C6.82036 22.7303 6.30356 21.6891 6.30356 20.5111V18.7099L6.28076 8.33594L16.7536 11.0871C17.3388 11.2391 17.9316 11.2315 18.5168 11.0871L29.0732 8.51834C29.0732 8.51834 28.9972 15.2291 28.9972 18.6567L28.9896 18.6643Z"
            fill="url(#paint0_linear_1101_5207)"
          />
          <path
            id="Vector_2"
            d="M18.5004 11.0869C17.9152 11.2389 17.3223 11.2389 16.7371 11.0869L7.29035 8.70051C7.10035 9.60491 6.94076 10.5245 6.80396 11.4593L17.4819 27.4193C17.8087 27.4497 18.1355 27.3965 18.4471 27.2521L27.7268 23.0037C28.4792 22.6617 28.9883 21.6129 28.9883 20.4349V18.6337C28.9731 15.1985 28.6159 11.8317 27.9319 8.62451L18.5004 11.0793V11.0869Z"
            fill="url(#paint1_linear_1101_5207)"
          />
          <path
            id="Vector_3"
            d="M16.7227 0.485124L1.11233 6.52712C0.321931 6.83112 0.321931 9.41512 1.11233 9.71912L16.7455 15.7003C17.3307 15.9207 17.9235 15.9207 18.5087 15.7003L34.1191 9.65832C34.9095 9.35432 34.9095 6.77032 34.1191 6.46632L18.4859 0.485124C17.9007 0.264724 17.3079 0.264724 16.7227 0.485124Z"
            fill="url(#paint2_linear_1101_5207)"
          />
          <path
            id="Vector_4"
            d="M34.4607 6.79297C34.3695 6.95257 34.2555 7.07417 34.1263 7.12737L18.5159 13.1694C17.9307 13.3974 17.3379 13.3974 16.7527 13.1694L1.11952 7.18817C0.982722 7.13497 0.868724 7.01337 0.777524 6.85377C0.344324 7.64417 0.465922 9.45297 1.11952 9.71137L16.7527 15.6926C17.3379 15.913 17.9307 15.913 18.5159 15.6926L34.1263 9.65057C34.7875 9.39217 34.8939 7.58337 34.4607 6.79297Z"
            fill="url(#paint3_linear_1101_5207)"
          />
          <path
            id="Vector_5"
            d="M18.4937 23.2092C17.9465 23.46 17.3613 23.46 16.8141 23.2092L6.90366 18.7404C6.68326 18.6416 6.47805 18.482 6.29565 18.2768C6.29565 18.3832 6.29565 18.4972 6.29565 18.6036V19.8424C6.47805 20.0476 6.68326 20.2072 6.90366 20.3136L16.8141 24.7824C17.3613 25.0332 17.9465 25.0256 18.4937 24.7824L28.3889 20.2756C28.6093 20.1768 28.8145 20.0096 28.9969 19.8044V18.5656C28.9969 18.4592 28.9969 18.3452 28.9969 18.2388C28.8221 18.444 28.6169 18.6036 28.3965 18.71L18.5013 23.2168L18.4937 23.2092Z"
            fill="url(#paint4_linear_1101_5207)"
          />
          <path
            id="Vector_6"
            d="M18.9718 6.03312C18.7742 5.53152 18.2422 5.17432 17.619 5.17432C16.8286 5.17432 16.1826 5.75192 16.1826 6.46632C16.1826 6.51952 18.493 11.7103 18.5082 11.7407C18.645 12.4019 21.3734 12.3791 21.3734 11.6875C21.3734 11.4975 21.3278 11.3227 21.2442 11.1631L18.9642 6.04072L18.9718 6.03312Z"
            fill="url(#paint5_linear_1101_5207)"
          />
          <path
            id="Vector_7"
            d="M30.8519 27.5185C30.6847 27.5185 30.4187 27.2221 30.4111 26.8497L30.3655 9.40015C30.3655 8.76935 30.1451 8.67055 29.8639 8.61735L17.3467 6.63375L17.3771 5.88135L30.0387 7.86495C30.6315 7.97135 31.0875 8.07015 31.0951 9.40015L31.1407 26.8497C31.1407 27.2221 31.0115 27.5185 30.8443 27.5185H30.8519Z"
            fill="url(#paint6_linear_1101_5207)"
          />
          <path
            id="Vector_8"
            d="M31.8087 26.1959C31.8087 26.8419 31.3755 28.4303 30.8283 28.4303C30.2811 28.4303 29.8403 26.8495 29.8403 26.2035C29.8403 25.5575 30.2811 24.6987 30.8207 24.6987C31.3603 24.6987 31.8087 25.5499 31.8087 26.1959Z"
            fill="url(#paint7_linear_1101_5207)"
          />
          <path
            id="Vector_9"
            d="M30.8205 24.6909C30.6761 24.6909 30.5469 24.7669 30.4253 24.8885L31.7933 26.9405C31.8085 26.8113 31.8085 26.6897 31.8085 26.5909C31.8085 25.7701 31.3677 24.6909 30.8205 24.6909Z"
            fill="url(#paint8_linear_1101_5207)"
          />
          <path
            id="Vector_10"
            d="M31.3526 24.4481C31.3526 24.8889 31.117 25.0485 30.8206 25.0485C30.5242 25.0485 30.2886 24.8889 30.2886 24.4557C30.2886 24.0225 30.5242 23.6577 30.813 23.6577C31.1018 23.6577 31.345 24.0149 31.345 24.4557L31.3526 24.4481Z"
            fill="url(#paint9_linear_1101_5207)"
          />
          <path
            id="Vector_11"
            d="M19.0481 6.4585C19.0481 7.1729 16.1753 7.1729 16.1753 6.4585C16.1753 5.7441 16.8137 5.1665 17.6117 5.1665C18.4097 5.1665 19.0481 5.7441 19.0481 6.4509V6.4585Z"
            fill="url(#paint10_linear_1101_5207)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1101_5207"
            x1="14.5965"
            y1="18.01"
            x2="20.038"
            y2="17.9996"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#575C55" />
            <stop offset="1" stopColor="#45425A" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1101_5207"
            x1="25.0749"
            y1="23.4232"
            x2="12.2607"
            y2="1.47653"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#45425A" stopOpacity="0" />
            <stop offset="0.35" stopColor="#353343" stopOpacity="0.35" />
            <stop offset="1" stopColor="#1A1A1A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1101_5207"
            x1="8.96"
            y1="-5.14027"
            x2="33.5312"
            y2="11.1725"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#575C55" />
            <stop offset="1" stopColor="#45425A" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1101_5207"
            x1="16.2216"
            y1="-3.05677"
            x2="23.443"
            y2="19.1838"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#45425A" stopOpacity="0" />
            <stop offset="0.35" stopColor="#353343" stopOpacity="0.35" />
            <stop offset="1" stopColor="#1A1A1A" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1101_5207"
            x1="16.017"
            y1="21.7617"
            x2="21.2381"
            y2="21.7517"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1101_5207"
            x1="20.0552"
            y1="10.5476"
            x2="14.1758"
            y2="6.64434"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#45425A" stopOpacity="0" />
            <stop offset="0.35" stopColor="#353343" stopOpacity="0.35" />
            <stop offset="1" stopColor="#1A1A1A" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1101_5207"
            x1="34.3301"
            y1="22.8553"
            x2="14.9283"
            y2="9.96749"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE548" />
            <stop offset="1" stopColor="#FFB211" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1101_5207"
            x1="30.5197"
            y1="26.6578"
            x2="32.6857"
            y2="26.6536"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE548" />
            <stop offset="1" stopColor="#FFB211" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1101_5207"
            x1="31.7873"
            y1="26.0936"
            x2="29.9598"
            y2="24.2731"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFB211" stopOpacity="0" />
            <stop offset="0.23" stopColor="#FF9F19" stopOpacity="0.23" />
            <stop offset="0.69" stopColor="#FF6F2E" stopOpacity="0.69" />
            <stop offset="1" stopColor="#FF4B3E" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1101_5207"
            x1="30.5294"
            y1="23.9988"
            x2="31.4584"
            y2="24.9166"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE548" />
            <stop offset="1" stopColor="#FFB211" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1101_5207"
            x1="17.8413"
            y1="5.21647"
            x2="17.8441"
            y2="6.70987"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#575C55" />
            <stop offset="1" stopColor="#45425A" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default CourseSidebarIcon
