import { SvgIcon, SvgIconProps } from '@mui/material'

const UploaderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5858 2.99985C11.3668 2.2188 12.6332 2.2188 13.4142 2.99985L16.7071 6.29274C17.0976 6.68327 17.0976 7.31643 16.7071 7.70696C16.3166 8.09748 15.6834 8.09748 15.2929 7.70696L13 5.41406L13 15.9998C13 16.5521 12.5523 16.9998 12 16.9998C11.4477 16.9998 11 16.5521 11 15.9998L11 5.41406L8.70711 7.70696C8.31658 8.09748 7.68342 8.09748 7.29289 7.70696C6.90237 7.31643 6.90237 6.68327 7.29289 6.29274L10.5858 2.99985ZM3 14.9998C3.55229 14.9998 4 15.4476 4 15.9998V16.1998C4 17.0564 4.00078 17.6387 4.03755 18.0888C4.07337 18.5272 4.1383 18.7514 4.21799 18.9078C4.40973 19.2842 4.7157 19.5901 5.09202 19.7819C5.24842 19.8616 5.47262 19.9265 5.91104 19.9623C6.36113 19.9991 6.94342 19.9998 7.8 19.9998H16.2C17.0566 19.9998 17.6389 19.9991 18.089 19.9623C18.5274 19.9265 18.7516 19.8616 18.908 19.7819C19.2843 19.5901 19.5903 19.2842 19.782 18.9078C19.8617 18.7514 19.9266 18.5272 19.9624 18.0888C19.9992 17.6387 20 17.0564 20 16.1998V15.9998C20 15.4476 20.4477 14.9998 21 14.9998C21.5523 14.9998 22 15.4476 22 15.9998V16.2412C22 17.0461 22 17.7105 21.9558 18.2517C21.9099 18.8138 21.8113 19.3305 21.564 19.8158C21.1805 20.5685 20.5686 21.1804 19.816 21.5639C19.3306 21.8112 18.8139 21.9097 18.2518 21.9557C17.7106 21.9999 17.0463 21.9999 16.2413 21.9998H7.75868C6.95372 21.9999 6.28936 21.9999 5.74817 21.9557C5.18608 21.9097 4.66937 21.8112 4.18404 21.5639C3.43139 21.1804 2.81947 20.5685 2.43597 19.8158C2.18868 19.3305 2.09012 18.8138 2.04419 18.2517C1.99998 17.7105 1.99999 17.0461 2 16.2411L2 15.9998C2 15.4476 2.44772 14.9998 3 14.9998Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default UploaderIcon
