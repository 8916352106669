import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} stroke="currentColor">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="currentColor"
        stroke="currentColor"
      >
        <path
          d="M2.82331 6.76007H2.82313H1.30321C1.03241 6.76007 0.881609 7.07186 1.04843 7.28345L1.04848 7.28351L7.00422 14.8287L7.00426 14.8288C7.34288 15.2574 7.99272 15.2566 8.33303 14.8292L8.33344 14.8287L18.9525 1.37231C18.9526 1.37211 18.9528 1.37192 18.9529 1.37172C19.1223 1.16016 18.9667 0.85 18.6986 0.85H17.1786C16.92 0.85 16.674 0.968609 16.5148 1.17274C16.5147 1.17285 16.5146 1.17297 16.5145 1.17308L7.66775 12.3802L3.48675 7.08254C3.48672 7.08251 3.4867 7.08248 3.48668 7.08245C3.40764 6.98213 3.30691 6.901 3.19204 6.84517C3.07713 6.78932 2.95107 6.76023 2.82331 6.76007Z"
          fill="currentColor"
          strokeWidth="0.3"
        />
      </svg>
    </SvgIcon>
  )
}

export default CheckIcon
