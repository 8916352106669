import { SvgIcon, SvgIconProps } from '@mui/material'

const FlagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3V9.88889H6.33894C8.66494 9.88889 10.9011 10.787 12.5542 12.3941C13.8282 13.6327 15.5619 14.3333 17.3753 14.3333H20V7.44444H19.6611C17.3351 7.44444 15.0989 6.54638 13.4458 4.93922C12.1718 3.70065 10.4381 3 8.62465 3H6ZM6 11.8889H6.33894C8.1524 11.8889 9.88611 12.5895 11.1601 13.8281C12.8131 15.4353 15.0493 16.3333 17.3753 16.3333H20.3219C21.222 16.3333 22 15.6168 22 14.6741V6.83584C22 6.04103 21.3453 5.44444 20.5974 5.44444H19.6611C17.8476 5.44444 16.1139 4.7438 14.8399 3.50523C13.1869 1.89807 10.9507 1 8.62465 1H5.60387C4.74482 1 4 1.6842 4 2.5871V22C4 22.5523 4.44772 23 5 23C5.55228 23 6 22.5523 6 22V11.8889Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default FlagIcon
