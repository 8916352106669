import { SvgIcon, SvgIconProps } from '@mui/material'

const LivestreamSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M15.0972 29.0315C21.0252 29.0315 25.836 23.9243 25.836 17.6239C25.836 11.3235 21.0252 6.21631 15.0972 6.21631C9.1692 6.21631 4.3584 11.3235 4.3584 17.6239C4.3584 23.9243 9.1692 29.0315 15.0972 29.0315Z"
          fill="#FCBCB8"
        />
        <path
          d="M3.43774 6.21622C3.43774 5.96542 3.52894 5.71462 3.71134 5.52462C9.98894 -1.14818 20.2033 -1.14818 26.4809 5.52462C26.8381 5.90462 26.8381 6.52782 26.4809 6.90782C26.1237 7.28782 25.5385 7.28782 25.1813 6.90782C19.6257 1.00262 10.5741 1.00262 5.01855 6.90782C4.66135 7.28782 4.07615 7.28782 3.71895 6.90782C3.53655 6.71782 3.44535 6.46702 3.44535 6.21622H3.43774Z"
          fill="#FCBCB8"
          stroke="#FCBCB8"
          strokeWidth="0.5"
        />
        <path
          d="M3.43774 29.0313C3.43774 28.7805 3.52894 28.5297 3.71134 28.3397C4.06854 27.9597 4.65374 27.9597 5.01094 28.3397C10.5665 34.2449 19.6181 34.2449 25.1737 28.3397C25.5309 27.9597 26.1161 27.9597 26.4733 28.3397C26.8305 28.7197 26.8305 29.3429 26.4733 29.7229C20.1957 36.3957 9.98134 36.3957 3.70374 29.7229C3.52134 29.5329 3.43774 29.2821 3.43774 29.0313Z"
          fill="#FCBCB8"
          stroke="#FCBCB8"
          strokeWidth="0.5"
        />
        <path
          d="M26.7321 19.5163L32.0217 22.7615C33.2225 23.4987 34.7197 22.5791 34.7197 21.1047V14.6143C34.7197 13.1399 33.2225 12.2203 32.0217 12.9575L26.7321 16.2027C25.5313 16.9399 25.5313 18.7791 26.7321 19.5163Z"
          fill="url(#paint0_linear_2408_2331)"
        />
        <path
          d="M28.5561 15.0854L26.7321 16.2026C25.5313 16.9398 25.5313 18.779 26.7321 19.5162L32.0217 22.7614C33.0857 23.4151 34.3853 22.7614 34.6665 21.5834L28.5561 15.093V15.0854Z"
          fill="url(#paint1_linear_2408_2331)"
        />
        <path
          d="M25.8731 9.78809H3.37713C1.79633 9.78809 0.519531 11.2701 0.519531 13.0941V22.6245C0.519531 24.4485 1.79633 25.9305 3.37713 25.9305H25.8731C27.4539 25.9305 28.7307 24.4485 28.7307 22.6245V13.0941C28.7307 11.2701 27.4539 9.78809 25.8731 9.78809Z"
          fill="url(#paint2_linear_2408_2331)"
        />
        <path
          d="M0.519531 19.9038V22.6474C0.519531 24.4562 1.81153 25.923 3.39993 25.923H25.8503C27.4387 25.923 28.7307 24.4562 28.7307 22.6474V19.9038H0.519531Z"
          fill="url(#paint3_linear_2408_2331)"
        />
        <path
          d="M8.68252 21.2341C8.31012 21.2341 8.12012 21.0137 8.12012 20.5729V14.8045C8.12012 14.3713 8.30251 14.1509 8.66731 14.1509C9.03211 14.1509 9.21451 14.3637 9.21451 14.8045V20.1397H11.7073C12.0265 20.1397 12.1861 20.3221 12.1861 20.6793C12.1861 21.0365 12.0265 21.2265 11.7073 21.2265H8.67491L8.68252 21.2341Z"
          fill="white"
        />
        <path
          d="M13.7596 21.3101C13.3948 21.3101 13.2124 21.0897 13.2124 20.6489V14.7589C13.2124 14.3181 13.3948 14.0977 13.7596 14.0977C14.1244 14.0977 14.3068 14.3181 14.3068 14.7589V20.6489C14.3068 21.0897 14.1244 21.3101 13.7596 21.3101Z"
          fill="white"
        />
        <path
          d="M18.3042 21.3098C18.0154 21.3098 17.8026 21.1426 17.6734 20.8082L15.4314 14.9486C15.3402 14.6978 15.3402 14.4926 15.4466 14.333C15.5454 14.1734 15.6974 14.0974 15.8874 14.0974C16.0546 14.0974 16.1762 14.1354 16.2598 14.219C16.3434 14.3026 16.4118 14.4242 16.4726 14.5914L18.3194 19.577L20.1662 14.5762C20.227 14.4166 20.303 14.295 20.3866 14.2114C20.4702 14.1278 20.5918 14.0898 20.7514 14.0898C20.9338 14.0898 21.0706 14.1734 21.1694 14.333C21.2606 14.4926 21.2606 14.6978 21.1694 14.941L18.9274 20.8006C18.8666 20.9678 18.783 21.0894 18.6918 21.173C18.6006 21.2566 18.4714 21.2946 18.3118 21.2946L18.3042 21.3098Z"
          fill="white"
        />
        <path
          d="M22.8645 21.2339C22.4769 21.2339 22.2793 21.0059 22.2793 20.5575V14.8727C22.2793 14.4167 22.4769 14.1963 22.8645 14.1963H25.9349C26.2313 14.1963 26.3833 14.3635 26.3833 14.6979C26.3833 15.0323 26.2313 15.2147 25.9349 15.2147H23.3357V17.1527H25.7449C26.0413 17.1527 26.1933 17.3199 26.1933 17.6619C26.1933 18.0039 26.0413 18.1711 25.7449 18.1711H23.3357V20.2231H25.9349C26.2313 20.2231 26.3833 20.3903 26.3833 20.7323C26.3833 21.0743 26.2313 21.2415 25.9349 21.2415H22.8645V21.2339Z"
          fill="white"
        />
        <path
          d="M3.83263 19.6453C4.72183 19.6453 5.44383 18.9233 5.44383 18.0341C5.44383 17.1449 4.72183 16.4229 3.83263 16.4229C2.94343 16.4229 2.22144 17.1449 2.22144 18.0341C2.22144 18.9233 2.94343 19.6453 3.83263 19.6453Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2408_2331"
            x1="32.6449"
            y1="17.8595"
            x2="35.6317"
            y2="17.8595"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2408_2331"
            x1="33.0097"
            y1="19.7898"
            x2="28.3205"
            y2="18.3458"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2408_2331"
            x1="14.6251"
            y1="9.78809"
            x2="14.6251"
            y2="25.9913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2408_2331"
            x1="14.6251"
            y1="20.2078"
            x2="14.6251"
            y2="26.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default LivestreamSidebarIcon
