import axios, { AxiosResponse } from 'axios'
import { BasicInfoPayload } from '../components/validation/basicInfoForm.validation'
import { CreateChannelContactPayload } from '../components/validation/contactForm.vaidation'
import TypedChannel from '../../../interfaces/channel.interface'
import TypedPlan from '../../../interfaces/plan.interface'
import {Order} from "entities/orders/order.type";

// const baseUrl = process.env.REACT_APP_AJAX_URL + '/channel'

export type CreateChannelPayload = BasicInfoPayload & {
  avatar: string
} & Partial<CreateChannelContactPayload>

// const channelApi = axios.create({
//   baseURL: baseUrl
//   // headers: {
//   //   ['X-Authorization']: `${localStorage.getItem('session')}`
//   // }
// })
export const createChannel = (
  payload: CreateChannelPayload
): Promise<AxiosResponse<TypedChannel, CreateChannelPayload>> => {
  const url = 'channel/create'
  return axios.post(url, {
    ...payload,
    avatar: payload.avatar
  })
}

export const updateChannel = (
  payload: Partial<CreateChannelPayload> & {
    _id: string
  }
): Promise<
  AxiosResponse<
    TypedChannel,
    Partial<CreateChannelPayload> & {
      _id: string
    }
  >
> => {
  const url = 'channel/update'
  return axios.patch(url, payload)
}

export const getDraft = (): Promise<AxiosResponse<TypedChannel>> => {
  const url = 'channel/channel-trash'
  return axios.get(url)
}

export const getPlans = async (): Promise<TypedPlan[]> => {
  const url = process.env.REACT_APP_AJAX_URL + '/plan/channel-n-extension?handle=channel '
  const res = await axios.get(url)
  return res.data
}

export const checkCreateChannel = (): Promise<
  AxiosResponse<{ isCreated: boolean; isGlobal: boolean }>
> => {
  const url = 'channel/create-check'
  return axios.get(url)
}

export const checkCreateDomain = (subdomain: string) => {
  const url = `https://dev.api.gamifa.appuni.io/channel/check-sub-domain?subdomain=${subdomain}`
  return axios.get(url)
}

export async function checkDomainBeCreated(domain:string){
  return await axios.get(`channel/create_subdomain_check?subdomain=${domain}`)
}

export async function getOrderByID(orderId:string): Promise<AxiosResponse<Order>>{
  return await axios.get<Order>(`order/detail-order/${orderId}`)
}
