import { SvgIcon, SvgIconProps } from '@mui/material'

const DocumentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0322 3.69239C17.7488 3.66924 17.3766 3.66846 16.8 3.66846L12 3.66846V8.59153C12 9.73858 11.0701 10.6685 9.92308 10.6685H5L5 19.4685C5 20.045 5.00078 20.4173 5.02393 20.7006C5.04612 20.9723 5.0838 21.073 5.109 21.1224C5.20487 21.3106 5.35785 21.4636 5.54601 21.5595C5.59545 21.5847 5.69617 21.6223 5.96784 21.6445C6.25118 21.6677 6.62346 21.6685 7.2 21.6685H16.8C17.3766 21.6685 17.7488 21.6677 18.0322 21.6445C18.3038 21.6223 18.4045 21.5847 18.454 21.5595C18.6422 21.4636 18.7951 21.3106 18.891 21.1224C18.9162 21.073 18.9539 20.9723 18.9761 20.7006C18.9992 20.4173 19 20.045 19 19.4685V5.86846C19 5.29191 18.9992 4.91963 18.9761 4.6363C18.9539 4.36463 18.9162 4.26391 18.891 4.21447C18.7951 4.02631 18.6422 3.87333 18.454 3.77745C18.4045 3.75226 18.3038 3.71458 18.0322 3.69239ZM6.41418 8.66846H9.92308C9.96556 8.66846 10 8.63402 10 8.59153V5.08266L6.41418 8.66846ZM19.362 1.99544C19.9265 2.28306 20.3854 2.742 20.673 3.30649C20.8658 3.68487 20.9371 4.07809 20.9694 4.47343C21 4.848 21 5.30276 21 5.8299V19.507C21 20.0342 21 20.4889 20.9694 20.8635C20.9371 21.2588 20.8658 21.652 20.673 22.0304C20.3854 22.5949 19.9265 23.0539 19.362 23.3415C18.9836 23.5343 18.5904 23.6056 18.195 23.6379C17.8205 23.6685 17.3657 23.6685 16.8386 23.6685H7.16144C6.63431 23.6685 6.17955 23.6685 5.80498 23.6379C5.40964 23.6056 5.01641 23.5343 4.63803 23.3415C4.07354 23.0539 3.6146 22.5949 3.32698 22.0304C3.13419 21.6521 3.06287 21.2588 3.03057 20.8635C2.99997 20.4889 2.99998 20.0342 3 19.507L3 10.4968C3 10.064 3.09357 9.64075 3.26943 9.25428C3.41685 8.93031 3.62216 8.63204 3.87869 8.37551L9.70711 2.54713C9.96362 2.29062 10.2619 2.08532 10.5858 1.93791C10.9723 1.76203 11.3956 1.66846 11.8284 1.66846L16.8386 1.66846C17.3657 1.66844 17.8205 1.66842 18.195 1.69903C18.5904 1.73133 18.9836 1.80264 19.362 1.99544Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default DocumentIcon
