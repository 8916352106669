import helpers from 'helpers/index';
import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';

export const socket = io( process.env.REACT_APP_SOCKET_IO_URL, {
  withCredentials: true,
  extraHeaders: {
    "X-Authorization": localStorage.getItem('session'),
  },
  autoConnect:false,
  timeout: 10000
});
