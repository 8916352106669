//Library
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {AxiosError, AxiosResponse} from 'axios'
import {useField, useForm} from '@shopify/react-form'
import { Form} from 'react-bootstrap'

//Components

//Image

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import { getDataChannelSetting, updateChannel } from '../store/channelSetting.store.reduce.ts'
import {getDefaultChannel, setChannelData} from 'store/user.store.reducer'

//Interface

//Scss
import '../media/channel.setting.overview.scss'
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import __ from "languages/index";
import TextField from "components/TextField";
import { Button } from '@mui/material'

const ChannelSettingAdvance = () => {
  const dispatch = useAppDispatch()
  const desRef = useRef(null)
  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataChannel, setDataChannel] = useState<any>()
  const toast = useToast()

  const {fields, dirty, submitting, submit} = useForm({
    fields: {
      firebase_notification_key: useField<string>({
        value: '',
        validates: []
      }),
      permalink_tos: useField<string>({
        value: '',
        validates: [
          inputVal => {
            let regexLink = new RegExp(/^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i)
            if (inputVal && !regexLink.test(inputVal)) {
              return `${__("user_setting_error_link")}`
            }
          }
        ]
      }),
      google_login_client_id: useField<string>({
        value: '',
        validates: []
      }),
      facebook_login_client_id: useField<string>({
        value: '',
        validates: []
      }),
      smtp_server_address: useField<string>({
        value: '',
        validates: []
      }),
      smtp_server_port: useField<string>({
        value: '',
        validates: []
      }),
      smtp_server_username: useField<string>({
        value: '',
        validates: []
      }),
      smtp_server_password: useField<string>({
        value: '',
        validates: []
      }),
      smtp_server_secure_protocol: useField<string>({
        value: '',
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          _id: channel_data?._id,
          firebase_notification_key: values?.firebase_notification_key?.trim(),
          permalink_tos: values?.permalink_tos?.trim(),
          facebook_login_client_id: values?.facebook_login_client_id?.trim(),
          google_login_client_id: values?.google_login_client_id?.trim(),
          smtp_server_address: values?.smtp_server_address?.trim(),
          smtp_server_port: values?.smtp_server_port?.trim(),
          smtp_server_username: values?.smtp_server_username?.trim(),
          smtp_server_password: values?.smtp_server_password?.trim(),
          smtp_server_secure_protocol: values?.smtp_server_secure_protocol?.trim(),
        }
        setIsLoading(true)
        dispatch(updateChannel({...formData}))
          .unwrap()
          .then((res: AxiosResponse) => {
            setDataChannel(res?.data)
            dispatch(setChannelData(res?.data))
            dispatch(getDefaultChannel());
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_info_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateSettingChannel_${error}`)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_info_failed")}`,
              type: EnumTypeToast.Error
            })
          })
        return {status: 'success'}
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return {status: 'fail', errors: [{field, message}]}
      }
    }
  })


  const handleSetHeightTextArea = useCallback(
    (ref: React.RefObject<HTMLTextAreaElement>) => {
      const textarea = ref?.current
      if (textarea) {
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    },
    [dataChannel, fields]
  )

  useEffect(() => {
      dispatch(getDataChannelSetting())
        .unwrap()
        .then((res: AxiosResponse) => {
          setDataChannel(res?.data?.[0])
        })
        .catch((error: AxiosError) => {
          console.log(`getDataChannel_${error}`)
        })
  }, [])

  useEffect(() => {
    fields.firebase_notification_key.onChange(dataChannel?.firebase_notification_key)
    fields.permalink_tos.onChange(dataChannel?.permalink_tos)
    fields.facebook_login_client_id.onChange(dataChannel?.facebook_login_client_id)
    fields.google_login_client_id.onChange(dataChannel?.google_login_client_id)
    fields.smtp_server_address.onChange(dataChannel?.smtp_server_address)
    fields.smtp_server_port.onChange(dataChannel?.smtp_server_port)
    fields.smtp_server_username.onChange(dataChannel?.smtp_server_username)
    fields.smtp_server_password.onChange(dataChannel?.smtp_server_password)
    fields.smtp_server_secure_protocol.onChange(dataChannel?.smtp_server_secure_protocol || "TLS")
  }, [dataChannel])

  return (
    <div id="channelSettingOverview" className="channelSettingOverview_container">
      <Form onSubmit={submit} className="channelSettingOverview_body_wrapper">
        <h5 className="channelSettingOverview_header_title">{__("channel_setting_advance_firebase")}</h5>
        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_overview_firebase_key")}</label>
          <textarea
            value={fields.firebase_notification_key.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.firebase_notification_key.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_overview_firebase_key_placeholder")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>

        <h5 className="channelSettingOverview_header_title">{__("channel_setting_advance_tos")}</h5>
        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_tos_url")}</label>
          <textarea
            value={fields.permalink_tos.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.permalink_tos.onChange(e.target.value)
            }
            name={"permalink_tos"}
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_tos_url")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
            onBlur={() => {
              fields.permalink_tos?.runValidation(fields.permalink_tos.value)
            }}
            onFocus={() => fields.permalink_tos?.setError('')}
          ></textarea>
          {fields.permalink_tos?.error && (
            <span className="form-text text-danger">{fields.permalink_tos?.error}</span>
          )}
        </div>

        {/*Config SSO*/}
        <h5 className="channelSettingOverview_header_title">{__("channel_setting_advance_SSO")}</h5>
        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_advance_facebook_js_url")}</label>
          <TextField value={window.location.origin} disabled />
        </div>
        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_advance_facebook_callback_url")}</label>
          <TextField value={window.location.origin+"/login/facebook"} disabled />
        </div>

        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_advance_facebook_client_id")}</label>
          <textarea
            value={fields.facebook_login_client_id.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.facebook_login_client_id.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_advance_facebook_client_id")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>

        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_advance_google_origin_url")}</label>
          <TextField value={window.location.origin} disabled />
        </div>
        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_advance_google_callback_url")}</label>
          <TextField value={window.location.origin+"/login/google"} disabled />
        </div>

        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_advance_google_client_id")}</label>
          <textarea
            value={fields.google_login_client_id.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.google_login_client_id.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_advance_google_client_id")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>


        {/*config email*/}
        <h5 className="channelSettingOverview_header_title">{__("channel_setting_advance_smtp")}</h5>
        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_smtp_server_address")}</label>
          <textarea
            value={fields.smtp_server_address.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.smtp_server_address.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_smtp_server_address")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>

        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_smtp_server_port")}</label>
          <textarea
            value={fields.smtp_server_port.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.smtp_server_port.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_smtp_server_port")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>

        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_smtp_server_username")}</label>
          <textarea
            value={fields.smtp_server_username.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.smtp_server_username.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_smtp_server_username")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>

        <div className="channelSettingOverview_description">
          <label className="channelSettingOverview_label">{__("channel_setting_smtp_server_password")}</label>
          <textarea
            value={fields.smtp_server_password.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              fields.smtp_server_password.onChange(e.target.value)
            }
            ref={desRef}
            onInput={() => handleSetHeightTextArea(desRef)}
            placeholder={__("channel_setting_smtp_server_password")}
            maxLength={1000}
            className="channelSettingOverview_description_textArea form-control"
          ></textarea>
        </div>

        <div className="channelSettingOverview_description">
          <label className="fw-bold mb-1">{__("channel_setting_smtp_secure_protocol")}</label>
          <Form.Select
            name="bank_name"
            value={fields?.smtp_server_secure_protocol.value}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const { value } = e.target
              fields.smtp_server_secure_protocol.onChange(value)
            }}
            className={`form-select`}
            aria-label="TLS"
          >
            {["TLS","SSL"].map((item) => (
              <option
                key={`option_${item}`}
                value={item}
              >{item}</option>
            ))}
          </Form.Select>
        </div>

        <div className="channelSettingOverview_button_update">
          <Button sx={{textTransform: 'none'}}
            disabled={!dirty || submitting || isLoading}
            onClick={submit}
          >
            {submitting || isLoading ? `${__("btn_processing")}` : `${__("btn_update")}`}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ChannelSettingAdvance
