export function setItemToLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getItemFromLocalStorage(key: string) {
  try {
    const value = localStorage.getItem(key)
    return JSON.parse(value)
  } catch (err) {
    throw err
  }
}

export const deleteItemFromLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}
