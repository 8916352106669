import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ChevronIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || '21'}
        height={props.height || '21'}
        viewBox={`0 0 ${props.width || '21'} ${props.height || '21'}`}
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.81293 8.68915C5.13836 8.36371 5.666 8.36371 5.99144 8.68915L10.4022 13.0999L14.8129 8.68915C15.1384 8.36371 15.666 8.36371 15.9914 8.68915C16.3169 9.01458 16.3169 9.54222 15.9914 9.86766L11.5807 14.2784C10.9298 14.9293 9.87454 14.9293 9.22367 14.2784L4.81293 9.86766C4.48749 9.54222 4.48749 9.01458 4.81293 8.68915Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default ChevronIcon
