import { Components } from '@mui/material'
import { CurrentTheme } from 'styles/themes'

export const defaultPaper: Components<CurrentTheme>['MuiPaper'] = {
  defaultProps:{
    elevation:1,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(1.5)
    }),
    elevation1: ({ theme }) => ({
      boxShadow: theme.shadows[0]
    }),
    elevation2: ({ theme }) => ({
      boxShadow: theme.shadows[1]
    }),
    elevation3: ({ theme }) => ({
      boxShadow: theme.shadows[2]
    }),
    elevation4: ({ theme }) => ({
      boxShadow: theme.shadows[3]
    }),
    elevation5: ({ theme }) => ({
      boxShadow: theme.shadows[4]
    }),
    elevation6: ({ theme }) => ({
      boxShadow: theme.shadows[5]
    }),
    elevation7: ({ theme }) => ({
      boxShadow: theme.shadows[6]
    }),
    elevation8: ({ theme }) => ({
      boxShadow: theme.shadows[7]
    })
  }
}
