//Library
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {AxiosError, AxiosResponse} from 'axios'
import {useInfinite} from 'hooks/useInfinite'

//Components
import ChannelModalConfirm from './channel.modal.confirm'
import AddCategory from './channel.setting.add.category'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getDataCategory, updateCategory} from '../store/channelSetting.store.reduce.ts'

//Interface
import {TypeCategory} from '../interface/index'

//Helpers
import helpers from 'helpers'

//Scss
import '../media/channel.setting.category.scss'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { Button } from '@mui/material'

const ChannelSettingCategory = () => {
  const dispatch = useAppDispatch()
  const channel_data = useAppSelector(state => state.user.channel_data)

  const [page, setPage] = useState<number>(1)
  const [dataCategory, setDataCategory] = useState<TypeCategory[]>([])
  const [totalDataCategory, setTotalDataCategory] = useState<number>(0)
  const [isShowConfirmDeleteCategory, setIsShowConfirmDeleteCategory] = useState<boolean>(false)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [isShowAdd, setIsShowAdd] = useState<string>('')
  const [dataCategorySelect, setDataCategorySelect] = useState<TypeCategory>()
  const toast = useToast()

  const params = useMemo(() => {
    return {
      page: page,
      limit: 20,
      order_by: 'DESC',
      public_status: 1,
      // channel_id: channel_data?._id,
      isRefresh: isRefresh
    }
  }, [page, isRefresh])

  const { ref } = useInfinite(dataCategory.length < totalDataCategory, () => {
    setPage(page => page + 1)
  })

  const handleSubmitDeleteCategory = useCallback(
    (result: boolean, args: { _id?: string }) => {
      setIsShowConfirmDeleteCategory(false)
      if (result) {
        const formData = {
          _id: args?._id,
          public_status: '0',
          channel_id: channel_data?._id
        }

        dispatch(updateCategory(formData))
          .unwrap()
          .then(() => {
            setIsRefresh(!isRefresh)
            toast.show({
              content: `${__("channel_setting_delete_category_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateCategory_${error}`)
            toast.show({
              content: `${__("channel_setting_delete_category_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }
    },
    [channel_data, isRefresh]
  )

  useEffect(() => {
    dispatch(getDataCategory(params))
      .unwrap()
      .then((res: AxiosResponse<TypeCategory[]>) => {
        setDataCategory(dataCategory => [...dataCategory, ...res?.data])
        setTotalDataCategory(parseInt(res.headers['x-total-count'] || 10, 10))
      })
      .catch((error: AxiosError) => {
        console.log(`getDataCategory_${error}`)
        setDataCategory([])
        setTotalDataCategory(0)
      })
  }, [params])

  useEffect(() => {
    setDataCategory([])
    setPage(1)
  }, [isRefresh])

  return (
    <div id="channelSettingCategory">
      {isShowAdd ? (
        <AddCategory
          isShowAdd={isShowAdd}
          dataCategorySelect={dataCategorySelect}
          setIsShowAdd={setIsShowAdd}
          isRefresh={isRefresh}
          setIsRefresh={setIsRefresh}
        />
      ) : (
        <>
          <div className="channelSettingCategory_header">
            <h4 className="channelSettingCategory_header_title">{__("channel_setting_category")}</h4>
            <Button sx={{textTransform: 'none'}} onClick={() => setIsShowAdd('ADD')}>{__("channel_setting_add_category")}</Button>
          </div>
          <div className="channelSettingCategory_body_list">
            {!helpers.isEmpty(dataCategory) &&
              dataCategory.map((item: TypeCategory, idx: number) => (
                <div
                  ref={ref}
                  key={`category_${idx}`}
                  className="channelSettingCategory_body_list_item"
                >
                  <div className="channelSettingCategory_body_item_info">
                    <span className="">{item?.category_title || ''}</span>
                    <span className="">{item?.category_content || ''}</span>
                  </div>
                  <div className="channelSettingCategory_body_item_button">
                    <Button variant='text' sx={{textTransform: 'none'}}
                      onClick={() => {
                        setIsShowConfirmDeleteCategory(true)
                        setDataCategorySelect(item)
                      }}
                      className="text-decoration-underline"
                    >
                      {__("channel_setting_remove")}
                    </Button>
                    <Button
                      sx={{textTransform: 'none'}}
                      onClick={() => {
                        setIsShowAdd('EDIT')
                        setDataCategorySelect(item)
                      }}
                    >
                      {__("channel_setting_edit")}
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      {isShowConfirmDeleteCategory && (
        <ChannelModalConfirm
          show={isShowConfirmDeleteCategory}
          onClose={handleSubmitDeleteCategory}
          title={__("channel_setting_modal_confirm_title")}
          {...{ _id: dataCategorySelect?._id }}
        >
          <p>{__("channel_setting_modal_confirm_text")}</p>
        </ChannelModalConfirm>
      )}
    </div>
  )
}

export default ChannelSettingCategory
