import __ from 'languages/index'
import ChannelIcon from '../media/icons/ChannelIcon.svg'

export const TypeSortMission = {
  CURRENT_MISSION: 'CURRENT_MISSION',
  FUTURE_MISSION: 'FUTURE_MISSION',
  PAST_MISSION: 'PAST_MISSION'
}

export const ChannelInformation = {
  icon: ChannelIcon,
  label: __('blessed_germ')
}
