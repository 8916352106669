import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.99994C12.5523 2.99994 13 3.44765 13 3.99994V10.9999H20C20.5523 10.9999 21 11.4477 21 11.9999C21 12.5522 20.5523 12.9999 20 12.9999H13V19.9999C13 20.5522 12.5523 20.9999 12 20.9999C11.4477 20.9999 11 20.5522 11 19.9999V12.9999H4C3.44772 12.9999 3 12.5522 3 11.9999C3 11.4477 3.44772 10.9999 4 10.9999H11V3.99994C11 3.44765 11.4477 2.99994 12 2.99994Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default PlusIcon
