import { SvgIcon, SvgIconProps } from '@mui/material'

const LinkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3644 5.636C17.1928 4.46443 15.2933 4.46443 14.1218 5.636L12.7076 7.05022C12.317 7.44074 11.6839 7.44074 11.2933 7.05022C10.9028 6.65969 10.9028 6.02653 11.2933 5.636L12.7076 4.22179C14.6602 2.26917 17.826 2.26917 19.7786 4.22179C21.7312 6.17441 21.7312 9.34024 19.7786 11.2929L18.3644 12.7071C17.9739 13.0976 17.3407 13.0976 16.9502 12.7071C16.5597 12.3165 16.5597 11.6834 16.9502 11.2929L18.3644 9.87864C19.536 8.70707 19.536 6.80758 18.3644 5.636ZM15.5362 8.46462C15.9267 8.85514 15.9267 9.48831 15.5362 9.87883L9.87933 15.5357C9.48881 15.9262 8.85565 15.9262 8.46512 15.5357C8.0746 15.1452 8.0746 14.512 8.46512 14.1215L14.122 8.46462C14.5125 8.0741 15.1457 8.0741 15.5362 8.46462ZM7.05071 11.2929C7.44123 11.6834 7.44123 12.3165 7.05071 12.7071L5.63649 14.1213C4.46492 15.2929 4.46492 17.1924 5.63649 18.3639C6.80806 19.5355 8.70756 19.5355 9.87913 18.3639L11.2933 16.9497C11.6839 16.5592 12.317 16.5592 12.7076 16.9497C13.0981 17.3402 13.0981 17.9734 12.7076 18.3639L11.2933 19.7781C9.34072 21.7308 6.1749 21.7308 4.22228 19.7781C2.26966 17.8255 2.26966 14.6597 4.22228 12.7071L5.63649 11.2929C6.02702 10.9023 6.66018 10.9023 7.05071 11.2929Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default LinkIcon
