import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ManagerAvatarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="currentColor"
      >
        <path
          d="M9.89909 1.4791C9.30219 1.40662 8.69872 1.40662 8.10183 1.4791C7.50493 1.55157 6.919 1.69599 6.35679 1.90921C5.79459 2.12243 5.26024 2.40287 4.7654 2.74444C4.27056 3.086 3.81886 3.48618 3.42014 3.93624C3.02141 4.3863 2.6786 4.88295 2.39918 5.41535C2.11975 5.94776 1.90576 6.51201 1.76186 7.09582C1.61796 7.67963 1.54522 8.2787 1.54522 8.87997C1.54522 9.48125 1.61796 10.0803 1.76186 10.6641C1.90575 11.2479 2.11975 11.8122 2.39918 12.3446C2.6786 12.877 3.02141 13.3736 3.42013 13.8237C3.81886 14.2738 4.27056 14.6739 4.7654 15.0155C5.26025 15.3571 5.79459 15.6375 6.35679 15.8507C6.919 16.064 7.50493 16.2084 8.10183 16.2808C8.69872 16.3533 9.30219 16.3533 9.89908 16.2808C10.496 16.2084 11.0819 16.064 11.6441 15.8507C12.2063 15.6375 12.7407 15.3571 13.2355 15.0155C13.7304 14.6739 14.1821 14.2738 14.5808 13.8237C14.9795 13.3736 15.3223 12.877 15.6017 12.3446C15.8812 11.8122 16.0952 11.2479 16.2391 10.6641C16.3829 10.0803 16.4557 9.48125 16.4557 8.87997C16.4557 8.2787 16.3829 7.67962 16.2391 7.09582C16.0952 6.51201 15.8812 5.94776 15.6017 5.41535C15.3223 4.88295 14.9795 4.3863 14.5808 3.93624C14.1821 3.48618 13.7304 3.086 13.2355 2.74444C12.7407 2.40287 12.2063 2.12243 11.6441 1.90921C11.0819 1.69599 10.496 1.55157 9.89909 1.4791Z"
          fill="url(#paint0_linear_209_2960)"
          stroke="white"
        />
        <path
          d="M9.00042 13.7789C11.706 13.7789 13.8993 11.5856 13.8993 8.88C13.8993 6.17443 11.706 3.98114 9.00042 3.98114C6.29486 3.98114 4.10156 6.17443 4.10156 8.88C4.10156 11.5856 6.29486 13.7789 9.00042 13.7789Z"
          fill="url(#paint1_linear_209_2960)"
        />
        <path
          d="M9.64421 6.28683L10.2357 7.41642L11.493 7.63023C12.0711 7.72852 12.301 8.43604 11.8912 8.85533L10.9998 9.76722L11.1853 11.029C11.2703 11.6093 10.6688 12.0464 10.1435 11.7859L9.00111 11.22L7.85875 11.7859C7.33339 12.0464 6.73139 11.6093 6.81691 11.029L7.0024 9.76722L6.11106 8.85533C5.70121 8.43604 5.93112 7.72852 6.50924 7.63023L7.76656 7.41642L8.35801 6.28683C8.63013 5.76702 9.37375 5.76702 9.64587 6.28683H9.64421Z"
          fill="url(#paint2_linear_209_2960)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_209_2960"
            x1="1.99414"
            y1="15.8863"
            x2="1.99413"
            y2="2.2558"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B7041E" />
            <stop offset="1" stopColor="#E14352" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_209_2960"
            x1="9.00042"
            y1="13.7789"
            x2="9.00042"
            y2="3.98114"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8D7DA" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_209_2960"
            x1="5.90332"
            y1="15.6418"
            x2="5.90331"
            y2="2.05508"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
