import { SvgIcon, SvgIconProps } from '@mui/material'

const IndeterminateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        stroke="currentColor"
      >
        <path
          d="M0.350586 4C0.350586 1.79086 2.14145 0 4.35059 0H16.3506C18.5597 0 20.3506 1.79086 20.3506 4V16C20.3506 18.2091 18.5597 20 16.3506 20H4.35059C2.14145 20 0.350586 18.2091 0.350586 16V4Z"
          fill="#DC3545"
        />
        <rect x="5.85059" y="9.2417" width="9" height="1.5166" rx="0.3" fill="white" />
      </svg>
    </SvgIcon>
  )
}

export default IndeterminateIcon
