//Library
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError, AxiosResponse } from 'axios'
import { faLink } from '@fortawesome/free-solid-svg-icons'

//Components
import TextField from 'components/TextField'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { invite } from '../store/channelSetting.store.reduce.ts'

//Scss
import '../media/channel.setting.invite.scss'
import { ConfirmModal } from 'components/modalConfirm'
import { getListMembers } from 'entities/channel/store/channel.store.reducer'
import __ from 'languages/index'
import {EnumTypeToast, useToast} from '../../../hooks/useToast'
import { Button } from '@mui/material'

const ChannelSettingInvite = () => {
  const dispatch = useAppDispatch()
  const channel_data = useAppSelector(state => state.user.channel_data)

  const [email, setEmail] = useState<string>('')
  // const [totalMember, setTotalMember] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShow, setIsShow] = useState<boolean>(false)
  const refConfirmModalSetting = useRef<any>()
  const toast = useToast()

  const service_type = 'premium'

  // useEffect( () => {
  //   try {
  //       dispatch(getListMembers({
  //         user_id: channel_data?.user_id?._id
  //       }))
  //       .unwrap()
  //       .then((response: AxiosResponse) => {
  //         if (Array.isArray(response?.data)) {
  //           if(response?.data?.length){
  //             let totalItems = response?.headers["x-total-count"]
  //             setTotalMember(totalItems)
  //           }
  //         }
  //       })

  //   }catch (e) {
  //     console.log('error_channel_setting', e)
  //   }

  // }, [channel_data?.user_id?._id]);


  const handleCopyLink = useCallback(() => {
    navigator.clipboard
      .writeText(`https://${window.location.hostname || `/channel/join/${channel_data?._id}`}`)
      .then(
        function () {
          toast.show({
            content: `${__("copy_link_success")}`,
            type: EnumTypeToast.Success
          })
        },
        function (err) {
          console.error('Async: Could not copy text: ', err)
          toast.show({
            content: `${__("copy_link_failed")}`,
            type: EnumTypeToast.Error
          })
        }
      )
      .catch(()=>{})
  }, [channel_data])

  const handleChangeValue = useCallback((e: string) => {
    // if(totalMember > 250 &&  service_type !== 'premium'){
    //   setIsShowModal(true )
    // }
    setEmail(e)
  }, [])

  const checkFormatEmail = useMemo(() => {
    const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    return regex.test(email)
  }, [email])

  const handleInvite = useCallback(() => {
    // if(!service_type && totalMember == 250){

    // }
    setIsLoading(true)
    dispatch(invite({ channel_id: channel_data?._id, email: email }))
      .unwrap()
      .then(() => {
        setIsLoading(false)
        setEmail('')
        toast.show({
          content: `${__("channel_setting_invite_email_success")}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((error: AxiosError) => {
        setIsLoading(false)
        console.log(`invite_${error}`)
        toast.show({
          content: `${__("channel_setting_invite_email_failed")}`,
          type: EnumTypeToast.Error
        })
      })
  }, [channel_data, email])

  const handleClickBtnClose = useCallback(()=>setIsShow(true),[])

  return (
    <div id="channelSettingInvite" className="channelSettingInvite_container">
      {/*{!isShow && <PopupUpgrade text= {`còn 30 thành viên`} url='/upgrade' handleClickBtnClose={handleClickBtnClose}/>}*/}
      <h4 className="channelSettingInvite_header_title">{__("channel_setting_invite_user")}</h4>
      <div className="channelSettingInvite_body">
        <span className="channelSettingInvite_body_title">{`${__("channel_setting_invite_into_channel")} ${
          channel_data?.name || ''
        }`}</span>
        <TextField
          type="email"
          value={email || ''}
          onChange={handleChangeValue}
          autoFocus
          placeholder={__("channel_setting_invite_user_placeholder")}
        />
        <div>
          <Button
            sx={{textTransform: 'none'}}
            onClick={handleInvite}
            disabled={!checkFormatEmail}
            className="channelSettingInvite_body_button"
          >
            {isLoading ? `${__("btn_processing")}` : `${__("channel_setting_invite_send_invitation")}`}
          </Button>
        </div>
      </div>
      <div className="channelSettingInvite_footer">
        <div className="channelSettingInvite_footer_label">
          <FontAwesomeIcon icon={faLink} />
          <span>{__("channel_setting_invite_get_url")}</span>
        </div>
        <div className="channelSettingInvite_footer_button">
          <span className="channelSettingInvite_footer_button_title">
            {`https://${window.location.hostname || `/channel/join/${channel_data?._id}`}`}
          </span>
          <Button
            sx={{textTransform: 'none'}}
            onClick={handleCopyLink}
            className="channelSettingInvite_footer_button_text"
          >
            {__("channel_setting_invite_btn_copy")}
          </Button>
        </div>
      </div>
      <ConfirmModal ref={refConfirmModalSetting} />
    </div>
  )
}

export default memo(ChannelSettingInvite)
