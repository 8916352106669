import { SvgIcon, SvgIconProps } from '@mui/material'

const CupIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.43389 11.1005C5.25273 10.4885 5.1546 9.84054 5.15281 9.17004V5.68004H3.38281V8.10004C3.38281 9.4599 4.23278 10.6309 5.43389 11.1005ZM6.56493 13.3199C3.70232 13.2995 1.38281 10.9674 1.38281 8.10004V5.37004C1.38281 4.44004 2.14281 3.68004 3.07281 3.68004H5.15582C5.21342 2.63067 6.08971 1.79004 7.15281 1.79004H16.7028C17.7659 1.79004 18.6422 2.63067 18.6998 3.68004H20.8028C21.7328 3.68004 22.4928 4.44004 22.4928 5.37004V8.10004C22.4928 10.9781 20.156 13.3169 17.2787 13.32C16.2467 14.6615 14.7329 15.6057 13.0028 15.8846V17.22H14.6828C16.4728 17.22 17.9228 18.67 17.9228 20.46C17.9228 21.42 17.1428 22.21 16.1728 22.21H7.50281C6.54281 22.21 5.75281 21.43 5.75281 20.46C5.75281 18.67 7.20281 17.22 8.99281 17.22H11.0028V15.9171C9.20514 15.6692 7.62744 14.7055 6.56493 13.3199ZM18.7028 9.16004V5.68004H20.4928V8.10004C20.4928 9.47911 19.6284 10.6541 18.4113 11.1134C18.5975 10.4946 18.6992 9.83891 18.7028 9.16004ZM16.6928 3.79004H7.15281V9.12004C7.16281 11.81 9.30281 13.97 11.9228 13.97C14.5428 13.97 16.6828 11.81 16.6928 9.15004V3.79004ZM7.79281 20.21H15.9028C15.7828 19.65 15.2828 19.22 14.6928 19.22H9.00281C8.40281 19.22 7.90281 19.64 7.79281 20.21Z"
        />
      </svg>
    </SvgIcon>
  )
}
export default CupIcon
