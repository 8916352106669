import { SvgIcon, SvgIconProps } from '@mui/material'

const CameraIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.35 4.1C20.35 4.5 21.95 6.1 22.35 8.1C22.4073 8.38669 22.4318 8.67338 22.461 9.01663C22.4828 9.27202 22.5073 9.55871 22.55 9.9V14.5C22.55 15.6 22.55 16.5 22.45 17.2C22.35 18 22.25 18.6 21.95 19.2C21.45 20.2 20.65 20.9 19.75 21.4C19.15 21.7 18.45 21.8 17.75 21.9C17.05 22 16.15 22 15.05 22H9.84995H7.14995C6.34995 21.9 5.74995 21.8 5.14995 21.5C4.24995 21 3.44995 20.2 3.04995 19.3C2.74995 18.7 2.64995 18 2.54995 17.3C2.44995 16.6 2.44995 15.7 2.44995 14.6V9.9C2.44995 9.1 2.44995 8.6 2.54995 8.1C2.94995 6.1 4.54995 4.5 6.54995 4.1C6.94995 4 7.34995 4 7.84995 4C8.04995 3.6 8.24995 3.2 8.54995 2.9C8.94995 2.5 9.24995 2.3 9.74995 2.2C10.25 2 10.85 2 11.45 2H13.45C14.15 2 14.65 2 15.25 2.2C15.65 2.4 16.05 2.6 16.35 2.9C16.65 3.2 16.85 3.6 17.05 4C17.55 4 17.95 4 18.35 4.1ZM20.35 17.1C20.45 16.5 20.45 15.7 20.45 14.6H20.35V9.9V8.4C20.15 7.2 19.15 6.2 17.95 6H16.45H16.35C16.05 6 15.75 5.8 15.65 5.5C15.55 5.5 15.55 5.4 15.55 5.4L15.45 5.2C15.15 4.5 15.05 4.4 14.95 4.3C14.85 4.2 14.65 4.1 14.55 4.1C14.35 4 14.25 4 13.45 4H11.45C10.65 4 10.45 4 10.35 4.1C10.15 4.1 10.05 4.2 9.94995 4.3C9.89995 4.35 9.87495 4.4 9.81245 4.525C9.74995 4.65 9.64995 4.85 9.44995 5.2V5.4C9.34995 5.4 9.34995 5.5 9.34995 5.5C9.14995 5.8 8.84995 6 8.54995 6H8.34995H6.94995C5.64995 6.3 4.74995 7.2 4.44995 8.5V9.9V14.6C4.44995 15.7 4.44995 16.5 4.54995 17.1C4.64995 17.7 4.74995 18 4.84995 18.3C5.14995 18.9 5.54995 19.3 6.14995 19.6C6.34995 19.8 6.74995 19.9 7.34995 19.9C7.94995 20 8.74995 20 9.84995 20H15.05C16.15 20 16.95 20 17.55 19.9C18.15 19.8 18.45 19.7 18.75 19.6C19.35 19.3 19.75 18.9 20.05 18.3C20.25 18.1 20.35 17.7 20.35 17.1ZM7.44995 13C7.44995 10.2 9.64995 8 12.45 8C15.25 8 17.45 10.2 17.45 13C17.45 15.8 15.25 18 12.45 18C9.64995 18 7.44995 15.8 7.44995 13ZM9.44995 13C9.44995 14.7 10.75 16 12.45 16C14.15 16 15.45 14.7 15.45 13C15.45 11.3 14.15 10 12.45 10C10.75 10 9.44995 11.3 9.44995 13Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default CameraIcon
