import { lazy } from 'react'
import ChannelSetting from 'entities/channelSetting'

/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
const AddDomain = lazy(() => import('entities/domain'))
const IntroDomainService = lazy(() => import('entities/domain/page/intro.page'))
const UpgradeAccount = lazy(() => import('entities/upgaradeAccount'))
const FamilyTree = lazy(() => import('../entities/ReferrerTree'))
const Shop = lazy(() => import('entities/shop'))
const ReferralCode = lazy(() => import('layout/referralCode'))
const UpdateInformation = lazy(() => import('layout/updateInformation'))
const TermsOfUse = lazy(() => import('layout/termsOfUse'))
const PrivacyPolicyComponent = lazy(() => import('layout/privacyPolicy'))
const Referral = lazy(() => import('entities/referral'))
const Revenue = lazy(() => import('entities/revenue'))
const MyWallet = lazy(() => import('entities/myWallet'))
const BusinessCooperationLevel = lazy(() => import('layout/BusinessCooperationLevel'))
const PaymentSuccessForMobile = lazy(() => import('layout/paymentSuccessForMobile'))
const Example = lazy(() => import('entities/example'))
const Courses = lazy(() => import('entities/courses'))
const Calendar = lazy(() => import('entities/calendar'))
const CoursesStudy = lazy(() => import('entities/courses/courses.study'))
const Extensions = lazy(() => import('entities/extentions'))
const Event = lazy(() => import('entities/event'))
const Gift = lazy(() => import('entities/gift'))
const Challenge = lazy(() => import('entities/challenge'))
const Broadcast = lazy(() => import('entities/broadcast'))
const Mentor = lazy(() => import('entities/mentor'))
const InfoPayment = lazy(() => import('entities/infoPayment'))
const Order = lazy(() => import('entities/orders'))
const Contact = lazy(() => import('layout/contact'))
const Mission = lazy(() => import('entities/mission'))
const Support = lazy(() => import('entities/support'))
const CreateTicket = lazy(() => import('entities/support/page/CreateTicket'))
const DetailTicket = lazy(() => import('entities/support/page/DetailTicket'))
const DetailMission = lazy(() => import('entities/mission/mission.detail'))
const SuccessfulScreen = lazy(() => import('layout/SuccessfulScreen'))
const ApprovalPost = lazy(() => import('entities/approvalPost'))
const User = lazy(() => import('entities/user'))
const About = lazy(() => import('entities/about'))
const Notification = lazy(() => import('entities/notification'))
const Channel = lazy(() => import('entities/channel'))
const Member = lazy(() => import('entities/member'))
const Podcast = lazy(() => import('../podcast'))
const DetailPodcast = lazy(() => import('../podcast/pages/DetailPodcast.page'))
const CreateNewPodcast = lazy(() => import('../podcast/pages/CreateNewPodcast.page'))
const LeaderBoard = lazy(() => import('entities/leaderBoard'))
const ValidateCode = lazy(() => import('entities/validateCode'))
const SearchScreen = lazy(() => import('entities/search'))
const LoginPage = lazy(() => import('layout/login'))
const CourseManageUser = lazy(() => import('entities/courses/course.manage.user'))
const GiftDetail = lazy(() => import('entities/gift/GiftDetail.page'))
const RegisterPage = lazy(() => import('layout/register'))
const Demo = lazy(() => import('entities/base'))
const ForgotPassword = lazy(() => import('entities/forgotPassword'))
const ChatPage = lazy(() => import('entities/chat'))
const Auth = lazy(() => import('layout/component/auth'))
const HomeNew = lazy(() => import('entities/homeNew'))
const HomeDetailPost = lazy(() => import('entities/homeNew/home.detail.post'))
const Print = lazy(() => import('entities/print'))
const News = lazy(() => import('entities/news'))
const LoginWithFacebook = lazy(() => import('layout/component/login.facebook'))
const LoginWithGoogle = lazy(() => import('layout/component/login.google'))
const Payment = lazy(() => import('entities/payment'))

/**
 * Any one can access this URL
 */
export const public_route = [
  {
    path: '/example',
    main: Example
  },
  {
    path: '/example/:slug',
    main: Example
  },
  {
    path: '/example/:slug/:example_id',
    main: Example
  },
  {
    path: '/courses/:slug/:courses_id/:module_id',
    main: Courses
  },
  {
    path: '/courses/:slug/:courses_id',
    main: Courses
  },
  {
    path: '/courses/:slug',
    main: Courses
  },
  {
    path: '/course/manage-user/:course_id',
    main: CourseManageUser
  },
  {
    path: '/courses',
    main: Courses
  },
  {
    path: '/user/:slug/:user_id',
    main: User
  },
  {
    path: '/user/:slug',
    main: User
  },
  {
    path: '/user',
    main: User
  },
  {
    path: '/member',
    main: Member
  },
  {
    path: '/channel-setting',
    main: ChannelSetting
  },
  {
    path: '/notifications',
    main: Notification
  },
  {
    path: '/leader-board',
    main: LeaderBoard
  },
  {
    path: '/mentor/:slug/:mentor_id',
    main: Mentor
  },
  {
    path: '/mentor',
    main: Mentor
  },
  {
    path: '/mentor/:slug',
    main: Mentor
  },
  {
    path: '/info-payment/:slug',
    main: InfoPayment
  },
  {
    path: '/info-payment',
    main: InfoPayment
  },
  {
    path: '/payment/:slug',
    main: Payment
  },
  {
    path: '/payment',
    main: Payment
  },

  {
    path: '/calendar',
    main: Calendar
  },
  {
    path: '/calendar/:slug',
    main: Calendar
  },
  {
    path: '/calendar/:slug/:calendar_id',
    main: Calendar
  },
  {
    path: '/search',
    main: SearchScreen
  },
  {
    path: '/about',
    main: About
  },
  {
    path: '/post/:slug',
    main: ApprovalPost
  },
  {
    path: '/event/:slug/:calendar_id',
    main: Event
  },
  {
    path: '/event/:slug',
    main: Event
  },
  {
    path: '/event',
    main: Event
  },
  {
    path: '/gift/:slug/:giftId',
    main: Gift
  },
  {
    path: '/gift/:slug',
    main: Gift
  },
  {
    path: '/gift',
    main: Gift
  },
  {
    path: '/broadcast',
    main: Broadcast
  },

  {
    path: '/challenge/:slug/:challenge_id',
    main: Challenge
  },
  {
    path: '/challenge/:slug',
    main: Challenge
  },
  {
    path: '/challenge',
    main: Challenge
  },

  {
    path: '/',
    main: HomeNew
  },
  {
    path: '/post/detail/:post_id',
    main: HomeDetailPost
  },
  {
    path: '/post/view/:post_id',
    main: HomeDetailPost
  },
  {
    path: '/orders',
    main: Order
  },
  {
    path: '/orders/:slug',
    main: Order
  },
  {
    path: '/orders/:slug/:order_id',
    main: Order
  },
  {
    path: '/extensions',
    main: Extensions
  },
  {
    path: '/extensions/:slug',
    main: Extensions
  },
  {
    path: '/extensions/:slug/:extension_id',
    main: Extensions
  },
  {
    path: '/missions',
    main: DetailMission
  },
  {
    path: '/missions/gift/:giftId',
    main: GiftDetail
  },
  {
    path: '/setting-mission',
    main: Mission
  },
  {
    path: '/setting-mission/:slug',
    main: Mission
  },
  {
    path: '/support',
    main: Support
  },
  {
    path: '/support/:id',
    main: DetailTicket
  },
  {
    path: '/support/create-ticket',
    main: CreateTicket
  },

  {
    path: '/domain/create',
    main: AddDomain
  },
  {
    path: '/domain/intro',
    main: IntroDomainService
  },
  {
    path: '/payment/success/:orderId',
    main: SuccessfulScreen
  },
  {
    path: '/upgrade/:slug',
    main: UpgradeAccount
  },
  {
    path: '/upgrade',
    main: UpgradeAccount
  },
  {
    path: '/podcast',
    main: Podcast
  },
  {
    path: '/podcast/:id',
    main: DetailPodcast
  },
  {
    path: '/podcast/create',
    main: CreateNewPodcast
  },
  {
    path: '/referrals',
    main: FamilyTree
  },
  {
    path: '/demo',
    main: Demo
  },
  {
    path: '/shop',
    main: Shop
  },
  {
    path: '/shop/:slug',
    main: Shop
  },
  {
    path: '/shop/:slug/:productId',
    main: Shop
  },
  {
    path: '/revenue',
    main: Revenue
  },
  {
    path: '/revenue/:slug',
    main: Revenue
  },
  {
    path: '/wallet',
    main: MyWallet
  },
  {
    path: '/referral',
    main: Referral
  },
  {
    path: '/referral/:slug',
    main: Referral
  },
  {
    path: '/news',
    main: News
  },
  {
    path: '/news/:slug',
    main: News
  },
  {
    path: '/news/:slug/:slugId',
    main: News
  }
]

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [
  {
    path: '/study/:course_id/:module_id',
    main: CoursesStudy
  },
  {
    path: '/study/:course_id',
    main: CoursesStudy
  },

  {
    path: '/auth',
    main: Auth
  },
  {
    path: '/login',
    main: LoginPage
  },
  {
    path: '/referral-code',
    main: ReferralCode
  },
  {
    path: '/login/facebook',
    main: LoginWithFacebook
  },
  {
    path: '/login/google',
    main: LoginWithGoogle
  },
  {
    path: '/register',
    main: RegisterPage
  },
  {
    path: '/business-level',
    main: BusinessCooperationLevel
  },
  {
    path: '/payment-success',
    main: PaymentSuccessForMobile
  },
  {
    path: '/update-information',
    main: UpdateInformation
  },
  {
    path: '/terms-of-use',
    main: TermsOfUse
  },
  {
    path: '/privacy-policy',
    main: PrivacyPolicyComponent
  },
  {
    path: '/contact',
    main: Contact
  },
  {
    path: '/validate-code',
    main: ValidateCode
  },
  {
    path: '/forgot-password',
    main: ForgotPassword
  },
  {
    path: '/forgot-password/:slug',
    main: ForgotPassword
  },
  {
    path: '/channel',
    main: Channel
  },
  {
    path: '/channel/:slug',
    main: Channel
  },
  {
    path: '/chat',
    main: ChatPage
  },
  {
    path: '/chat/:slug/:id_chat',
    main: ChatPage
  },
  {
    path: '/channel/:slug/:channel_id',
    main: Channel
  },
  {
    path: '/print/:slug/:id',
    main: Print
  }
]
