import React from 'react';
import MaxLayout from "../layouts/maxLayout";
import HeaderComponent from "../layouts/Header";

//SEO({title: "Not Found!", description: "Sorry we can not found this page!"});

function PageNotFound({includeHeader = false}) {
    return (
        <MaxLayout>
            { includeHeader && <HeaderComponent /> }
            <div className="d-flex align-items-center justify-content-center vh-100 bg-danger">
                <h1 className="display-1 fw-bold text-white">404</h1>
                <div className='d-block px-2'>
                    <div className="text-white">ohhhh noooooooooooooo</div>
                    <div className="text-white">Missing something?</div>
                </div>
            </div>
        </MaxLayout>
    );
}

export default PageNotFound;
