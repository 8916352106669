//Library
import axios from 'axios'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {createEntitySlice, IQueryParams, serializeAxiosError} from 'config/reducer.utils'

//helpers
import helpers from 'helpers'

//Interface
import {TypeDataMember, TypedChannelLevel, TypedMemberRanking} from '../interface'

export const initialState = {}

export const getListMemberRanking = createAsyncThunk(
  'leader_board/get_member_ranking',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<TypedMemberRanking[]>(`/channel/member-ranking`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getListLevel = createAsyncThunk(
  'leader_board/get_list_level',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<TypedChannelLevel[]>(`/channel/list-level`, {
        params: params
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const LEADER_BOARD_REDUCER = createEntitySlice({
  name: 'leader_board',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = LEADER_BOARD_REDUCER.actions

export default LEADER_BOARD_REDUCER.reducer
