import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Plan, Subscription } from '../add-domain.type'
import TypedChannel from 'interfaces/channel.interface'

type GetSubscriptionsResponse = Subscription[]

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL,
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    return headers
  },
  credentials: 'include'
})

const domainApi = createApi({
  baseQuery,
  reducerPath: 'domainApi',
  tagTypes: ['Domain'],
  refetchOnMountOrArgChange: 30,
  endpoints: build => ({
    createDomain: build.mutation<any, { id: string; domain: string }>({
      query({ id, domain }) {
        return {
          url: `/channel/check-create-domain/${id}`,
          method: 'POST',
          body: {
            domain
          }
        }
      }
    }),
    createFreeDomain: build.mutation<any, { channelId: string; domain: string }>({
      query({ channelId, domain }) {
        return {
          url: '/channel/update',
          method: 'PATCH',
          body: {
            _id: channelId,
            domain
          }
        }
      },
      invalidatesTags: ['Domain']
    }),

    getChannelInfo: build.query<TypedChannel, void>({
      query() {
        return {
          url: '/channel/me'
        }
      },
      providesTags: ['Domain']
    }),

    getSubscriptionsByDomain: build.query<Subscription, { id: string }>({
      query({ id }) {
        return {
          url: `/subscribe/user/${id}`,
          method: 'GET',
          params: {
            service_name: 'domain'
          }
        }
      },
      transformResponse: (response: GetSubscriptionsResponse, meta) => {
        return response[0] ?? undefined
      }
    }),

    getDomainPlan: build.query<Plan[], { service_name: string }>({
      query({ service_name }) {
        return {
          url: `/plan/list-plan`,
          params: {
            service_name,
            version: '1.0.1'
          }
        }
      }
    })
  })
})

export const {
  useCreateDomainMutation,
  useCreateFreeDomainMutation,
  useGetSubscriptionsByDomainQuery,
  useGetChannelInfoQuery,
  useGetDomainPlanQuery
} = domainApi

export default domainApi
