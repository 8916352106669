import { SvgIcon, SvgIconProps } from '@mui/material'

const UserCircleInformation = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="52"
        height="46"
        viewBox="0 0 52 46"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="30.0039" cy="22.875" r="22" fill="#626F82" />
        <path
          d="M30.0039 3.625C19.3814 3.625 10.7539 12.235 10.7539 22.875C10.7539 33.515 19.3814 42.125 30.0039 42.125C40.6264 42.125 49.2539 33.515 49.2539 22.875C49.2539 12.235 40.6439 3.625 30.0039 3.625ZM30.0039 14.125C33.2589 14.125 35.9189 16.7675 35.9189 20.04C35.9189 23.3125 33.2589 25.9375 30.0039 25.9375C26.7489 25.9375 24.1064 23.295 24.1064 20.04C24.1064 16.785 26.7489 14.125 30.0039 14.125ZM30.0039 38.625C26.1539 38.625 22.6189 37.2425 19.8714 34.9325C21.0264 31.9925 24.8764 29.4375 30.0039 29.4375C35.1314 29.4375 38.9814 31.9925 40.1364 34.9325C37.3889 37.2425 33.8539 38.625 30.0039 38.625Z"
          fill="white"
        />
        <circle cx="11.9917" cy="33.1255" r="10.9565" fill="#626F82" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9922 24.125C7.02162 24.125 2.99219 28.1544 2.99219 33.125C2.99219 38.0956 7.02162 42.125 11.9922 42.125C16.9628 42.125 20.9922 38.0956 20.9922 33.125C20.9922 28.1544 16.9628 24.125 11.9922 24.125ZM0.992188 33.125C0.992188 27.0499 5.91706 22.125 11.9922 22.125C18.0673 22.125 22.9922 27.0499 22.9922 33.125C22.9922 39.2001 18.0673 44.125 11.9922 44.125C5.91706 44.125 0.992188 39.2001 0.992188 33.125ZM11.9922 28.1248C12.5445 28.1248 12.9922 28.5725 12.9922 29.1248V29.1348C12.9922 29.6871 12.5445 30.1348 11.9922 30.1348C11.4399 30.1348 10.9922 29.6871 10.9922 29.1348V29.1248C10.9922 28.5725 11.4399 28.1248 11.9922 28.1248ZM11.9922 31.1348C12.5445 31.1348 12.9922 31.5825 12.9922 32.1348V37.1348C12.9922 37.6871 12.5445 38.1348 11.9922 38.1348C11.4399 38.1348 10.9922 37.6871 10.9922 37.1348V32.1348C10.9922 31.5825 11.4399 31.1348 11.9922 31.1348Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}

export default UserCircleInformation
