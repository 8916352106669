import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EmptyNotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="currentColor"
      >
        <path
          d="M78.825 134.31C106.825 134.31 129.525 111.61 129.525 83.5105C129.525 55.4105 106.725 32.7104 78.825 32.7104C50.825 32.7104 28.125 55.4105 28.125 83.5105C28.125 111.61 50.825 134.31 78.825 134.31Z"
          fill="#EAEEF9"
        />
        <path
          d="M132.676 116.287C134.941 116.287 136.776 114.452 136.776 112.187C136.776 109.923 134.941 108.087 132.676 108.087C130.412 108.087 128.576 109.923 128.576 112.187C128.576 114.452 130.412 116.287 132.676 116.287Z"
          fill="#EAEEF9"
        />
        <path
          d="M138.675 100.287C140.221 100.287 141.475 99.0339 141.475 97.4875C141.475 95.9411 140.221 94.6875 138.675 94.6875C137.129 94.6875 135.875 95.9411 135.875 97.4875C135.875 99.0339 137.129 100.287 138.675 100.287Z"
          fill="#EAEEF9"
        />
        <path
          d="M30.8254 50.1103C32.3718 50.1103 33.6254 48.8567 33.6254 47.3103C33.6254 45.7639 32.3718 44.5103 30.8254 44.5103C29.279 44.5103 28.0254 45.7639 28.0254 47.3103C28.0254 48.8567 29.279 50.1103 30.8254 50.1103Z"
          fill="#EAEEF9"
        />
        <path
          d="M13.7254 104.11C16.5973 104.11 18.9254 101.782 18.9254 98.9105C18.9254 96.0386 16.5973 93.7104 13.7254 93.7104C10.8535 93.7104 8.52539 96.0386 8.52539 98.9105C8.52539 101.782 10.8535 104.11 13.7254 104.11Z"
          fill="#EAEEF9"
        />
        <path
          d="M85.1857 101.928H73.9185C71.3078 101.928 69.1094 104.337 69.1094 107.196V113.368C69.1094 119.69 73.7811 124.807 79.5521 124.807C85.3231 124.807 89.9949 119.69 89.9949 113.368V107.196C89.9949 104.186 87.7964 101.928 85.1857 101.928Z"
          fill="white"
        />
        <g filter="url(#filter0_d_2086_23322)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.9199 42.7942V41.7422C72.9199 38.5829 75.3932 36.1104 78.5535 36.1104C81.7138 36.1104 84.1871 38.5829 84.1871 41.7422V42.7944C95.2116 45.3447 103.423 55.218 103.423 67.017V70.1763C103.423 96.8247 123.484 114.27 123.484 114.27H33.4844C33.4844 114.27 53.6828 96.8247 53.6828 70.1763V67.017C53.6828 55.2177 61.895 45.3442 72.9199 42.7942Z"
            fill="url(#paint0_linear_2086_23322)"
          />
        </g>
        <path
          d="M68.1094 81.0276C64.6743 81.0276 61.7888 78.8298 60.6896 75.6704C60.4148 74.9836 60.827 74.2968 61.514 74.0221C62.201 73.7474 62.8881 74.1594 63.1629 74.8463C63.8499 76.9067 65.911 78.4177 68.1094 78.4177C70.3079 78.4177 72.369 77.0441 73.056 74.8463C73.3308 74.1594 74.0178 73.7474 74.7049 74.0221C75.3919 74.2968 75.8041 74.9836 75.5293 75.6704C74.4301 78.9671 71.5446 81.0276 68.1094 81.0276Z"
          fill="#ADB6C8"
        />
        <path
          d="M88.8575 81.0276C85.4224 81.0276 82.5369 78.8298 81.4376 75.6704C81.1628 74.9836 81.575 74.2968 82.2621 74.0221C82.9491 73.7474 83.6361 74.1594 83.9109 74.8463C84.5979 76.9067 86.659 78.4177 88.8575 78.4177C91.056 78.4177 93.117 77.0441 93.8041 74.8463C94.0789 74.1594 94.7659 73.7474 95.4529 74.0221C96.1399 74.2968 96.5521 74.9836 96.2773 75.6704C95.1781 78.9671 92.2926 81.0276 88.8575 81.0276Z"
          fill="#ADB6C8"
        />
        <path
          d="M78.5529 97.8465C80.2983 97.8465 81.7132 96.432 81.7132 94.6872C81.7132 92.9423 80.2983 91.5278 78.5529 91.5278C76.8075 91.5278 75.3926 92.9423 75.3926 94.6872C75.3926 96.432 76.8075 97.8465 78.5529 97.8465Z"
          fill="#ADB6C8"
        />
        <path
          d="M95.4878 20.3391V15.6895H113.458V20.3391L101.196 35.1335H113.669V39.7832H94.8535V35.1335L107.115 20.3391H95.4878Z"
          fill="url(#paint1_linear_2086_23322)"
        />
        <path
          d="M110.286 55.2118V52.2529H121.914V55.2118L114.092 64.7225H122.125V67.6814H109.863V64.7225L117.686 55.2118H110.286Z"
          fill="url(#paint2_linear_2086_23322)"
        />
        <defs>
          <filter
            id="filter0_d_2086_23322"
            x="11.4844"
            y="25.1104"
            width="134"
            height="122.159"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11" />
            <feGaussianBlur stdDeviation="11" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2086_23322"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2086_23322"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2086_23322"
            x1="78.455"
            y1="34.3025"
            x2="78.455"
            y2="115.112"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDFEFF" />
            <stop offset="0.9964" stopColor="#ECF0F5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2086_23322"
            x1="104.263"
            y1="16.5264"
            x2="104.263"
            y2="30.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B0BACC" />
            <stop offset="1" stopColor="#969EAE" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2086_23322"
            x1="115.995"
            y1="52.7889"
            x2="115.995"
            y2="61.4487"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B0BACC" />
            <stop offset="1" stopColor="#969EAE" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
