import { SvgIcon, SvgIconProps } from '@mui/material'

const TrashAltIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.03583 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7V18C4 20.7614 6.23857 23 9 23H14.8927C17.6367 23 19.868 20.7885 19.8925 18.0446L19.9911 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H16.9642C16.6596 2.66386 14.4568 1 12 1C9.54321 1 7.34037 2.66386 7.03583 5ZM9.07417 5H14.9258C14.6354 3.91763 13.5163 3 12 3C10.4837 3 9.36461 3.91763 9.07417 5ZM17.991 7H6V18C6 19.6569 7.34314 21 9 21H14.8927C16.5391 21 17.8779 19.6731 17.8926 18.0268L17.991 7ZM10 9C10.5523 9 11 9.44772 11 10V18C11 18.5523 10.5523 19 10 19C9.44772 19 9 18.5523 9 18V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18V10C13 9.44772 13.4477 9 14 9Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default TrashAltIcon
