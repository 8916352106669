import { SvgIcon, SvgIconProps } from '@mui/material'

const UserCircleOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_2177_4145)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99967 19.1668C4.94647 19.1668 0.833008 15.0534 0.833008 10.0002C0.833008 4.94695 4.94647 0.833496 9.99967 0.833496C15.0529 0.833496 19.1663 4.94695 19.1663 10.0002C19.1663 15.0534 15.0529 19.1668 9.99967 19.1668ZM9.99967 2.48218C5.85324 2.48218 2.48169 5.85373 2.48169 10.0002C2.48169 14.1466 5.85324 17.5181 9.99967 17.5181C14.1461 17.5181 17.5177 14.1466 17.5177 10.0002C17.5177 5.85373 14.1461 2.48218 9.99967 2.48218ZM9.9667 10.7915C8.0295 10.7915 6.45501 9.21704 6.45501 7.27984C6.45501 5.34264 8.0295 3.76815 9.9667 3.76815C11.9039 3.76815 13.4784 5.34264 13.4784 7.27984C13.4784 9.21704 11.9039 10.7915 9.9667 10.7915ZM9.9667 5.41683C8.93627 5.41683 8.10369 6.24941 8.10369 7.27984C8.10369 8.31026 8.93627 9.14285 9.9667 9.14285C10.9971 9.14285 11.8297 8.31026 11.8297 7.27984C11.8297 6.24941 10.9971 5.41683 9.9667 5.41683ZM13.363 14.7649C13.5196 14.9709 13.7587 15.0699 13.9977 15.0699H14.006C14.1873 15.0699 14.3687 15.0039 14.5253 14.8803C14.8798 14.5917 14.9292 14.0724 14.6407 13.7179C13.6185 12.4732 12.11 11.756 10.4943 11.756H9.65345C8.03774 11.756 6.5292 12.4732 5.50702 13.7179C5.2185 14.0724 5.26796 14.5917 5.62243 14.8803C5.97689 15.1688 6.49623 15.1193 6.78475 14.7649C7.49368 13.8993 8.54059 13.4047 9.65345 13.4047H10.4943C11.6071 13.4047 12.6541 13.8993 13.363 14.7649Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_2177_4145">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default UserCircleOutlinedIcon
