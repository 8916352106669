import { SvgIcon, SvgIconProps } from '@mui/material'

export default function StarBadgeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          d="M12 22.4663C17.9371 22.4663 22.75 17.6534 22.75 11.7163C22.75 5.77925 17.9371 0.966309 12 0.966309C6.06294 0.966309 1.25 5.77925 1.25 11.7163C1.25 17.6534 6.06294 22.4663 12 22.4663Z"
          fill="url(#paint0_linear_2093_6187)"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M12 19.0015C16.0235 19.0015 19.2852 15.7398 19.2852 11.7163C19.2852 7.69283 16.0235 4.43115 12 4.43115C7.97652 4.43115 4.71484 7.69283 4.71484 11.7163C4.71484 15.7398 7.97652 19.0015 12 19.0015Z"
          fill="url(#paint1_linear_2093_6187)"
        />
        <path
          d="M12.9218 8.00537L13.7695 9.62256L15.5703 9.92725C16.3984 10.0679 16.7265 11.0796 16.1406 11.6812L14.8632 12.9858L15.1289 14.7905C15.25 15.6226 14.3906 16.2476 13.6367 15.8765L12 15.0679L10.3632 15.8765C9.60934 16.2476 8.74996 15.6226 8.87105 14.7905L9.13668 12.9858L7.85934 11.6812C7.2734 11.0796 7.60152 10.0679 8.42965 9.92725L10.2304 9.62256L11.0781 8.00537C11.4687 7.25928 12.5312 7.25928 12.9218 8.00537Z"
          fill="url(#paint2_linear_2093_6187)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2093_6187"
            x1="12"
            y1="21.7163"
            x2="12"
            y2="1.71631"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2093_6187"
            x1="12"
            y1="19.0019"
            x2="12"
            y2="4.43076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE187" />
            <stop offset="1" stopColor="#FFF8E0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2093_6187"
            x1="11.9999"
            y1="15.9862"
            x2="11.9999"
            y2="7.44639"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FD7600" />
            <stop offset="0.71" stopColor="#FDBF30" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
