import { SvgIcon, SvgIconProps } from '@mui/material'

const GroupIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9307 7.47023C12.9307 9.67023 14.7207 11.4502 16.9107 11.4502C19.1107 11.4502 20.8907 9.67023 20.8907 7.47023C20.8907 5.27023 19.1107 3.49023 16.9107 3.49023C14.7107 3.49023 12.9307 5.27023 12.9307 7.47023ZM14.9307 7.48023C14.9307 6.39023 15.8207 5.50023 16.9107 5.50023C18.0007 5.50023 18.8907 6.39023 18.8907 7.48023C18.8907 8.57023 18.0007 9.46023 16.9107 9.46023C15.8207 9.46023 14.9307 8.57023 14.9307 7.48023ZM3.57067 8.79023C3.57067 11.2302 5.55067 13.2102 7.99067 13.2102V13.2002C10.4307 13.2002 12.4107 11.2202 12.4107 8.78023C12.4107 6.34023 10.4307 4.36023 7.99067 4.36023C5.55067 4.36023 3.57067 6.35023 3.57067 8.79023ZM5.57067 8.78023C5.57067 7.45023 6.65067 6.36023 7.99067 6.36023C9.32067 6.36023 10.4107 7.45023 10.4107 8.78023C10.4107 10.1102 9.32067 11.2002 7.99067 11.2002C6.66067 11.2002 5.57067 10.1102 5.57067 8.78023ZM2.06067 20.2602C2.15067 20.2902 2.25067 20.3002 2.35067 20.3002V20.3102C2.78067 20.3102 3.18067 20.0302 3.31067 19.6002C3.95067 17.5302 5.83067 16.1402 8.00067 16.1402C10.1707 16.1402 12.0607 17.5302 12.6907 19.6002C12.8507 20.1202 13.4107 20.4302 13.9407 20.2602C14.4707 20.1002 14.7607 19.5402 14.6007 19.0102C13.7107 16.0902 11.0607 14.1302 8.00067 14.1302C4.94067 14.1302 2.29067 16.0902 1.40067 19.0102C1.23067 19.5402 1.53067 20.1002 2.06067 20.2602ZM21.7607 17.9202C21.3307 17.9202 20.9407 17.6402 20.8007 17.2102C20.2707 15.4902 18.7107 14.3302 16.9007 14.3302C15.9807 14.3302 15.1107 14.6302 14.4007 15.1802C13.9607 15.5202 13.3407 15.4402 13.0007 15.0102C12.6607 14.5702 12.7407 13.9502 13.1707 13.6102C14.2407 12.7702 15.5307 12.3302 16.9007 12.3302C19.5907 12.3302 21.9307 14.0602 22.7107 16.6202C22.8707 17.1502 22.5707 17.7102 22.0507 17.8702C21.9507 17.9002 21.8507 17.9102 21.7607 17.9102V17.9202Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default GroupIcon
