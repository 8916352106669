import { useAppSelector } from "config/store";
import helpers from "helpers/index";
import { TypedCurrentUserCan } from "interfaces/Capacity.interface";
import React, { ReactElement, ReactPortal, useCallback, useEffect, useState } from "react";



/**
 * Jamviet.com
 * Check capacity of a current user.
 * @current_user_can check user capacity, eg: channel/create
 * @extra_condition boolean, if you want to check more condition, pass true to @extra_condition and it will show after @current_user_can check the right capacity
 * @devMode boolean: if you want Capacity return true/false to test user's capacity ...
 */
export default function Capacity(
  { current_user_can, extra_condition = true, onFail, devMode = false, children }
    :
    { current_user_can: TypedCurrentUserCan | TypedCurrentUserCan[], extra_condition?: boolean, onFail?: Function, devMode?: boolean, children? }
) {

  const permission_data = useAppSelector(state => state.user.permission_data);
  const [allow, setAllow] = useState < boolean | null > (null);


  useEffect(() => {
    if (!permission_data) return;
    if (typeof permission_data !== 'object') return;
    if (permission_data.indexOf('super_admin') > -1 || permission_data.indexOf('admin') > -1) {
      setAllow(true);
      return;
    }


    if (Array.isArray(current_user_can)) {
      let isTrue = false;
      for (let role of current_user_can) {
        if (permission_data.indexOf(role) > -1) {
          setAllow(true);
          isTrue = true;
          break;
        }
      }
      if(isTrue){
        return;
      }
    } else {
      if (permission_data.indexOf(current_user_can) < 0) {
        setAllow(false);
        return;
      } else {
        setAllow(true);
        return;
      }
    }


    /**
     * Check one more time, if current user can === user role in all portal
     */
    if (permission_data === current_user_can) {
      setAllow(true);
      return;
    }

    if (devMode && helpers.isDev()) {
      setAllow(devMode);
    }

  }, [permission_data, current_user_can, devMode]);

  const onFailCB = () => {
    return onFail?.call(this, null);
  }


  return (
    allow !== null ?
      (
        allow ? (extra_condition && children) : onFailCB()
      )
      : onFailCB()
  )
}
