import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const MinusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 11.9999C3 11.4477 3.44772 10.9999 4 10.9999L20 10.9999C20.5523 10.9999 21 11.4477 21 11.9999C21 12.5522 20.5523 12.9999 20 12.9999L4 12.9999C3.44772 12.9999 3 12.5522 3 11.9999Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default MinusIcon
