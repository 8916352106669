import { InjectPaginateParams } from '../../../interfaces/query.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRootState } from 'config/store'

type HomeState = {
  newsFeedParams: InjectPaginateParams<{}>
}

const initialState: HomeState = {
  newsFeedParams: {
    page: 1,
    limit: 10,
    post_category: '',
    order_type: 'time'
  }
}

const homeSlice = createSlice({
  initialState,
  name: 'homeSlice',
  reducers: {
    updateNewsFeedParams(state, action: PayloadAction<InjectPaginateParams<{}>>) {
      state.newsFeedParams = action.payload
    }
  }
})

export const { updateNewsFeedParams } = homeSlice.actions

export const getFetchingParams = (state: IRootState) => state.homeSlice.newsFeedParams

export default homeSlice.reducer
