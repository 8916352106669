import { SvgIcon, SvgIconProps } from '@mui/material'

export default function MessageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C7.58172 4 4 7.58172 4 12C4 13.0303 4.19425 14.0128 4.54722 14.9146C4.74889 15.4298 4.85148 16.0276 4.74759 16.6445L4.21945 19.7806L7.35552 19.2524C7.97242 19.1485 8.57019 19.2511 9.08543 19.4528C9.98723 19.8057 10.9697 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.7163 22 9.48679 21.7576 8.35647 21.3152C8.10799 21.2179 7.87953 21.1923 7.68767 21.2246L3.85883 21.8694C2.84177 22.0407 1.95927 21.1582 2.13055 20.1412L2.77536 16.3123C2.80767 16.1205 2.78206 15.892 2.6848 15.6435C2.24239 14.5132 2 13.2837 2 12Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.96191 11.8779C8.5142 11.8779 8.96191 12.3256 8.96191 12.8779C8.96191 13.8149 10.0606 14.9841 11.9907 14.9841C13.9209 14.9841 15.0195 13.8149 15.0195 12.8779C15.0195 12.3256 15.4672 11.8779 16.0195 11.8779C16.5718 11.8779 17.0195 12.3256 17.0195 12.8779C17.0195 15.372 14.5107 16.9841 11.9907 16.9841C9.47077 16.9841 6.96191 15.372 6.96191 12.8779C6.96191 12.3256 7.40963 11.8779 7.96191 11.8779Z"
        />
      </svg>
    </SvgIcon>
  )
}
