import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ToastType } from "hooks/useToast";

export type ToastPayload = {
  id: string;
} & ToastType;

const initialState: {
  toasts: ToastPayload[];
} = {
  toasts: [] as ToastPayload[],
};

const uiSlice = createSlice({
  initialState,
  name: "ui",
  reducers: {
    addToast: (state, action: PayloadAction<ToastPayload>) => {
      state.toasts.push(action.payload);
    },
    removeToast: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      return { ...state, toasts: state.toasts.filter((t) => t.id !== id) };
    },
  },
});

export const { addToast, removeToast } = uiSlice.actions;

export default uiSlice.reducer;

