import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'
import { IRootState } from 'config/store'
import helpers from 'helpers/index'
import TypedChannel from 'interfaces/channel.interface'
import TypedUser from "../interfaces/user.interface";
import {checkCreateChannel} from "entities/channel/service";

type TypedUserInThisChannel = {
  channel_role: string
  coin_number: number
  level_number: number
  official_status: number
  old_point: number
  point: number
  point_month: number
  point_week: number
  loadingDoneFirstTime: boolean
}

interface InitState {
  loading: boolean
  updateSuccess: boolean,
  creating: boolean,
  createdSuccess: boolean,
  errorMessage: any,
  user_data: any
  user_in_this_channel: TypedUserInThisChannel
  channel_data: TypedChannel
  permission_data: string[]
  level_data: any
  is_user_logged_in: boolean
  current_user_id: any
  check_first_gift: boolean
  isAuthenticating: boolean
  loadingDoneFirstTime: boolean
  totalNotificationUnread: number
  isSigningOut: boolean
  isCreatedChannel: boolean
}

export const initialState: InitState = {
  loading: true,
  errorMessage: '',
  user_data: null,
  user_in_this_channel: null,
  channel_data: null,
  permission_data: null,
  level_data: null,
  is_user_logged_in: false,
  current_user_id: null,
  check_first_gift: false,
  isAuthenticating: false,
  loadingDoneFirstTime: false,
  totalNotificationUnread: 0,
  isSigningOut: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false,
  isCreatedChannel: true
}

/**
 * Đừng ai sửa getInfoChannel của em nhé
 * Hoài
 */
export const getInfoChannel = (currentUrl: string) => {
  return axios.get(`channel/me?from_url=${currentUrl}`)
}

export const checkFirstGift = createAsyncThunk(
  'channel/check_first_gift',
  async (params: boolean) => {
    return params
  }
)

/**
 * @author jamviet.com
 * @refactor
 * @deprecated remove in the near future, use getCurrentChannelInfo instead
 */
export const getDefaultChannel = createAsyncThunk('channel/fetch_default_channel', async () => {
  return await axios.get<any>(`channel/me`)
})

export const getCurrentUserData = createAsyncThunk('v2/user/me', async () => {
  return await axios.get<any>(`v2/user/me`)
})

export const getLevelsChannel = createAsyncThunk(
  'channel/get_levels',
  async (params: IQueryParams) => {
    return axios.get<any>(`channel/list-level`, { params: params })
  }
)

export const checkCreatedChannel = createAsyncThunk(
  'channel/checkCreatedChannel',
  async () => {
    return checkCreateChannel()
  }
)

/**
 * Hoài
 */
export const getCurrentChannelInfo = createAsyncThunk('channel/getCurrentChannelInfo', async () => {
  return await axios.get<any>(`channel/me`)
})

/**
 * Lấy permission của một user với một channel ...
 */
export const getUserPermission = createAsyncThunk('permission/get_channel_permission', async () => {
  return await axios.get<any>(`channel/permission`)
})

export const updateDataUser = createAsyncThunk(
  'user/updateDataUser',
  async (body: TypedUser, { rejectWithValue }) => {
    try {
      return await axios.patch<TypedUser>(`/user/${body?._id}`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const USER_REDUCER = createEntitySlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserData(state, action) {
      state.user_data = action?.payload?.data
    },
    setTotalNotificationUnread: (state, action) => {
      state.totalNotificationUnread = action.payload
    },
    subTotalNotificationUnread: (state, action) => {
      state.totalNotificationUnread = Math.max(state.totalNotificationUnread - action.payload, 0)
    },
    addTotalNotificationUnread: (state, action) => {
      state.totalNotificationUnread = Math.max(state.totalNotificationUnread + action.payload, 0)
    },
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => {
      return { ...state, ...initialState, channel_data: state.channel_data }
    },
    resetLogout: state => {
      return { ...state, ...initialState, channel_data: state.channel_data, loadingDoneFirstTime: initialState.loadingDoneFirstTime }
    },
    setChannelData(state, action) {
      state.channel_data = action.payload
    },
    clearAll: () => {
      localStorage.removeItem('user')
      localStorage.removeItem('channel')
    },
    setLoadingDoneFirstTime: state => {
      state.loadingDoneFirstTime = true
    },
    logout: (state) => {
      state.isSigningOut = true
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getDefaultChannel.fulfilled, (state, action) => {
        return ({
          ...state,
          loading: false,
          errorMessage: null,
          channel_data: action.payload.data
        })
      })
      .addCase(checkFirstGift.fulfilled, (state, action) => {
        state.check_first_gift = action.payload
      })
      .addCase(getDefaultChannel.pending, (state, action) => {
        state.loading = true
        state.errorMessage = null
      })
      .addCase(getDefaultChannel.rejected, (state, action) => {
        state.loading = false
        state.errorMessage = action.payload
        state.channel_data = undefined
      })
      .addCase(getCurrentChannelInfo.fulfilled, (state, action) => {
        return ({
          ...state,
          channel_data: action?.payload?.data
        })
      })
      .addCase(getUserPermission.fulfilled, (state, action) => {
        state.permission_data = action?.payload?.data
      })
      .addCase(checkCreatedChannel.fulfilled, (state, action) => {
        state.isCreatedChannel = action?.payload?.data?.isCreated
      })
      .addCase(getLevelsChannel.fulfilled, (state, action) => {
        state.level_data = action?.payload?.data
      })
      .addCase(getLevelsChannel.rejected, (state, action) => {
        state.errorMessage = action.payload
      })
      .addCase(updateDataUser.fulfilled, (state, action) => {
        state.user_data = { ...state.user_data, ...action.payload?.data }
      })

      .addCase(getCurrentUserData.fulfilled, (state, action) => {
        state.user_data = action?.payload?.data
        state.user_in_this_channel = state.user_data?.channelpermission
        state.current_user_id = action?.payload?.data?._id
        state.isAuthenticating = false
        state.is_user_logged_in = true
        state.loadingDoneFirstTime = true
      })

      .addCase(getCurrentUserData.rejected, (state, action) => {
        state.user_data = null
        state.user_in_this_channel = null
        state.current_user_id = null
        state.isAuthenticating = false
        state.is_user_logged_in = false
        state.loadingDoneFirstTime = true
      })
    // Cái này làm nháy nút đăng nhập, đăng ký
    // .addCase(getCurrentUserData.pending, (state, action) => {
    //   state.user_data = null
    //   state.user_in_this_channel = null
    //   state.isAuthenticating = true
    //   state.is_user_logged_in = false
    // })
  }
})
export const {
  clearError,
  setTotalNotificationUnread,
  addTotalNotificationUnread,
  subTotalNotificationUnread,
  setLoadingDoneFirstTime,
  setChannelData,
  setUserData,
  reset,
  resetLogout,
  logout
} = USER_REDUCER.actions

export const getUserId = (state: IRootState) => state.user.user_data?._id
export const getChannelId = (state: IRootState) => state.user.channel_data?._id
export const getDetailChannel = (state: IRootState) => state.user.channel_data
export const getPermissionData = (state: IRootState) => state.user?.permission_data

export const getChannelServices = (state: IRootState) => state.user?.channel_data?.service_id
// Reducer
export default USER_REDUCER.reducer
