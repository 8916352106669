import __ from 'languages/index'

export const optionMission = [
  {
    id: 0,
    key: '',
    value: '',
    label: `${__("mission_title")}`
  },
  {
    id: 1,
    key: 'comment',
    value: '2',
    label: `${__('comment_post')}`
  },
  {
    id: 2,
    key: 'like_post',
    value: '1',
    label: `${__("like_post")}`
  },
  {
    id: 3,
    key: 'view_course',
    value: '2',
    label: `${__('view_course')}`
  },
  {
    id: 4,
    key: 'like_comment',
    value: '1',
    label: `${__('like_comment')}`
  },
  {
    id: 5,
    key: 'post_new',
    value: '5',
    label: `${__('post_new')}`
  },
  {
    id: 5,
    key: 'invite_user',
    value: '5',
    label: `${__('invite_user')}`
  },
  {
    id: 6,
    key: 'update_profile',
    value: '5',
    label: `${__('update_profile')}`
  }
]

export const optionConditions = [
  {
    id: 0,
    value: '',
    label: 'Điều kiện tham gia'
  },
  {
    id: 1,
    value: 'level_1',
    label: 'Level 1'
  },
  {
    id: 2,
    value: 'level_2',
    label: 'Level 2'
  },
  {
    id: 3,
    value: 'level_3',
    label: 'Level 3'
  },
  {
    id: 4,
    value: 'level_4',
    label: 'Level 4'
  },
  {
    id: 5,
    value: 'level_5',
    label: 'Level 5'
  }
]

export const optionMissionData = [
  {
    id: 0,
    key: '',
    value: '',
    label: `${__("mission_title")}`
  },
  {
    id: 1,
    key: 'comment',
    value: '2',
    label: `${__("mission_data_label_comment")}`,
    mission: `${__("mission_data_mission_post")}`

  },
  {
    id: 2,
    key: 'like_post',
    value: '1',
    label: `${__("mission_data_label_like")}`,
    mission:`${__("mission_data_mission_post")}`
  },
  {
    id: 3,
    key: 'view_course',
    value: '2',
    label: `${__("mission_data_label_watch")}`,
    mission: `${__("mission_data_mission_course")}`

  },
  {
    id: 4,
    key: 'like_comment',
    value: '1',
    label: `${__("mission_data_label_like")}`,
    mission: `${__("mission_data_mission_comment")}`
  },
  {
    id: 5,
    key: 'post_new',
    value: '5',
    label: `${__("mission_data_label_post")}`,
    mission: `${__("mission_data_mission_new_post")}`

  },
  {
    id: 5,
    key: 'invite_user',
    value: '5',
    label: `${__("mission_data_label_invite")}`,
    mission: `${__("mission_data_mission_invite_user")}`
  },
  {
    id: 6,
    key: 'update_profile',
    value: '5',
    label: `${__("mission_data_label_profile")}`,
    mission: ''
  }
]
