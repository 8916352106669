import { SvgIcon, SvgIconProps } from '@mui/material'

const FileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0322 3.02393C17.7488 3.00078 17.3766 3 16.8 3L12 3V7.92307C12 9.07012 11.0701 10 9.92308 10H5L5 18.8C5 19.3766 5.00078 19.7488 5.02393 20.0322C5.04612 20.3038 5.0838 20.4045 5.109 20.454C5.20487 20.6422 5.35785 20.7951 5.54601 20.891C5.59545 20.9162 5.69617 20.9539 5.96784 20.9761C6.25118 20.9992 6.62346 21 7.2 21H16.8C17.3766 21 17.7488 20.9992 18.0322 20.9761C18.3038 20.9539 18.4045 20.9162 18.454 20.891C18.6422 20.7951 18.7951 20.6422 18.891 20.454C18.9162 20.4045 18.9539 20.3038 18.9761 20.0322C18.9992 19.7488 19 19.3766 19 18.8V5.2C19 4.62345 18.9992 4.25118 18.9761 3.96784C18.9539 3.69617 18.9162 3.59545 18.891 3.54601C18.7951 3.35785 18.6422 3.20487 18.454 3.10899C18.4045 3.0838 18.3038 3.04612 18.0322 3.02393ZM6.41418 8H9.92308C9.96556 8 10 7.96557 10 7.92307V4.4142L6.41418 8ZM19.362 1.32698C19.9265 1.6146 20.3854 2.07354 20.673 2.63803C20.8658 3.01641 20.9371 3.40963 20.9694 3.80497C21 4.17954 21 4.6343 21 5.16144V18.8386C21 19.3657 21 19.8205 20.9694 20.195C20.9371 20.5904 20.8658 20.9836 20.673 21.362C20.3854 21.9265 19.9265 22.3854 19.362 22.673C18.9836 22.8658 18.5904 22.9371 18.195 22.9694C17.8205 23 17.3657 23 16.8386 23H7.16144C6.63431 23 6.17955 23 5.80498 22.9694C5.40964 22.9371 5.01641 22.8658 4.63803 22.673C4.07354 22.3854 3.6146 21.9265 3.32698 21.362C3.13419 20.9836 3.06287 20.5904 3.03057 20.195C2.99997 19.8205 2.99998 19.3657 3 18.8385L3 9.82839C3 9.39554 3.09357 8.97229 3.26943 8.58582C3.41685 8.26186 3.62216 7.96359 3.87869 7.70706L9.70711 1.87867C9.96362 1.62217 10.2619 1.41687 10.5858 1.26945C10.9723 1.09358 11.3956 1 11.8284 1L16.8386 1C17.3657 0.999983 17.8205 0.999967 18.195 1.03057C18.5904 1.06287 18.9836 1.13419 19.362 1.32698Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default FileIcon
